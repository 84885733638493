import { CompanyIssueStatusesAndTypesStyles } from './CompanyIssueStatusesAndTypesStyles';
import { FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { DeleteIcon, PencilIcon } from '../../assets';
import SubmitButton from '../form/submitButton/SubmitButton';
import React, { useEffect, useRef, useState } from 'react';
import { ApiIssueStatus, ApiIssueType, issuesApi } from '../../api';
import { useNavigate, useParams } from 'react-router-dom';
import { formInitialValues2_1, formInitialValues2_2, FormValues, FormValues2, validationSchema, validationSchema2 } from './formValuesAndValidation';
import { updateItemsList } from './utils';
import { useAppSelector } from '../../state';
import { Loader, TransparentButton } from '../atoms';
import { CompaniesInfoModal, Modal, ModalConfirmDecline } from '../organisms';
import { getErrorMessage, handleKeyUp } from '../../utils';
import { InputField } from '../molecules';
export default function CompanyIssueStatusesAndTypes() {
  const {
    id
  } = useParams();
  const navigate = useNavigate();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [submitError, setSubmitError] = useState<string>('');
  const [accessibleStatusesEdit, setAccessibleStatusesEdit] = useState<boolean>(false);
  const [accessibleTypesEdit, setAccessibleTypesEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [afterSaveModal, setAfterSaveModal] = useState<boolean>(false);
  const [openedModalType, setOpenedModalType] = useState<string>();
  const [issueTypeOrStatusCreateLoading, setIssueTypeOrStatusCreateLoading] = useState<boolean>(false);
  const [confirmSaveModalOpen, setConfirmSaveModalOpen] = useState<boolean>(false);
  const [dataSaveError, setDataSaveError] = useState<string>('');
  const formRef = useRef<FormikProps<FormValues>>(null);
  async function handleCreateIssueType(value: ApiIssueType) {
    formRef.current!.values.types = [...formRef.current!.values.types, value];
  }
  async function handleCreateIssueStatus(value: ApiIssueStatus) {
    formRef.current!.values.statuses = [...formRef.current!.values.statuses, value];
  }
  async function getDataBySelectedCompany(companyId: number) {
    const statuses = await issuesApi.getTicketStatusesByCompany(companyId);
    const types = await issuesApi.getTicketTypesByCompany(companyId);
    if (statuses.statusCode >= 200 && statuses.statusCode < 300 && types.statusCode >= 200 && types.statusCode < 300) {
      setInitialValues({
        statuses: statuses.data,
        types: types.data
      });
    }
  }
  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getDataBySelectedCompany(+id).finally(() => {
        setIsLoading(false);
      });
    }
  }, [id]);
  const onSaveModalConfirm = async (values: FormValues) => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        updateItemsList('status', +id!, initialValues ? initialValues.statuses : [], values.statuses);
        // updateItemsList(
        //   'type',
        //   +id!,
        //   initialValues ? initialValues.types : [],
        //   values.types,
        // );
      } catch (e) {
        setSubmitError('* Виникла помилка при збереженні даних');
        setDataSaveError('* Виникла помилка при збереженні даних');
      } finally {
        if (!submitError) {
          setConfirmSaveModalOpen(false);
          setAfterSaveModal(true);
        }
        setIsLoading(false);
      }
    }
  };
  async function onSubmit(values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) {
    setConfirmSaveModalOpen(true);
    setSubmitting(false);
  }
  function onSubmit2(values: FormValues2, {
    setSubmitting
  }: FormikHelpers<FormValues2>) {
    setSubmitting(false);
    setIssueTypeOrStatusCreateLoading(true);
    try {
      if (values.type === 'type') {
        handleCreateIssueType({
          companyID: +id!,
          name: values.value
        });
      }
      if (values.type === 'status') {
        handleCreateIssueStatus({
          companyID: +id!,
          name: values.value
        });
      }
    } catch (e) {
      console.log('ERROR', e);
    } finally {
      setIssueTypeOrStatusCreateLoading(false);
      setOpenedModalType(undefined);
    }
  }
  const renderForm = ({
    values,
    setFieldValue
  }: FormikProps<FormValues>) => <Form>
      <div className="designWrapper">
        <div className="optionsWrapper">
          <FieldArray name="statuses" render={({
          insert,
          remove,
          push
        }) => <>
                <div className="headWrapper">
                  <span>Статуси</span>
                  <div className="buttonsWrapper">
                    <button type="button" onClick={() => setAccessibleStatusesEdit(!accessibleStatusesEdit)}>
                      <PencilIcon />
                    </button>
                    <div className="addButtonWrapper">
                      <TransparentButton handleClick={() => setOpenedModalType('status')} text="Додати статус" />
                    </div>
                  </div>
                </div>
                <div className="arrayWrapper">
                  {values.statuses.map((field, index) =>
            // eslint-disable-next-line react/no-array-index-key
            <div className={accessibleStatusesEdit ? 'itemsWrapper edit' : 'itemsWrapper'} key={index}>
                      <div className="inputWrapper">
                        {accessibleStatusesEdit ? <InputField name={`statuses[${index}].name`} onChange={setFieldValue} onKeyUp={() => handleKeyUp(`statuses[${index}].name`, setErrorMessage, errorMessage)} placeholder="Введіть назву кольору" value={values.statuses[index].name} error={typeof errorMessage === 'object' ? getErrorMessage(`statuses[${index}].name`, errorMessage) : undefined} extraBlockStyles={{
                  width: '300px'
                }} /> : field.name}
                      </div>
                      <div className="fileWrapper">
                        {accessibleStatusesEdit && <button type="button" onClick={() => remove(index)}>
                            <DeleteIcon />
                          </button>}
                      </div>
                    </div>)}
                </div>
              </>} />
        </div>
      </div>

      {/* <div className="designWrapper">
        <div className="optionsWrapper">
          <FieldArray
            name="types"
            render={({ insert, remove, push }) => (
              <>
                <div className="headWrapper">
                  <span>
                    Типи
                  </span>
                  <div className="buttonsWrapper">
                    <button
                      type="button"
                      onClick={() => setAccessibleTypesEdit(!accessibleTypesEdit)}
                    >
                      <PencilIcon />
                    </button>
                    <div className="addButtonWrapper">
                      <TransparentButton
                        handleClick={() => setOpenedModalType('type')}
                        text="Додати тип"
                      />
                    </div>
                  </div>
                </div>
                <div className="arrayWrapper">
                  {values.types.map((field, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div
                      className={
                        accessibleTypesEdit
                          ? 'itemsWrapper edit'
                          : 'itemsWrapper'
                      }
                      key={index}
                    >
                      <div className="inputWrapper">
                        {accessibleTypesEdit ? (
                          <InputField
                            name={`types[${index}].name`}
                            onChange={setFieldValue}
                            onKeyUp={() => handleKeyUp(
                              `types[${index}].name`,
                              setErrorMessage,
                              errorMessage,
                            )}
                            placeholder="Введіть назву кольору"
                            value={values.types[index].name}
                            error={
                              typeof errorMessage === 'object'
                                ? getErrorMessage(`types[${index}].name`, errorMessage)
                                : undefined
                            }
                            extraBlockStyles={{ width: '300px' }}
                          />
                        ) : (
                          field.name
                        )}
                      </div>
                      <div className="fileWrapper">
                        {accessibleTypesEdit && (
                          <button type="button" onClick={() => remove(index)}>
                            <DeleteIcon />
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          />
        </div>
       </div> */}

      <SubmitButton extraBlockStyles={{
      maxWidth: '544px',
      width: '100%'
    }}>
        Зберегти
      </SubmitButton>
      <span className="uploadError">{submitError}</span>
    </Form>;
  const renderForm2 = ({
    values,
    setFieldValue
  }: FormikProps<FormValues2>) => <Form>
      <InputField extraBlockStyles={{
      width: '100%',
      maxWidth: '524px'
    }} name="value" onChange={setFieldValue} onKeyUp={() => handleKeyUp('value', setErrorMessage, errorMessage)} placeholder="Введіть назву форми" value={values.value} error={typeof errorMessage === 'object' ? getErrorMessage('value', errorMessage) : undefined} label={openedModalType === 'type' ? 'Назва типу завдання' : 'Назва статусу завдання'} required />

      <SubmitButton extraBlockStyles={{
      maxWidth: 250,
      width: '100%'
    }}>
        Зберегти
      </SubmitButton>
    </Form>;
  return <CompanyIssueStatusesAndTypesStyles>
      {afterSaveModal && <CompaniesInfoModal onConfirm={() => setAfterSaveModal(false)} onClose={() => {
      setAfterSaveModal(false);
    }} onDecline={() => navigate('/companies')} />}

      {!initialValues ? <Loader /> : <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema(interfaceLanguage)}>
          {renderForm}
        </Formik>}

      {confirmSaveModalOpen && <ModalConfirmDecline onClose={() => setConfirmSaveModalOpen(false)} onConfirm={() => onSaveModalConfirm(formRef.current!.values)} onDecline={() => setConfirmSaveModalOpen(false)} confirmText="'Зберегти зміни" declineText="Закрити" title="Ви впевнені що хочете редагувати статуси делегованих завдань?" error={!!dataSaveError} errorText={dataSaveError} firstButtonStyles={{
      background: '#e50000'
    }} secondButtonStyles={{
      background: '#000'
    }} />}

      {openedModalType && <Modal onClose={() => setOpenedModalType(undefined)} extraStyles={{
      width: '100%',
      maxWidth: 500
    }} title={openedModalType === 'type' ? 'Створити новий тип завдання' : 'Створити новий статус завдання'}>
          <div className="modalContainer">
            <div className="body">
              <div className="modalTitleContainer">
                <h6 style={{
              textAlign: 'left'
            }}>
                  {openedModalType === 'type' ? 'Створити тип' : 'Створити статус'}
                </h6>
                <span>має бути унікальною</span>
              </div>

              <Formik initialValues={openedModalType === 'type' ? formInitialValues2_2 : formInitialValues2_1} onSubmit={onSubmit2} validationSchema={validationSchema2(interfaceLanguage)}>
                {renderForm2}
              </Formik>
            </div>
            <div className="footer right">
              <div />
              <TransparentButton disabled={issueTypeOrStatusCreateLoading} handleClick={() => setOpenedModalType(undefined)} text="Закрити" />
            </div>
          </div>
        </Modal>}
    </CompanyIssueStatusesAndTypesStyles>;
}