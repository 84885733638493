import styled from 'styled-components';

export const CategoryFilterStyles = styled.div`
  font-weight: 400;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  background: #fff;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  z-index: 200;
  width: 270px;
  padding: var(--sm-4);
  border-radius: 4px;

  @media screen and (max-width: 1500px) {
    position: fixed;
    z-index: 99;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .selectContainer {
    width: 100%;
    height: 100%;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
  }
`;
