import React, { FC, useEffect } from 'react';
import { LinePath } from '@visx/shape';
import { scaleLinear } from '@visx/scale';
import { curveLinear } from '@visx/curve';
import { CurveItem } from '../../../../pages';
import { useAppSelector } from '../../../../state';
type TCurvedChart = {
  width: number;
  height: number;
  data: CurveItem[];
  hasNegativeValues?: boolean;
  fillColor: string;
};
export const CurvedChart: FC<TCurvedChart> = ({
  width,
  height,
  data,
  hasNegativeValues,
  fillColor
}) => {
  const {
    fontScale
  } = useAppSelector(state => state.interface);
  const margin = {
    top: 0,
    right: 20,
    bottom: 0,
    left: 20
  };
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const yMin = Math.min(...data.map(d => d.y));
  const yMaxValue = Math.max(...data.map(d => d.y));
  const padding = (yMaxValue - yMin) * 0.2;
  const xScale = scaleLinear({
    domain: [0, Math.max(...data.map(d => d.x))],
    range: [margin.left, xMax]
  });
  const yDomain = hasNegativeValues ? [yMin - padding, yMaxValue + padding] : [0, yMaxValue + padding];
  const yScale = scaleLinear({
    domain: yDomain,
    range: [yMax, margin.top],
    nice: true
  });
  const adjustTextPosition = (x: number, textWidth: number) => {
    const adjustedX = Math.max(margin.left + textWidth, Math.min(x, xMax - textWidth / 2));
    const adjustedY = yMax + margin.bottom - 10;
    return {
      adjustedX,
      adjustedY
    };
  };
  return <svg width={width} height={height}>
      <LinePath data={data} x={d => xScale(d.x)} y={d => yScale(d.y)} curve={curveLinear} stroke={fillColor} strokeWidth={2.5} strokeLinecap="round" />
      {data.map((d, i) => {
      const cx = xScale(d.x);
      const cy = yScale(d.y);
      const {
        adjustedX,
        adjustedY
      } = adjustTextPosition(cx, 30);
      return <g key={`point-${i}`}>
            <circle cx={cx} cy={cy} r={5} fill={fillColor} />
            <text x={cx} y={cy - 10} fontSize={10.5 + fontScale} fill="black" textAnchor="middle">
              {d.y}
            </text>
            <text x={adjustedX} y={adjustedY + 25} fontSize={10.5 + fontScale} fill="black" textAnchor="middle">
              {d.label}
            </text>
          </g>;
    })}
    </svg>;
};