import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { DynamicDashboardStyles } from './styles';
import { analyticsApi, ApiDynamic, TPostDynamic } from '../../../../api';
import { Loader } from '../../../atoms';
import { setSaveFilters, setSaveLink, useAppDispatch, useAppSelector } from '../../../../state';
import { CurvedChart } from '../../analyticsCharts';
import { addOneDay } from '../../../../utils';
type TDynamicDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
const colors: string[] = ['#6EAE93', '#A7C5E9', '#F5EF67', '#BB6685'];
export const DynamicDashboard: FC<TDynamicDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const {
    data: userData
  } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ApiDynamic>();
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      sourceIDs,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostDynamic = {
      from: from as string,
      to: addOneDay(to as string),
      isDiia: isDiia as boolean,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      npsNegativeAnswers: ['0', '1', '2', '3', '4', '5', '6', '0/10', '1/10', '2/10', '3/10', '4/10', '5/10', '6/10'],
      npsPositiveAnswers: ['9', '10', '9/10', '10/10'],
      csiPositiveAnswers: ['Чудово', 'Добре'],
      csiFieldIDs: [858718, 858809, 858866],
      sourceIDs: sourceIDs as number[],
      npsFieldIDs: [858818, 858826],
      groupsByDates: 'month'
    };
    setLoading(true);
    analyticsApi.getDynamic(+companyID, filteredData, !userData).then(res => {
      setData(res.data);
      setLoading(false);
    });
    dispatch(setSaveLink('analytics/dynamic/file'));
    dispatch(setSaveFilters({
      from: from as string,
      to: addOneDay(to as string),
      isDiia: isDiia as boolean,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      npsNegativeAnswers: ['0', '1', '2', '3', '4', '5', '6', '0/10', '1/10', '2/10', '3/10', '4/10', '5/10', '6/10'],
      npsPositiveAnswers: ['9', '10', '9/10', '10/10'],
      csiPositiveAnswers: ['Чудово', 'Добре'],
      csiFieldIDs: [858718, 858809, 858866],
      sourceIDs: sourceIDs as number[],
      npsFieldIDs: [858818, 858826],
      groupsByDates: 'month'
    }));
  }, [reQuery]);
  if (loading) {
    return <DynamicDashboardStyles>
        <Loader />
      </DynamicDashboardStyles>;
  }
  return <DynamicDashboardStyles>
      <div className="charts">
        <div className="charts-item">
          <div className="charts-title">
            <h3>CSI</h3>
          </div>
          {data && <CurvedChart data={data?.csi.map((el, index) => ({
          x: index,
          y: parseFloat(el.csi.toFixed(1)),
          label: el.groupName
        }))} width={dimensions.width - 50} height={dimensions.height / 5} fillColor="#CFC4FF" />}
        </div>
        <div className="charts-item">
          <div className="charts-title">
            <h3>NPS</h3>
          </div>
          {data && <CurvedChart data={data?.nps.map((el, index) => ({
          x: index,
          y: parseFloat(el.nps.toFixed(1)),
          label: el.groupName
        }))} width={dimensions.width - 50} height={dimensions.height / 5} hasNegativeValues fillColor="#A7C4E9" />}
        </div>
        <div className="charts-item">
          <div className="charts-title">
            <h3>Кількість відгуків</h3>
          </div>
          {data && <CurvedChart data={data?.reviewsCount.map((el, index) => ({
          x: index,
          y: el.count,
          label: el.groupName
        }))} width={dimensions.width - 100} height={dimensions.height / 5} fillColor="#F5EF67" />}
        </div>
      </div>
    </DynamicDashboardStyles>;
};