import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Pie } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleOrdinal } from '@visx/scale';
import { PieArch } from './pieArc';
import { DataItem } from '../../../../pages';
import { PieChartStyles } from './styles';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
// eslint-disable-next-line import/no-extraneous-dependencies
import { localPoint } from '@visx/event';
type TPieChart = {
  // width: number;
  // height: number;
  data: DataItem[];
  label?: string;
  legend?: boolean;
  chartLegend?: boolean;
  onClick?: (el: DataItem) => void;
};
const ASPECT_RATIO = 3 / 2;
const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  color: '#333',
  padding: '4px 8px',
  borderRadius: '6px',
  fontSize: '10px',
  fontWeight: 'normal',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
  maxWidth: '250px',
  whiteSpace: 'normal',
  zIndex: 101,
  display: 'flex',
  gap: '5px',
  flexDirection: 'column' as 'column'
};
export const PieChart: FC<TPieChart> = ({
  // width,
  // height,
  data,
  label,
  legend,
  chartLegend,
  onClick
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({
    width: 300,
    height: 200
  });
  const {
    tooltipOpen,
    tooltipData,
    tooltipLeft,
    tooltipTop,
    showTooltip,
    hideTooltip
  } = useTooltip<DataItem>();
  const {
    containerRef: tooltipContainerRef,
    TooltipInPortal
  } = useTooltipInPortal();
  const updateDimensions = useCallback(() => {
    if (!containerRef.current) return;
    const {
      width,
      height
    } = containerRef.current.getBoundingClientRect();
    let newWidth = width;
    let newHeight = width / ASPECT_RATIO;
    if (newHeight > height) {
      newHeight = height;
      newWidth = height * ASPECT_RATIO;
    }
    setDimensions({
      width: newWidth,
      height: newHeight
    });
  }, []);
  useEffect(() => {
    if (!containerRef.current) return;
    const observer = new ResizeObserver(updateDimensions);
    observer.observe(containerRef.current, {
      box: 'border-box'
    });
    updateDimensions();
    return () => observer.disconnect();
  }, [updateDimensions]);
  const {
    width,
    height
  } = dimensions;
  const margin = {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20
  };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const innerRadius = radius / 2;
  const colorScale = scaleOrdinal({
    domain: data.map(d => d.label),
    range: data.map(el => el.fill ?? '#FFDB4D')
  });
  const leftAlignedLabels: DataItem[] = [];
  const rightAlignedLabels: DataItem[] = [];
  data.forEach((item, index) => {
    if (index < data.length / 2) {
      rightAlignedLabels.push(item);
    } else {
      leftAlignedLabels.push(item);
    }
  });
  return <PieChartStyles ref={containerRef}>
      <svg width={width} height={height} ref={tooltipContainerRef}>
        <Group top={height / 2} left={width / 2}>
          <Pie data={data.filter(el => el.value)} pieValue={d => d.value} outerRadius={radius} innerRadius={innerRadius} cornerRadius={0} padAngle={0} startAngle={0} endAngle={2 * Math.PI} pieSort={null} pieSortValues={null}>
            {pie => pie.arcs.map((arc, index) => <PieArch key={`arc-${arc.data.label}-${index}`} arc={arc} path={pie.path} index={index} color={colorScale(arc.data.label)} onClick={onClick || undefined} chartLegend={chartLegend} onMouseEnter={event => {
            const coords = localPoint(event);
            showTooltip({
              tooltipData: arc.data,
              tooltipTop: coords?.y,
              tooltipLeft: coords?.x
            });
          }} onMouseLeave={() => hideTooltip()} />)}
          </Pie>
        </Group>
      </svg>
      {legend && <div className="pie-legend-wrapper">
          <div className="legend-column">
            {leftAlignedLabels.map((item, idx) => <div key={idx} className="legend-item" style={{
          textAlign: 'left',
          color: colorScale(item.label)
        }}>
                {`${item.value} ${item.count ? `(${item.count})` : ''}`}
              </div>)}
          </div>
          <div className="legend-column">
            {rightAlignedLabels.map((item, idx) => <div key={idx} className="legend-item" style={{
          textAlign: 'right',
          color: colorScale(item.label)
        }}>
                {`${item.value} ${item.count ? `(${item.count})` : ''}`}
              </div>)}
          </div>
        </div>}
      {tooltipOpen && tooltipData && <TooltipInPortal top={tooltipTop} left={tooltipLeft} className="tooltipWrapper" style={tooltipStyles}>
          <p><b>{tooltipData.label}</b></p>
          <p>{tooltipData.value.toFixed(1)}% {!!tooltipData.count && `(${tooltipData.count})`}</p>
        </TooltipInPortal>}
    </PieChartStyles>;
};