import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { TFieldConstruct } from '../../companyFields';
import { ApiCompanyField, ApiCreatedField, ApiUpdatedField, FieldType, TAdditionalParams, fieldsApi } from '../../../../api';
import { ExtendedAnswer, addAdditionalParam, reduceOptionsParam, unfoldOptionsParam } from '../utils';
import { FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Checkbox, InputField } from '../../../molecules';
import { InfoAlert, SubmitButton, TransparentButton } from '../../../atoms';
import { getErrorMessage, handleKeyUp } from '../../../../utils';
import { FieldOptionsStyles } from '../styles';
import { DeleteIcon } from '../../../../assets';
import _ from 'lodash';
type TRadioButton = {
  field: TFieldConstruct;
  setFields: Dispatch<SetStateAction<ApiCompanyField[]>>;
  setField: Dispatch<SetStateAction<TFieldConstruct | null>>;
};
export interface IRadioButtonField {
  question: string;
  required: boolean;
  isSentiment: boolean;
  answers: ExtendedAnswer[];
  analytics_title: string;
  key_question: boolean;
}
const initialValue: IRadioButtonField = {
  question: '',
  analytics_title: '',
  required: false,
  key_question: false,
  answers: [],
  isSentiment: false
};
export const validationSchema = Yup.object({
  question: Yup.string().required('* Required')
});
export const RadioButton: FC<TRadioButton> = ({
  field,
  setFields,
  setField
}) => {
  const {
    id
  } = useParams();
  const [initialValues, setInitialValues] = useState<IRadioButtonField>();
  const [apiField, setApiField] = useState<ApiCompanyField | null>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const initialRef = useRef<IRadioButtonField>();
  const onSubmit = async (values: IRadioButtonField, {
    setSubmitting
  }: FormikHelpers<IRadioButtonField>) => {
    setSubmitting(false);
    let options: TAdditionalParams[] = [];
    const styles: TAdditionalParams[] = [];
    if (id) {
      if (apiField) {
        options = [...apiField.options.filter(el => el.key !== 'answer_description_for')];
        addAdditionalParam(values.required, 'required', options);
        addAdditionalParam(values.isSentiment, 'isSentiment', options);
        addAdditionalParam(values.key_question, 'key_question', options);
        addAdditionalParam(values.analytics_title, 'analytics_title', options);
        reduceOptionsParam(values.answers, options, 'answer_description_for');
        const data: ApiUpdatedField<FieldType.RadioButton> = {
          answers: values.answers,
          question: values.question,
          options,
          styles
        };
        fieldsApi.updateFormField(apiField.id!, data).then(res => {
          if (res.statusCode === 200) {
            setFields(prev => prev.map(el => {
              if (el.id === apiField.id) {
                return {
                  ...el,
                  ...data
                };
              }
              return el;
            }));
            if (!_.isEqual(initialRef.current?.answers, values.answers)) {
              fieldsApi.updateFormFieldAnswer(apiField.id!, values.answers).then(res => {
                toast.success('Поле успішно оновлене');
              });
            }
          }
        });
      } else {
        addAdditionalParam(values.required, 'required', options);
        addAdditionalParam(values.isSentiment, 'isSentiment', options);
        addAdditionalParam(values.key_question, 'key_question', options);
        addAdditionalParam(values.analytics_title, 'analytics_title', options);
        reduceOptionsParam(values.answers, options, 'answer_description_for');
        const data: ApiCreatedField = {
          field: {
            [FieldType.RadioButton]: {
              answers: values.answers,
              question: values.question,
              options,
              styles
            }
          }
        };
        fieldsApi.createCompanyFields(+id, data).then(res => {
          if (res.statusCode === 201) {
            setFields(prev => [res.data, ...prev]);
            setField({
              id: res.data.id,
              type: field.type
            });
            toast.success('Поле успішно додано');
          }
        });
      }
    }
  };
  const renderForm = ({
    values,
    setFieldValue,
    handleChange
  }: FormikProps<IRadioButtonField>) => <Form>
      <InputField extraBlockStyles={{
      marginBottom: '25px'
    }} name="question" onChange={setFieldValue} onKeyUp={() => handleKeyUp('question', setErrorMessage, errorMessage)} placeholder="Введіть запитання для поля" value={values.question} error={typeof errorMessage === 'object' ? getErrorMessage('question', errorMessage) : undefined} />

      <div className="field-info">
        <InputField extraBlockStyles={{
        marginBottom: '25px'
      }} name="analytics_title" onChange={setFieldValue} onKeyUp={() => handleKeyUp('analytics_title', setErrorMessage, errorMessage)} placeholder="Заголовок для дашбордів аналітики" value={values.analytics_title} error={typeof errorMessage === 'object' ? getErrorMessage('analytics_title', errorMessage) : undefined} />
        <InfoAlert signature="У цьому полі введіть загаловок який буде відображатись на дашборді аналітики." />
      </div>

      <div className="options">
        <FieldArray name="answers" render={({
        insert,
        remove,
        push
      }) => <div className="options-wrapper">
              {values.answers!.map((field: any, index: number) => <div className={`options-field ${field.showChoiceDescription ? 'options-field-detailed' : ''}`}>
                  <div className="options-raw">
                    <InputField type="text" name={`answers[${index}].text`} value={values.answers![index].text} placeholder="Введіть варіант відповіді" onChange={setFieldValue} onKeyUp={() => null} showError={false} />
                    <div className="options-delete">
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                      <button type="button" onClick={() => remove(index)}>
                        <DeleteIcon />
                      </button>
                    </div>
                    <div className="options-verticalLine" />
                    <div className="options-detailed">
                      <Checkbox name={`answers[${index}].showChoiceDescription`} value={field.showChoiceDescription!} onChange={e => {
                setFieldValue(`answers[${index}].showChoiceDescription`, !field.showChoiceDescription);
              }} />
                      <InfoAlert signature="Ви можете відобразити текстове поле якщо хочете щоб користувач розширив відповідь коли вибрав цю опцію" />
                    </div>
                  </div>
                  {field.showChoiceDescription && <div className="options-detailedFields">
                      <InputField type="text" name={`answers[${index}].choiceDescriptionTitle`} value={values.answers![index].choiceDescriptionTitle} placeholder="Введіть заголовок додаткового поля" onChange={setFieldValue} showError={false} />
                      <InputField type="text" name={`answers[${index}].choiceDescriptionPlaceholder`} value={values.answers![index].choiceDescriptionPlaceholder} placeholder="Введіть опис додаткового поля" onChange={setFieldValue} showError={false} />
                      <Checkbox name={`answers[${index}].choiceDescriptionRequired`} value={field.choiceDescriptionRequired!} onChange={e => {
              setFieldValue(`answers[${index}].choiceDescriptionRequired`, !field.choiceDescriptionRequired);
            }}>
                        <span className="checkboxValue">
                          Обовʼязкове заповнення поля
                        </span>
                      </Checkbox>
                    </div>}
                </div>)}

              <div className="options-addbtn">
                <TransparentButton handleClick={() => push({
            text: ''
          })} text="Додати варіант відповіді" filled />
              </div>
            </div>} />
      </div>

      <Checkbox name="required" value={values.required!} onChange={handleChange}>
        <span className="checkboxValue">Обовʼязкове поле</span>
      </Checkbox>

      <Checkbox name="key_question" value={values.key_question!} onChange={handleChange}>
        <span className="checkboxValue">Ключове питання</span>
      </Checkbox>

      <Checkbox name="isSentiment" value={values.isSentiment!} onChange={handleChange}>
        <span className="checkboxValue">Сентимент аналіз</span>
      </Checkbox>

      <SubmitButton extraBlockStyles={{
      marginTop: '20px'
    }} extraButtonStyles={{
      padding: '15px 45px'
    }}>
        Зберегти
      </SubmitButton>
    </Form>;
  useEffect(() => {
    if (field.id) {
      fieldsApi.getTargetField(field.id).then(res => {
        const required = res.data.options.find(option => option.key === 'required')?.value === 'true';
        const isSentiment = res.data.options.find(option => option.key === 'isSentiment')?.value === 'true';
        const key_question = res.data.options.find(option => option.key === 'key_question')?.value === 'true';
        const analytics_title = res.data.options.find(option => option.key === 'analytics_title')?.value || '';
        setApiField(res.data);
        initialRef.current = {
          question: res.data.question,
          isSentiment,
          answers: unfoldOptionsParam(res.data.answers as ExtendedAnswer[], res.data.options, 'answer_description_for'),
          analytics_title,
          required,
          key_question
        };
        setInitialValues({
          question: res.data.question,
          isSentiment,
          answers: unfoldOptionsParam(res.data.answers as ExtendedAnswer[], res.data.options, 'answer_description_for'),
          analytics_title,
          required,
          key_question
        });
      });
    } else {
      setInitialValues(initialValue);
    }
  }, [field]);
  return <FieldOptionsStyles>
      <div className="field-body">
        {initialValues && <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
            {renderForm}
          </Formik>}
      </div>
    </FieldOptionsStyles>;
};