import styled from 'styled-components';

export const DataForDownloadDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  width: 100%;
  align-items: center;
  overflow: auto;
  box-sizing: border-box;

  @media screen and (max-width: 1500px) {
    gap: 10px;
  }

  .rating {
    flex: 1;
    height: 100%;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box !important;

    &-col {
      box-sizing: border-box;
      font-size: var(--fs-5);
      display: flex;
      flex-direction: column;
    }

    &-item {
      font-size: var(--fs-3);

      @media screen and (max-width: 1500px) {
        font-size: var(--fs-5);
      }
    }
  }
`;
