import React, { FC } from 'react';
import { TIcon } from './TIcon';
export const FailedMassMailings: FC<TIcon> = React.memo(({
  color = '#0C0005',
  width = 36,
  height = 36
}) => <svg width={width} height={height} viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.2214 18.4283C17.8855 18.6523 17.4479 18.6523 17.112 18.4283L5.7176 10.8321C4.89436 10.2832 5.28289 9 6.2723 9L29.0612 9C30.0506 9 30.4391 10.2832 29.6159 10.8321L18.2214 18.4283Z" stroke={color} strokeWidth="2" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.66675 10H29.6667V14H31.6667V10C31.6667 8.89543 30.7713 8 29.6667 8H5.66675C4.56218 8 3.66675 8.89543 3.66675 10V26C3.66675 27.1046 4.56218 28 5.66675 28H29.6667C30.7713 28 31.6667 27.1046 31.6667 26V24H29.6667V26H5.66675V10Z" fill={color} />
    <circle cx="30.6667" cy="19" r="5" stroke={color} strokeWidth="2" />
    <path d="M32.0808 17.5858L29.2524 20.4142" stroke="black" strokeWidth="2" strokeLinecap="round" />
    <path d="M29.2527 17.5858L32.0811 20.4142" stroke="black" strokeWidth="2" strokeLinecap="round" />
  </svg>);