import styled from 'styled-components';
export const UsersStyles = styled.div`
  width: 100%;

  .titleContainer {
    .buttonsContainer {
      display: flex;

      & > div {
        margin-left: 24px;
        min-width: 100px;

        & > button {
          width: 100%;
        }
      }
    }
  }

  .searchWrapper {
    background-color: white;
    padding: 4px;
    margin-bottom: 15px;
    border-radius: 5px;
    .search {
      display: flex;
      align-items: center;
      padding: 0 19px;
      border-radius: 5px;
      background-color: #e7eef3;
      input {
        background-color: transparent;
        width: 100%;
        height: 3rem;
        margin-left: 11px;
        border: none;
        outline: none;
      }

      input::placeholder {
        font-size: var(--fs-2);
        color: #666666;
      }
    }
  }

  .editButtonsContainer {
    position: relative;
    z-index: 99;
    .menuOpenerContainer {
      position: relative;
      z-index: 109;

      .menuOpenerButton {
        padding: 0 20px;
        cursor: pointer;
      }

      .menu {
        position: relative;

        .menuList {
          z-index: 99;
          position: absolute;
          top: calc(50% + 15px);
          right: 16px;
          width: 260px;
          border-radius: 4px;
          border: 1px solid #e5e5e5;
          overflow: hidden;
          filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.14));

          list-style: none;
          font-size: var(--fs-3);
          color: #000000;

          li {
            height: 41px;
            background: #ffffff;
            border-bottom: 1px solid #e5e5e5;
            box-sizing: border-box;

            &:last-child {
              border-bottom: none;
            }
          }

          button {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0 16px;
            box-sizing: border-box;
            transition: background-color 0.3s;

            &:hover {
              background-color: #f0f5f5;
              transition: background-color 0.3s;
            }
          }

          span {
            margin-left: 12px;
          }
        }
      }
    }

    .modalBody {
      .modalButtonsWrap {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .modalButtonsCaption {
          margin-bottom: 16px;
          font-size: var(--fs-3);
          color: #000000;
        }

        div {
          display: flex;
          justify-content: space-between;
        }
      }

      .modalError {
        font-size: var(--fs-5);
        line-height: 120%;
        color: #c32900;
        margin-bottom: 0;
      }
    }
  }
`;