import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { ComplexGeneralDashboardStyles } from './styles';
import { analyticsApi, ApiComplexGeneral, ApiComplexRating, TPostComplexRating } from '../../../../api';
import { Table } from '../../../organisms';
import { PieChart } from '../../analyticsCharts';
import { setSaveFilters, setSaveLink, useAppDispatch, useAppSelector } from '../../../../state';
import { TGroup } from '../../../../pages';
import { getQuarterDates } from '../../../../utils';
import { useWindowSize } from 'usehooks-ts';
import { ArrowDownIcon } from '../../../../assets';
type TComplexGeneralDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
  group: TGroup;
};
const colors: string[] = ['#A7C5E9', '#BB6685', '#F5EF67', '#6EAE93'];
const ComplexGeneralDashboard_1 = _compiledBlock(_props => _props.v0, {
  name: "ComplexGeneralDashboard_1",
  portals: ["v0"]
});
export const ComplexGeneralDashboard: FC<TComplexGeneralDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions,
  group
}) => {
  const {
    data: userData
  } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<ApiComplexRating[]>([]);
  const [pie, setPie] = useState<ApiComplexGeneral>();
  const [loading, setLoading] = useState<boolean>(true);
  const [preloading, setPreloading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [direction, setDirection] = useState<boolean>(false);
  const [ratingBy, setRatingBy] = useState<string>('reviews');
  const dataCount = useRef<number>(0);
  useEffect(() => {
    if (!loading) {
      setData([]);
      setPreloading(true);
      setPageNumber(1);
      setReload(!reload);
    }
  }, [reQuery, direction, ratingBy]);
  useEffect(() => {
    const {
      companyID,
      year,
      quarter,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      sourceIDs,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostComplexRating = {
      year: +year as number,
      quarter: +quarter as number,
      csiFieldIDs: [858718, 858809, 858866],
      csiPositiveAnswers: ['Чудово', 'Добре'],
      npsFieldIDs: [858735, 858818, 858826],
      npsNegativeAnswers: ['0', '1', '2', '3', '4', '5', '6', '0/10', '1/10', '2/10', '3/10', '4/10', '5/10', '6/10'],
      npsPositiveAnswers: ['9', '10', '9/10', '10/10'],
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean,
      sourceIDs: sourceIDs as number[],
      withTrustLevel: group.key === 'quarterly',
      regionLevel: 3,
      orderDir: direction ? 'desc' : 'asc',
      ratingBy,
      ...getQuarterDates(+quarter, +year)
    };
    setLoading(true);
    Promise.all([analyticsApi.getComplexRatingData(+companyID, filteredData, (pageNumber ? +pageNumber - 1 : 0) * 10, 10, !userData), analyticsApi.getComplexGeneral(+companyID, filteredData, !userData)]).then(([data, pie]) => {
      setData(prev => [...prev, ...data.data]);
      dataCount.current = data.count ? data.count : 0;
      setPie(pie.data);
      setLoading(false);
      setPreloading(false);
    }).finally(() => {
      setLoading(false);
    });
    dispatch(setSaveLink('analytics/statistics/general/file'));
    dispatch(setSaveFilters({
      year: +year as number,
      quarter: +quarter as number,
      csiFieldIDs: [858718, 858809, 858866],
      csiPositiveAnswers: ['Чудово', 'Добре'],
      npsFieldIDs: [858735, 858818, 858826],
      npsNegativeAnswers: ['0', '1', '2', '3', '4', '5', '6', '0/10', '1/10', '2/10', '3/10', '4/10', '5/10', '6/10'],
      npsPositiveAnswers: ['9', '10', '9/10', '10/10'],
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      sourceIDs: sourceIDs as number[],
      isDiia: isDiia as boolean
    }));
  }, [pageNumber, reload]);
  useEffect(() => {
    setTableHeaders([{
      Header: 'IDF',
      accessor: 'idf',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.idf}</span>
          </div>
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <div className="dashboard-name">
            <span>Регіон</span>
          </div>,
      accessor: 'regionName',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.regionName}</span>
          </div>
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <div className="dashboard-name">
            <span>Назва центру</span>
          </div>,
      accessor: 'name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.name}</span>
          </div>
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <div className="direction" onClick={() => {
        setDirection(!direction);
        setRatingBy('csi');
      }}>
            {ratingBy === 'csi' && <div className={`direction-arrow ${direction ? 'active' : ''}`}>
                <ArrowDownIcon />
              </div>}
            <span>CSI</span>
          </div>,
      accessor: 'csi',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>
              {data.row.original.csi ? data.row.original.csi.toFixed(1) : ''}
            </span>
          </div>
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <div className="direction" onClick={() => {
        setDirection(!direction);
        setRatingBy('nps');
      }}>
            {ratingBy === 'nps' && <div className={`direction-arrow ${direction ? 'active' : ''}`}>
                <ArrowDownIcon />
              </div>}
            <span>NPS</span>
          </div>,
      accessor: 'nps',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>
              {data.row.original.nps ? data.row.original.nps.toFixed(1) : ''}
            </span>
          </div>
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <div className="direction" onClick={() => {
        setDirection(!direction);
        setRatingBy('reviews');
      }}>
            {ratingBy === 'reviews' && <div className={`direction-arrow ${direction ? 'active' : ''}`}>
                <ArrowDownIcon />
              </div>}
            <span>Кількість відгуків</span>
          </div>,
      accessor: 'reviews',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>
              {data.row.original.reviews}
            </span>
          </div>
    }]);
  }, [data]);
  return /*@million jsx-skip*/<ComplexGeneralDashboard_1 v0={<ComplexGeneralDashboardStyles>
      <div className="charts">
        <div className="charts-item charts-one">
          <h4>CSI</h4>
          <div className="counts">
            <h2>{pie?.csi.toFixed(1)}</h2>
          </div>
        </div>
        <div className="charts-item charts-two">
          <h4>NPS</h4>
          <div className="counts">
            <h2>{pie?.nps.toFixed(1)}</h2>
          </div>
        </div>
        <div className="charts-item charts-three">
          <h4>Кількість точок</h4>
          <div className="counts">
            <h2>{pie?.locations}</h2>
          </div>
        </div>
        <div className="charts-item charts-four">
          <h4>Відгуків</h4>
          <div className="counts">
            <h2>{pie?.reviews}</h2>
          </div>
        </div>
        <div className="charts-item charts-five">
          {pie?.csiDistribution?.map((e, i) => ({
          label: e.value,
          value: e.part,
          fill: colors[i]
        })).map((el, index) => <div className="charts-legend" key={index}>
              {(index === 2 || index === 1) && <span style={{
            background: colors[index]
          }} />}
              <p>{`${el.value.toFixed(1)}%`}</p>
              {(index === 0 || index === 3) && <span style={{
            background: colors[index]
          }} />}
            </div>)}
          <PieChart data={pie ? pie?.csiDistribution.map((el, index) => ({
          label: el.value,
          value: el.part,
          fill: colors[index]
        })) : []} />
        </div>
        <div className="charts-item charts-eight legend">
          <div className="legend-item">
            <div style={{
            background: colors[3]
          }} />
            <p>Чудово</p>
          </div>
          <div className="legend-item">
            <div style={{
            background: colors[0]
          }} />
            <p>Добре</p>
          </div>
          <div className="legend-item">
            <div style={{
            background: colors[2]
          }} />
            <p>Прийнятно</p>
          </div>
          <div className="legend-item">
            <div style={{
            background: colors[1]
          }} />
            <p>Жахливо</p>
          </div>
        </div>
        <div className="charts-item charts-six">
          {tableHeaders && <Table columns={tableHeaders} data={data} fullWidthColumns={['name', 'answers']} totalCount={dataCount.current} currentPage={pageNumber} offset={10} loading={loading} pageNumberHandler={value => setPageNumber(value)} fixedHeader extraFieldsSettings={{
          nps: {
            justifyContent: 'center'
          },
          csi: {
            justifyContent: 'center'
          },
          reviews: {
            justifyContent: 'center'
          }
        }} />}
        </div>
      </div>
    </ComplexGeneralDashboardStyles>} />;
};