import { accessesApi } from '../../../api/accessesApi/Accesses';
import { TOption } from '../../../components/molecules/customFilter/types';
export enum FilterKeys {
  managerNames = 'managerNames',
  managerOrgNames = 'managerOrgNames',
  managerOrgTypes = 'managerOrgTypes',
  workerOrgTypes = 'workerOrgTypes',
  workerRegions = 'workerRegions',
}
const fetchMethods = {
  // manager filters
  managerNames: (searchString: string, limit: number, offset: number) => accessesApi.accessReviewManagerNameFilters(searchString, limit, offset),
  managerOrgNames: (searchString: string, limit: number, offset: number) => accessesApi.accessReviewManagerOrgNameFilters(searchString, limit, offset),
  managerOrgTypes: (searchString: string, limit: number, offset: number) => accessesApi.accessReviewManagerOrgTypeFilters(searchString, limit, offset),
  // worker filters
  workerOrgTypes: (searchString: string, limit: number, offset: number) => accessesApi.accessReviewWorkerOrgTypeFilters(searchString, limit, offset),
  workerRegions: (searchString: string, limit: number, offset: number) => accessesApi.accessReviewWorkerRegionFilters(searchString, limit, offset)
};
export const usePaginatedFilters = (): {
  getPaginatedInitialValues?: (key: FilterKeys, limit: number, offset: number, q: string) => Promise<{
    count: number;
    items: TOption[];
  }>;
} => {
  const getPaginatedInitialValues = async (key: FilterKeys, l: number, o: number, q: string): Promise<{
    count: number;
    items: TOption[];
  }> => {
    const {
      data
    } = await fetchMethods[key](q, l, o);
    return {
      count: data.count,
      items: data.items.map((e: string) => ({
        id: e,
        value: false,
        name: e
      }))
    };
  };
  return {
    getPaginatedInitialValues
  }; // Empty dependency array means this runs once on mount
};