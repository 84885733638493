import React, { FC, useEffect, useRef, useState } from 'react';
import { SmsCampaignsStyles } from './styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { availablePageSizes } from '../../../constants';
import { ApiSmsCampaigns, massMailingApi } from '../../../api';
import { TablePagination } from '../table/tablePagination';
import { Campaign } from './campaign';
type TSmsCampaigns = {
  companyID: number;
  reQuery: boolean;
  filters: {
    [key: string]: number | string | string[] | number[];
  };
};
export const SmsCampaigns: FC<TSmsCampaigns> = ({
  companyID,
  reQuery,
  filters
}) => {
  const {
    pageNumber
  } = useParams();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(availablePageSizes[1]);
  const [campaignsSms, setCampaignsSms] = useState<ApiSmsCampaigns[]>([]);
  const [campaigns, setCampaigns] = useState<number>(0);
  useEffect(() => {
    setCampaignsSms([]);
    navigate('/massmailer/sms/1');
  }, [reQuery]);
  useEffect(() => {
    if (pageNumber) {
      massMailingApi.massMailingSmsCampaignList(companyID, (pageNumber ? +pageNumber - 1 : 0) * pageSize, pageSize, filters.from as string, filters.to as string).then(res => {
        if (res.statusCode === 200) {
          setCampaignsSms(res.data);
          setCampaigns(res.count ? res.count : 0);
        }
      });
    }
  }, [pageNumber, pageSize, reQuery]);
  return <SmsCampaignsStyles>
      <div className="massmailing-campaigns">
        {campaignsSms.map(campaign => <Campaign key={campaign.id} campaign={campaign} />)}
      </div>
      {!!pageNumber && !!campaigns && <TablePagination pageSize={pageSize} setPageSize={setPageSize} currentPage={+pageNumber} pageNumberHandler={page => {
      if (page) {
        navigate(`/massmailer/sms/${page}`);
      }
    }} rowsQuantity={campaigns} />}
    </SmsCampaignsStyles>;
};