import React, { useState, useRef, useEffect } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { CsiFilterStyles } from './styles';
import { translations } from '../../../../i18n';
import { useAppSelector } from '../../../../state';
import CustomSelectWithMultipleCheckboxes from '../../../form/customSelect/CustomSelectWithMultipleCheckboxes';
import SubmitButton from '../../../form/submitButton/SubmitButton';
import { TransparentButton } from '../../../atoms/transparentButton/TransparentButton';
import { Loader } from '../../..';
import { extraOneOptionStyles, resetFilterButtonStyles } from '../reviewsTableFiltersUtils';
import { ICsiFilter, CsiFilterValues } from './types';
export const CsiFilter = ({
  filterParams,
  setReQuery,
  setApply,
  setFilterParams,
  onClose
}: ICsiFilter) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [initialValues, setInitialValues] = useState<CsiFilterValues>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const ref = useRef<any>();
  const formRef = useRef<FormikProps<CsiFilterValues>>(null);
  async function onSubmit(values: CsiFilterValues, {
    setSubmitting
  }: FormikHelpers<CsiFilterValues>) {
    const params: {
      [key: string]: number | string | any;
    } = {};
    const checkedCsi = values?.csi?.filter(item => item.value)?.map(item => item.name);
    if (checkedCsi && checkedCsi?.length > 0) {
      params.csi = checkedCsi;
    } else if (filterParams?.csi && checkedCsi && checkedCsi?.length <= 0) {
      delete filterParams.csi;
    }
    const resultParams: {
      [key: string]: any;
    } = {};
    Object.entries({
      ...filterParams,
      ...params
    }).forEach(item => {
      if (!!item[0] && item[1] !== undefined && item[1] !== null && item[1] !== '') {
        resultParams[item[0]] = item[1];
      }
    });
    setFilterParams(resultParams);
    setApply(resultParams);
    setReQuery();
    setSubmitting(false);
  }
  const additionalResetHandlers = () => {
    if (filterParams.csi) {
      delete filterParams.csi;
    }
    setFilterParams({
      ...filterParams
    });
    setApply({
      ...filterParams
    });
    setReQuery();
    onClose();
  };
  useEffect(() => {
    const csiFormattedArray = [{
      id: 1,
      name: 'Чудово',
      value: false
    }, {
      id: 2,
      name: 'Добре',
      value: false
    }, {
      id: 3,
      name: 'Прийнятно',
      value: false
    }, {
      id: 4,
      name: 'Жахливо',
      value: false
    }];
    const csiArray = filterParams?.csi && filterParams.csi.length ? csiFormattedArray.map(csi => ({
      id: csi.id,
      name: csi.name,
      value: filterParams.csi.includes(csi.name)
    })) : csiFormattedArray.map(csi => ({
      id: csi.id,
      name: csi.name,
      value: false
    }));
    setInitialValues({
      csi: csiArray
    });
  }, [filterParams.csi]);
  const renderForm = ({
    values,
    handleChange,
    resetForm
  }: FormikProps<CsiFilterValues>) => <Form>
      <div>
        <SubmitButton extraButtonStyles={{
        width: '100%',
        maxWidth: '500px',
        height: 32,
        fontSize: 'var(--fs-5)',
        fontWeight: 500
      }}>
          {translations[interfaceLanguage].apply_filters_button}
        </SubmitButton>
        {typeof errorMessage === 'string' && <p className="extraErrorMessage">{errorMessage}</p>}
        <TransparentButton handleClick={() => {
        resetForm();
        additionalResetHandlers();
      }} text={translations[interfaceLanguage].reset_reviews_filter} extraStyles={resetFilterButtonStyles} extraButtonTextStyles={{
        color: '#ffffff',
        fontSize: 'var(--fs-5)',
        fontWeight: 500
      }} />
        <CustomSelectWithMultipleCheckboxes name="csi" handleChange={handleChange} options={values.csi || []} extraOneOptionStyles={extraOneOptionStyles} optionsContainerStyles={{
        width: '100%'
      }} placeholder={translations[interfaceLanguage].select_multiple_statuses} placeholderStyles={{
        color: '#666666'
      }} oneOptionValueStyles={{
        marginLeft: 4,
        fontSize: 'var(--fs-5)',
        whiteSpace: 'nowrap'
      }} alwaysOpened />
      </div>
    </Form>;
  return <CsiFilterStyles ref={ref}>
      {initialValues ? <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSubmit}>
          {renderForm}
        </Formik> : <Loader margin={0} />}
    </CsiFilterStyles>;
};