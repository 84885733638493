import styled from 'styled-components';

export const ReviewStyles = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: calc(-62px + 100vh);
  flex: 1;
  box-sizing: border-box;

  .subBtns {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .submitContainer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    button {
      height: 32px;
    }
  }

  .extraBlockStyles {
    margin-bottom: 0;
  }

  .quantityCounterContainer.quantityCounterContainerExtraStyles {
    margin-top: 0;
  }

  .mainWrapper {
    display: flex;
    flex: 1;

    h3 {
      width: 100%;
      font-size: var(--fs-1);
    }

    .dashed-line {
      width: 100%;
      border-bottom: 1px dashed #e1e1e1;
      margin: 16px 0;
    }

    .mainChatTickets,
    .answersWrapper,
    .mainManagerForm {
      width: calc(33.33% - 64px);
      flex: 1;
      position: relative;
      overflow: auto;
      box-sizing: border-box !important;
      min-width: 320px;
      padding: 20px 30px;
      display: flex;
      flex-direction: column;
      flex: 1;

      @media screen and (max-width: 1500px) {
        width: calc(50% - 64px);
        height: auto;
      }
      @media screen and (max-width: 991px) {
        width: 100%;
        height: auto;
      }
    }

    .mainChatTickets {
      overflow: visible;
    }

    .answersWrapper {
      .reviewInfo {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &-item {
          display: flex;
          align-items: center;
          gap: 5px;

          > span:nth-child(1) {
            line-height: 120%;
            font-size: var(--fs-3);
            color: #666666;
            width: 35%;
          }

          > span:nth-child(2) {
            line-height: 120%;
            font-size: var(--fs-3);
            font-weight: 700;
            color: #000000;

            &.btn {
              text-decoration: underline;
              cursor: pointer;
            }
          }

          .responsibleIds {
            display: flex;
            flex-direction: column;

            span {
              line-height: 120%;
              font-size: var(--fs-3);
              font-weight: 700;
              color: #000000;
              margin-bottom: 5px;
              &.btn {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }

          .responsibles {
            display: flex;
            flex-direction: column;
            gap: 5px;
            span {
              line-height: 120%;
              font-size: var(--fs-3);
              font-weight: 700;
              color: #000000;
            }
          }

          .connectionChannelsContainer {
            display: flex;
            align-items: center;
            gap: 5px;
          }

          .userContainer {
            display: flex;
            align-items: center;
            gap: 10px;

            .copyBtn {
              height: 20px;
              cursor: pointer;
            }

            a {
              &:link,
              &:visited {
                color: #000;
              }
            }

            span {
              line-height: 120%;
              font-size: var(--fs-3);
              font-weight: 700;
              color: #000000;
            }

            img {
              max-width: 60px;
            }
          }
        }
      }
      .filesContainer {
        display: flex;
        flex-wrap: wrap;

        .oneFile {
          margin-right: 10px;
          display: flex;
          align-items: center;
          width: fit-content;

          &:last-child {
            margin-right: 0px;
          }
        }

        .fileContainer {
          height: 28px;
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 0 8px;
          background: #f0f5f5;
          border-radius: 4px;
          cursor: pointer;
          margin-left: 16px;

          &:first-child {
            margin-left: 0;
          }

          & > div {
            display: flex;
            align-items: center;
          }

          a,
          span {
            color: #000;
          }
        }
      }
    }

    .mainChatTickets {
      .tabsContainer {
        width: 100%;
        padding-left: 16px;
        padding-right: 32px;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        border-bottom: none;

        button {
          height: 24px;
          display: flex;
          align-items: flex-start;
          border-bottom: 3px solid #fff;
          margin-right: 32px;

          font-size: var(--fs-1);
          line-height: 120%;
          color: #7c7878;
          transition: all 0.3s;

          &:last-child {
            margin-right: 0;
          }

          &.active {
            border-bottom: 3px solid #000;
            color: #000;
            transition: all 0.3s;
          }
        }
      }
    }

    .mainManagerForm {
      background: rgb(231, 238, 243);
      overflow: auto;

      .colapsLabel {
        margin-top: 16px;
        font-weight: 500;
        font-size: var(--fs-3);
        line-height: 120%;
        color: rgb(0, 0, 0);
        text-decoration: underline;
        display: flex;
        align-items: center;
        user-select: none;
        gap: 10px;

        &:hover {
          cursor: pointer;
        }
      }

      .colapsWrapper {
        opacity: 0;
        overflow: hidden;
        max-height: 0;
        transition: opacity 0.3s ease-in-out;
      }
      .colapsWrapper.show {
        opacity: 1;
        max-height: none;
        margin-top: 8px;
      }

      .contactsContainer {
        display: grid;
        gap: 16px;

        > div:last-child {
          margin-bottom: 42px;
        }
      }
    }

    .listWrapper {
      display: grid;
      gap: 20px;
      border-bottom: 1px dashed #e5e5e5;
      padding-bottom: 24px;
      font-size: var(--fs-3);

      .ticket-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      .del-ticket {
        transform: rotate(45deg);
        cursor: pointer;
        position: absolute;
        right: 5px;
        top: 5px;
      }

      .listTitle,
      .noIssues {
        font-size: var(--fs-3);
        color: #000;
      }

      .noIssues {
        margin: auto;
      }
    }

    .typeSelectorsContainer {
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .addFieldButtonContainer {
      display: flex;
      justify-content: flex-end;

      button {
        height: 32px;
      }
    }

    .answersContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 15px;

      h3 {
        width: 100%;
        font-weight: 500;
        font-size: var(--fs-1);
        line-height: 120%;
        color: #202020;
      }

      .checkboxInputWrapper {
        margin-top: 10px;
      }

      .checkboxContainer {
        display: grid;
        gap: 8px;

        .checkboxWrapper {
          display: grid;
          grid-template-columns: 16px auto;
          align-items: center;
          grid-gap: 8px;
          margin-bottom: 10px;
        }

        .checkbox {
          border: 1px solid #e5e5e5;
          border-radius: 2px;
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;

          &Selected {
            border: 1px solid #0e9285;
          }
        }
      }

      .radioContainer {
        display: grid;
        gap: 8px;

        .radioWrapper {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 5px;
        }

        .radio {
          border: 1px solid #e5e5e5;
          border-radius: 8px;
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;

          &Selected {
            border: 1px solid #0e9285;
          }
        }
      }

      .inputWrapper {
        display: grid;
        width: 100%;

        .input {
          box-sizing: border-box;
          min-height: 40px;
          width: 100%;
          padding: 12px 16px;
          border: 1px solid #e5e5e5;
          background: #f9f9f9;
          border-radius: 3px;
          font-weight: 400;
          font-size: var(--fs-3);
          color: #000000;
        }

        .inputLabel {
          font-weight: 500;
          font-size: var(--fs-3);
          line-height: 120%;
          color: #000000;
          margin-bottom: 8px;

          &.other {
            margin-top: 12px;
          }
        }

        &-withValue {
          line-height: 120%;
          color: #000000;
        }
      }

      .answerLabel {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 14px;

        h4 {
          font-weight: 500;
          font-size: var(--fs-3);
          line-height: 120%;
          color: #202020;
          margin: 0;
        }
      }

      .questionDescription {
        font-weight: 500;
        font-size: var(--fs-1);
        line-height: 150%;
        color: #202020;
        margin-bottom: 20px;
      }

      .scaleContainer {
        max-width: 414px;
        width: 100%;

        footer {
          margin-bottom: 0;
        }

        button {
          cursor: auto;
        }
      }

      .hiddenContainer {
        display: flex;
        gap: 10px;
        align-items: center;

        .hiddenKey {
          font-size: var(--fs-3);
          font-weight: bold;

          span {
            font-weight: 400;
          }
        }
        .hiddenValue {
          font-size: var(--fs-3);
          font-weight: bold;
        }
      }

      .detailedContainer {
        .detailedValue {
          font-size: var(--fs-3);
          font-weight: bold;
        }
      }

      .selectableContainer {
        .selectableValue {
          font-size: var(--fs-3);
          font-weight: bold;
        }
      }
    }
  }

  .reviewTypeSelectContainer {
    margin-top: 48px;

    .selectContainer {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;

      & > .buttonContainer {
        margin-left: 16px;
      }
    }

    .reviewTypeItemRow {
      height: 32px;
      box-sizing: border-box;
      border-bottom: 1px solid #c4c4c4;
      padding: 0 16px;
      display: flex;
      align-items: center;
    }

    .reviewTypeItem {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 8px;
      align-items: center;

      p {
        text-align: left;
      }
    }
  }

  .chatContainer {
    position: relative;
    display: flex;
    flex-direction: column;

    .chatted {
      display: flex;
      border: 1px solid #eee;
      border-bottom: none;
      justify-content: space-around;

      &-active {
        background: rgb(240, 245, 245);
        color: #000;
        border: 1px solid #000;
      }

      &-item {
        padding: 10px 0;
        width: 50%;
        text-align: center;
        font-size: var(--fs-3);
        cursor: pointer;
      }
    }

    .reviewDiscussionHistory {
      width: 100%;
      height: 460px;
      overflow-y: auto;
      padding: 16px;
      box-sizing: border-box;
      border: 1px solid #eee;
      position: relative;

      &.chatIsClosedReviewHistory {
        border-bottom: none;
      }

      .message {
        width: 80%;
        margin-bottom: 16px;
        padding: 8px;
        box-sizing: border-box;
        font-size: var(--fs-3);
        word-wrap: break-word;
        overflow-wrap: break-word;
        hyphens: auto;

        &:last-child {
          margin-bottom: 0;
        }

        .messageText {
          white-space: pre-line;
        }

        .chatImg {
          width: 100%;
          height: 90%;
          cursor: pointer;
          object-fit: cover;
        }

        .chatLink {
          width: fit-content;
          display: flex;
          align-items: center;
          gap: 5px;
          border-radius: 8px;
          padding: 5px;
          background: #fff;
          margin-top: 5px;

          span {
            text-decoration: underline;
            color: #000;
          }
        }

        .authorAndDate {
          font-style: italic;
          margin-top: 8px;
          display: flex;
          justify-content: space-between;
        }

        &.user {
          float: left;
          border-radius: 0 8px 8px 8px;
          background-color: #eee;

          .authorAndDate {
            color: #222;
          }
        }

        &.admin {
          float: right;
          border-radius: 8px 0 8px 8px;
          background-color: #000;
          color: #fff;

          .authorAndDate {
            color: #ddd;
          }
        }
      }

      .openDialogButtonContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 300px;
      }
      .chatHistoryEmptyContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 300px;
      }
    }
  }

  .chatErrorModalContent {
    background-color: #fff;
    padding: 16px;

    .errorDetails {
      margin-top: 16px;
      font-size: var(--fs-5);
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 16px;
      color: red;
    }
  }

  .messageInputContainer {
    display: grid;
    grid-template-columns: 1fr auto;
    position: relative;

    .selectedFile {
      position: absolute;
      background: #d9d9d9;
      border-radius: 5px;
      padding: 5px;
      right: 10px;
      top: -75px;

      .delBtn {
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 50%;
        background: #fff;
        width: 15px;
        height: 15px;
      }

      svg {
        width: 50px;
        height: 50px;
      }
    }

    .selectedImg {
      position: absolute;
      right: 10px;
      top: -110px;

      .delBtn {
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 50%;
        background: #fff;
        width: 15px;
        height: 15px;
      }

      img {
        object-fit: cover;
        width: 100px;
        height: 100px;
        border-radius: 5px;
      }
    }

    .chatTemplates {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      background: #fff;
      width: 100%;
      overflow: auto;
      border: 1px solid #eee;
      top: 100px;
      max-height: 200px;
      border-radius: 4px;

      .chatTemplate {
        margin: 5px 0;
        cursor: pointer;
        position: relative;
        font-size: var(--fs-5);
        z-index: 999;
        border: 1px solid #eee;
        padding: 10px 5px;
        transform: scale(0.95);
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          transform: scale(1);
        }

        b {
          font-size: var(--fs-5);
        }
      }
    }

    .emojiContainer {
      position: absolute;
      z-index: 10;
      top: 100px;
      left: 50%;
      transform: translate(-50%);
    }

    .btnWrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100px;
    }

    .submitButton {
      padding-top: 1px;
      width: 50px;
      box-sizing: border-box;
      border: 1px solid #eee;
      border-top: none;
      border-left: none;
      height: 50px;
      cursor: pointer;
    }

    .fileBtn {
      cursor: pointer;
      padding-top: 1px;
      width: 50px;
      box-sizing: border-box;
      border: 1px solid #eee;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .chatIsFinished {
    padding: 12px 0px 20px;
    color: #000;
    font-weight: 700;
    text-align: center;
    background-color: #ffffff;
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
    border-left: 1px solid #eee;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &::before {
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
      content: '';
      width: 90%;
      height: 2px;
      background-color: #000;
    }
  }

  .templateWrapper {
    width: 100%;
    display: grid;
    gap: 10px;

    .templateContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;
      padding: 12px 8px 12px 16px;
      margin-left: auto;

      background: #ffffff;

      border: 1px solid #e5e5e5;
      border-radius: 4px;

      text-align: left;
      font-weight: 400;
      font-size: var(--fs-3);
      line-height: 19px;

      color: #000000;

      &.active {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .modalContainer {
    background: #fff;
    color: #000000;
    border-radius: 4px;

    .body {
      margin-bottom: 15px;
      border-bottom: 1px solid #e5e5e5;

      h6 {
        font-size: var(--fs-2);
        margin-top: 0;
        margin-bottom: 16px;
        text-align: center;
      }

      p {
        font-size: var(--fs-3);
        margin: 0;
      }
    }

    .footer {
      height: 4rem;
      padding: 0 16px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &.right {
        grid-template-columns: 1fr auto;
      }

      & > div,
      a {
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
      }

      & > div {
        width: 100%;
        margin: 0;

        a,
        button {
          width: 100%;
        }
      }
    }
  }

  .selectedTypeColor {
    display: flex;
    flex-direction: column;

    span {
      font-weight: 500;
      font-size: var(--fs-3);
      line-height: 120%;
      color: #000000;
      margin-bottom: 8px;
    }

    .selectedTypeColorWrap {
      display: flex;
      align-items: center;

      .selectedTypeColorContainer {
        flex-grow: 1;
        display: grid;
        grid-template-columns: 1fr;
      }

      .selectedTypeColorRow {
        flex-grow: 1;
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        background-color: #ffffff;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 0 16px;
      }

      .selectedTypeColorItem {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 8px;
        align-items: center;

        p {
          text-align: left;
        }
      }
    }

    .formErrorContainer {
      height: 24px;
      font-size: var(--fs-5);
      line-height: 120%;
      color: #c32900;
    }

    .required {
      color: #ff0101;
    }
  }

  .closeReviewDialogContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .loaderReviewPageContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
