import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { FC, useEffect, useRef, useState } from 'react';
import { networkIcon } from '../../../../constants';
import { getTranslationByLangOrEng, translations } from '../../../../i18n';
import { useAppSelector } from '../../../../state';
import { Loader, TransparentButton } from '../../../atoms';
import CustomSelectWithMultipleCheckboxes from '../../../form/customSelect/CustomSelectWithMultipleCheckboxes';
import SubmitButton from '../../../form/submitButton/SubmitButton';
import { extraOneOptionStyles, resetFilterButtonStyles } from '../reviewsTableFiltersUtils';
import { NetworkFilterStyles } from './NetworkFilterStyles';
const networks: string[] = ['email', 'phone', 'telegram', 'viber', 'whatsapp'];
interface NetworkFilterValues {
  networks: {
    value: boolean;
    name: string;
    key: string;
    icon: any;
  }[] | undefined;
}
interface INetworkFilter {
  filterParams: {
    [key: string]: number | string | any;
  };
  setReQuery: () => void;
  setApply: (params: {
    [key: string]: number | string;
  }) => void;
  setFilterParams: (params: {
    [key: string]: number | string;
  }) => void;
  onClose: () => void;
}
export const NetworkFilter: FC<INetworkFilter> = React.memo(({
  filterParams,
  setReQuery,
  setApply,
  setFilterParams,
  onClose
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [initialValues, setInitialValues] = useState<NetworkFilterValues>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const ref = useRef<any>();
  const formRef = useRef<FormikProps<NetworkFilterValues>>(null);
  const additionalResetHandlers = () => {
    if (filterParams.contacts) {
      delete filterParams.contacts;
    }
    setFilterParams({
      ...filterParams
    });
    setApply({
      ...filterParams
    });
    setReQuery();
    onClose();
  };
  async function onSubmit(values: NetworkFilterValues, {
    setSubmitting
  }: FormikHelpers<NetworkFilterValues>) {
    const params: {
      [key: string]: number | string | any;
    } = {};
    const checkedNetworks = values?.networks?.filter(item => item.value)?.map(item => item.key);
    if (checkedNetworks && checkedNetworks?.length > 0) {
      params.contacts = checkedNetworks;
    } else if (filterParams?.contacts && checkedNetworks && checkedNetworks?.length <= 0) {
      delete filterParams.contacts;
    }
    const resultParams: {
      [key: string]: any;
    } = {};
    Object.entries({
      ...filterParams,
      ...params
    }).forEach(item => {
      if (!!item[0] && item[1] !== undefined && item[1] !== null && item[1] !== '') {
        resultParams[item[0]] = item[1];
      }
    });
    setFilterParams(resultParams);
    setApply(resultParams);
    setReQuery();
    setSubmitting(false);
  }
  useEffect(() => {
    const networksData = filterParams?.contacts && filterParams.contacts.length ? networks.map(network => ({
      key: network,
      name: getTranslationByLangOrEng(interfaceLanguage, `${network}_network`),
      value: filterParams.contacts.includes(network),
      icon: networkIcon(network)
    })) : networks.map(network => ({
      key: network,
      name: getTranslationByLangOrEng(interfaceLanguage, `${network}_network`),
      value: false,
      icon: networkIcon(network)
    }));
    setInitialValues({
      networks: networksData
    });
  }, []);
  const renderForm = ({
    values,
    errors,
    setFieldValue,
    handleChange,
    resetForm
  }: FormikProps<NetworkFilterValues>) => <Form>
        <div>
          <SubmitButton extraButtonStyles={{
        width: '100%',
        maxWidth: '500px',
        height: 32,
        fontSize: 'var(--fs-5)',
        fontWeight: 500
      }}>
            {translations[interfaceLanguage].apply_filters_button}
          </SubmitButton>
          {typeof errorMessage === 'string' && <p className="extraErrorMessage">{errorMessage}</p>}
          <TransparentButton handleClick={() => {
        resetForm();
        additionalResetHandlers();
      }} text={translations[interfaceLanguage].reset_reviews_filter} extraStyles={resetFilterButtonStyles} extraButtonTextStyles={{
        color: '#ffffff',
        fontSize: 'var(--fs-5)',
        fontWeight: 500
      }} />

          <CustomSelectWithMultipleCheckboxes name="networks" handleChange={handleChange} options={values.networks || []} extraOneOptionStyles={extraOneOptionStyles} optionsContainerStyles={{
        width: '100%'
      }} placeholderStyles={{
        color: '#666666'
      }} oneOptionValueStyles={{
        marginLeft: '4px'
      }} alwaysOpened />
        </div>
      </Form>;
  return <NetworkFilterStyles ref={ref}>
        {initialValues ? <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSubmit}>
            {renderForm}
          </Formik> : <Loader margin={0} />}
      </NetworkFilterStyles>;
});