import React, { FC, useEffect, useState } from 'react';
import { StatusesCounterStyles } from './StatusesCounterStyles';
import { useAppSelector } from '../../../state';
import { reviewApi } from '../../../api';
import { reviewsStatusIconsAndText } from '../../../constants';
import { truncateString } from '../../../utils';
type TStatusesCounter = {
  clientStatuses: number[];
  filterParams: any;
};
export const StatusesCounter: FC<TStatusesCounter> = React.memo(({
  clientStatuses,
  filterParams
}) => {
  const {
    data
  } = useAppSelector(state => state.user);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [status, setStatus] = useState<{
    count: number;
    status: number;
  }[]>([]);
  useEffect(() => {
    reviewApi.getFilteredStatusesCount(0, {
      ...filterParams,
      sources: filterParams.sources?.map((e: string) => +e),
      years: filterParams.years?.map((e: string) => +e)
    }).then(res => {
      if (res.statusCode === 200) {
        setStatus(res.data);
      }
    });
  }, [filterParams]);
  return <StatusesCounterStyles>
        <div className="wrapper">
          {status && status.filter(el => clientStatuses.includes(el.status)).map(item => {
        const statusObject = reviewsStatusIconsAndText(interfaceLanguage)[item.status];
        const Icon = statusObject.icon;
        return <div key={item.status} className="status" title={statusObject.text}>
                    <div className="statusHead">
                      <div className="statusCount">{item.count}</div>
                      <Icon width={20} height={20} />
                    </div>
                    <div className="statusContent">
                      <div className="statusName">
                        {truncateString(statusObject.text, 30)}
                      </div>
                    </div>
                  </div>;
      })}
        </div>
      </StatusesCounterStyles>;
});