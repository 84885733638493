import styled from 'styled-components';

export const LeavePageModalStyles = styled.div`
  background: #fff;
  color: #000000;
  border-radius: 4px;

  .body {
    margin-bottom: 15px;
    border-bottom: 1px solid #e5e5e5;

    h6 {
      font-size: var(--fs-1);
      margin-top: 0;
      margin-bottom: 16px;
      text-align: center;
    }

    p {
      font-size: var(--fs-3);
      margin: 0;
    }
  }

  .footer {
    height: 4rem;
    padding: 0 16px;
    box-sizing: border-box;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    & > div,
    a {
      display: grid;
      grid-template-columns: 1fr;
      place-items: center;
    }

    & > div {
      width: 100%;
      margin: 0;

      a,
      button {
        width: 100%;
      }
    }
  }
`;
