import styled from 'styled-components';

export const TablePaginationStyles = styled.div<{ mobileVersion: boolean }>`
  padding: 5px 0;
  border-bottom: 1px solid #f0f0f0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  select {
    border: none;
    background: none;
  }

  .arrowsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    .arrows {
      display: flex;
      justify-content: space-between;

      svg {
        @media screen and (max-width: 1500px) {
          width: 24px;
          height: 24px;
        }
      }
    }

    .pageCounter {
      color: #000;
      padding: 0 16px;

      span {
        font-weight: bold;
        font-size: var(--fs-3);
      }

      .currentPage {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        text-align: center;
        background-color: transparent;
        border: 2px solid #0c000533;
        font-size: var(--fs-3);
        font-weight: 500;
        border-radius: 12px;

        @media screen and (max-width: 1500px) {
          width: 24px;
          height: 24px;
          border-radius: 6px;
        }

        &::placeholder {
          font-size: var(--fs-3);
          color: #0c0005;
        }

        &.notValid {
          border: 1px solid #ff0101;
        }
      }
    }

    span {
      font-weight: 400;
      color: #706d71;
      font-size: var(--fs-3);
      line-height: 120%;

      @media screen and (max-width: 1500px) {
        font-size: var(--fs-5);
      }
    }
  }
`;
