import React, { useEffect, useMemo, useState, CSSProperties, useRef, useCallback, FC } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { DatePickerStyles } from './styles';
import { toDate, zonedTimeToUtc } from 'date-fns-tz';
import { addDay, getStartDate, periods, setMidnightTime } from './utils';
import { useAppSelector } from '../../../state';
import { getTranslationByLangOrEng } from '../../../i18n';
import { ArrowDownIcon } from '../../../assets';
import { useClickOutside } from '../../../hooks';
import { format } from 'date-fns';
type TDatePicker = {
  setReQuery: () => void;
  setFilterParams: (params: {
    [key: string]: number | string | string[] | number[] | any;
  }) => void;
  filterParams: {
    [key: string]: number | string | any;
  };
};
export const DatePicker: FC<TDatePicker> = React.memo(({
  setReQuery,
  filterParams,
  setFilterParams
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [dateFrom, setDateFrom] = useState<Date>(new Date('2022-12-30T22:00:00.000Z'));
  const [dateTo, setDateTo] = useState<Date>(new Date());
  const [open, setOpen] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const filterFrom = filterParams.from && filterParams.from.includes('%') ? toDate(filterParams.from) : filterParams.from && !filterParams.from.includes('%') ? filterParams.from : undefined;
  const filterTo = filterParams.to && filterParams.to.includes('%') ? toDate(filterParams.to) : filterParams.to && !filterParams.to.includes('%') ? filterParams.to : undefined;
  const initialFromDate: Date = useMemo(() => filterFrom ? new Date(filterFrom as number | string | Date) : getStartDate(), []);
  const initialToDate: Date = useMemo(() => filterTo ? new Date(filterTo as number | string) : setMidnightTime(new Date()), []);
  const handleDateFrom = (date: Date) => {
    if (date.toISOString() >= dateTo!.toISOString()) {
      setDateTo(date);
    }
    setDateFrom(date);
  };
  const handleSubmit = () => {
    const dateQuery: {
      [key: string]: string;
    } = {
      ...filterParams
    };
    const dateFromWithMidnightTime = setMidnightTime(dateFrom!);
    const dateToWithMidnightTime = setMidnightTime(dateTo!);
    if (dateFromWithMidnightTime.toISOString() !== initialFromDate!.toISOString() || !filterParams.from) {
      dateQuery.from = zonedTimeToUtc(new Date(dateFromWithMidnightTime!), timezone).toISOString();
    }
    if (dateToWithMidnightTime.toISOString() !== initialToDate!.toISOString() || !filterParams.to) {
      dateQuery.to = zonedTimeToUtc(new Date(addDay(dateToWithMidnightTime!, 1)), timezone).toISOString();
    }
    if (Object.keys(dateQuery).length) {
      setDateFrom(new Date(dateFromWithMidnightTime!));
      setDateTo(new Date(dateToWithMidnightTime));
      setFilterParams(dateQuery);
      setReQuery();
    }
    setOpen(false);
  };
  const handleReset = () => {
    const currentFilters = {
      ...filterParams
    };
    currentFilters.from = new Date('2022-12-30T22:00:00.000Z').toISOString();
    currentFilters.to = new Date().toISOString();
    setFilterParams(currentFilters);
    setReQuery();
    setOpen(false);
    setDateFrom(new Date('2022-12-30T22:00:00.000Z'));
    setDateTo(new Date());
  };
  const comparedTime = useCallback((period: string): boolean => {
    const comparedDate: Date = new Date();
    switch (period) {
      case 'quarter':
        comparedDate.setMonth(comparedDate.getMonth() - 3);
        break;
      case 'months':
        comparedDate.setMonth(comparedDate.getMonth() - 1);
        break;
      case 'days7':
        comparedDate.setDate(comparedDate.getDate() - 7);
        break;
      default:
        break;
    }
    const dateFromWithMidnightTime = setMidnightTime(comparedDate);
    const dateToWithMidnightTime = setMidnightTime(new Date());
    if (dateFrom && dateTo) {
      return dateFromWithMidnightTime.toString() === dateFrom.toString() && dateToWithMidnightTime.toString() === dateTo.toString();
    }
    return false;
  }, [dateFrom, dateTo]);
  const handleSetTemplatePeriod = (period: string) => {
    const dateQuery: {
      [key: string]: string;
    } = {
      ...filterParams
    };
    const templateDateFrom: Date = new Date();
    switch (period) {
      case 'quarter':
        templateDateFrom.setMonth(templateDateFrom.getMonth() - 3);
        break;
      case 'months':
        templateDateFrom.setMonth(templateDateFrom.getMonth() - 1);
        break;
      case 'days7':
        templateDateFrom.setDate(templateDateFrom.getDate() - 7);
        break;
      default:
        break;
    }
    const dateFromWithMidnightTime = setMidnightTime(templateDateFrom);
    const dateToWithMidnightTime = setMidnightTime(new Date());
    dateQuery.from = zonedTimeToUtc(new Date(dateFromWithMidnightTime), timezone).toISOString();
    dateQuery.to = zonedTimeToUtc(new Date(addDay(dateToWithMidnightTime, 1)), timezone).toISOString();
    if (Object.keys(dateQuery).length) {
      setDateFrom(new Date(dateFromWithMidnightTime!));
      setDateTo(new Date(dateToWithMidnightTime));
      setFilterParams(dateQuery);
      setReQuery();
    }
    setOpen(false);
  };
  useEffect(() => {
    setDateFrom(initialFromDate);
    setDateTo(initialToDate);
    const currentFilters = {
      ...filterParams
    };
    currentFilters.from = initialFromDate.toISOString();
    currentFilters.to = addDay(initialToDate, 1).toISOString();
    setFilterParams(currentFilters);
    setReQuery();
  }, []);
  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        submitButtonRef.current?.click();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);
  return <DatePickerStyles>
        <div className="selectedValue" onClick={() => setOpen(!open)} ref={selectedValueFieldRef}
    // aria-label={placeholder}
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    tabIndex={0} onKeyDown={e => {
      if (e.key === 'Enter') {
        setOpen(!open);
      }
    }}>
          <span className="value">{format(dateFrom, 'dd.MM.yyyy')} - {format(dateTo, 'dd.MM.yyyy')}</span>
          <div className={open ? 'arrowDownActive' : ''}>
            <ArrowDownIcon />
          </div>
        </div>
        {open && <div className="dropdown" ref={optionsListRef}>
            <div className="calendars">
              <div className="calendar from">
                <Calendar onChange={handleDateFrom} value={dateFrom} minDetail="year" minDate={new Date(2022, 1, 1)} maxDate={new Date()} locale="UK" tileClassName={({
            date,
            view
          }) => {
            if (view === 'month') {
              if (dateFrom && dateTo && date >= dateFrom && date <= dateTo) {
                return 'active';
              }
            }
            return null;
          }} />
              </div>
              <div className="calendar to">
                <Calendar onChange={setDateTo} value={dateTo} minDetail="year" minDate={dateFrom!} maxDate={new Date()} locale="UK" tileClassName={({
            date,
            view
          }) => {
            if (view === 'month') {
              if (dateFrom && dateTo && date >= dateFrom && date <= dateTo) {
                return 'active';
              }
            }
            return null;
          }} />
              </div>
            </div>

            <div className="templates">
              {periods.map(period => <button disabled={comparedTime(period)} key={period} type="button" onClick={() => handleSetTemplatePeriod(period)}>
                  {getTranslationByLangOrEng(interfaceLanguage, period)}
                </button>)}
            </div>

            <div className="buttons">
              <button className="resetButton" type="button" onClick={() => handleReset()}>
                {getTranslationByLangOrEng(interfaceLanguage, 'reset_reviews_filter')}
              </button>
              <button className="submitButton" type="button" onClick={() => handleSubmit()} ref={submitButtonRef}>
                {getTranslationByLangOrEng(interfaceLanguage, 'apply_filters_button')}
              </button>
            </div>
          </div>}
      </DatePickerStyles>;
});