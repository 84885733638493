/* eslint-disable class-methods-use-this */
import baseApi from '../baseApi';
import {
  ApiEmailCampaigns,
  ApiMassMailing,
  ApiMassSmsMailingResponse,
  ApiResponse,
  ApiSmsCampaigns,
  ApiSmsHistoryItem,
  ApiViberHistoryItem,
} from '../entities';
import IMassMailingApi from './IMassMailing';

class MassMailingApi implements IMassMailingApi {
  public async createFilteredMassSmsMailing(data: {
    companyID: number;
    filter: {};
    formID: number;
    name: string;
    templateID: number;
  }): Promise<ApiResponse<ApiMassSmsMailingResponse>> {
    return baseApi.postData('/sms/campaign/mailing-list', data);
  }

  public async createMassSmsMailing(
    data: ApiMassMailing,
  ): Promise<ApiResponse<ApiMassSmsMailingResponse>> {
    return baseApi.postData('/sms/campaign', data);
  }

  public async createMassViberMailing(
    data: ApiMassMailing,
  ): Promise<ApiResponse<{ message: string }>> {
    return baseApi.postData('/viber/campaign', data);
  }

  public async createMassEmailMailing(
    data: ApiMassMailing,
  ): Promise<ApiResponse<{ message: string }>> {
    return baseApi.postData('/email/campaign', data);
  }

  public async massMailingViberHistory(
    companyID: number,
  ): Promise<ApiResponse<ApiViberHistoryItem[]>> {
    return baseApi.getData(`/viber-history?companyID=${companyID}`);
  }

  public async massMailingSmsHistory(
    companyID: number,
  ): Promise<ApiResponse<ApiSmsHistoryItem[]>> {
    const res = await baseApi.getData(`/sms-history?companyID=${companyID}`);

    return Promise.resolve({
      data: res.data.data,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async massMailingSmsCampaignList(
    companyID: number,
    offset?: number,
    limit?: number,
    from?: string,
    to?: string,
  ): Promise<ApiResponse<ApiSmsCampaigns[]>> {
    const res = await baseApi.getData(
      `/sms-campaigns?companyID=${companyID}&offset=${offset || 0}&limit=${
        limit || 10
      }${from ? `&from=${from}` : ''}${to ? `&to=${to}` : ''}`,
    );

    return Promise.resolve({
      data: res.data.data,
      statusCode: res.statusCode,
      count: res.data.count,
      stats: res.data.stats,
    });
  }

  public async massMailingViberCampaignList(
    companyID: number,
    offset?: number,
    limit?: number,
    from?: string,
    to?: string,
  ): Promise<ApiResponse<ApiSmsCampaigns[]>> {
    const res = await baseApi.getData(
      `/viber-campaigns?companyID=${companyID}&offset=${offset || 0}&limit=${
        limit || 10
      }${from ? `&from=${from}` : ''}${to ? `&to=${to}` : ''}`,
    );

    return Promise.resolve({
      data: res.data.data,
      statusCode: res.statusCode,
      count: res.data.count,
      stats: res.data.stats,
    });
  }

  public async massMailingEmailCampaignList(
    companyID: number,
  ): Promise<ApiResponse<{ campaigns: ApiEmailCampaigns[] }>> {
    return baseApi.getData(`/email-campaigns?companyID=${companyID}`);
  }

  public async massMailingSmsCampaignItems(
    id: number,
    offset?: number,
    limit?: number,
  ): Promise<ApiResponse<ApiSmsHistoryItem[]>> {
    const res = await baseApi.getData(
      `/sms-campaigns/${id}/items?offset=${offset || 0}&limit=${limit || 10}`,
    );

    return Promise.resolve({
      data: res.data.data,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async massMailingViberCampaignItems(
    id: number,
    offset?: number,
    limit?: number,
  ): Promise<ApiResponse<ApiSmsHistoryItem[]>> {
    const res = await baseApi.getData(
      `/viber-campaigns/${id}/items?offset=${offset || 0}&limit=${limit || 10}`,
    );

    return Promise.resolve({
      data: res.data.data,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async deleteMassSmsMailingCampaign(
    id: number,
  ): Promise<ApiResponse<any>> {
    return baseApi.deleteData(`/sms-campaigns/${id}`);
  }

  public async massMailingViberCampaignStats(
    id: number,
  ): Promise<ApiResponse<ApiSmsCampaigns>> {
    return baseApi.getData(`/viber-campaigns/${id}`);
  }

  public async massMailingSmsCampaignStats(
    id: number,
  ): Promise<ApiResponse<ApiSmsCampaigns>> {
    return baseApi.getData(`/sms-campaigns/${id}`);
  }
}

export const massMailingApi = new MassMailingApi();
