import styled from 'styled-components';

export const ReviewsTableStyles = styled.div<{
  tableVersion: boolean;
  mobileVersion: boolean;
}>`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px 12px;
  overflow: hidden;

  @media screen and (max-width: 1500px) {
    padding: 8px;
    gap: 2px;
  }

  .reviews {
    width: 100%;

    &-subheader {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
    }

    &-utils {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }

    &-btns {
      display: flex;
      align-items: center;
      gap: 12px;
      position: relative;

      @media screen and (max-width: 1500px) {
        gap: 8px;
      }
    }

    &-extrabtn {
      height: 44px;

      @media screen and (max-width: 1500px) {
        height: 36px;
      }
    }

    &-btn {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      border: 2px solid #000000;
      border-radius: 1000px;

      @media screen and (max-width: 1500px) {
        width: 36px;
        height: 36px;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      &:hover,
      &:focus {
        background: #000;

        path,
        line,
        rect {
          fill: #fff;
          stroke: #fff;
        }
      }
    }
  }

  .connectionChannelContainer {
    max-width: 150px;
    width: 100%;
    display: flex;
    gap: 16px;

    .networkDataContainer {
      display: flex;
      align-items: center;

      span {
        font-size: var(--fs-7);
      }

      svg {
        flex-shrink: 0;

        @media screen and (max-width: 768px) {
          width: 20px;
        }
      }

      .botIsNotConnected {
        font-size: var(--fs-5);
      }
    }
  }

  .statusContainer {
    display: flex;
    gap: 8px;
    text-align: center;
    align-items: center;
    font-size: var(--fs-5);
    color: #000;
  }

  .sourceContainer {
    text-align: start;
    font-size: var(--fs-5);
  }

  .answersContainer {
    .checkboxesList {
      list-style: none;
      display: grid;
      gap: 8px;
      color: #000000;
    }

    .answersExtraQuestionField {
      width: 200px;
    }

    .scaleContainer {
      font-weight: 700;
      font-size: var(--fs-5);
      color: #000000;
      display: grid;
      grid-template-columns: auto auto;
      align-items: flex-start;
      gap: 4px;
    }
  }

  .cellTextContainer {
    /* background-color: red; */
    font-weight: 400;
    font-size: var(--fs-5);
    line-height: 130%;
    color: #000000;
  }
  .headerTextContainer {
    /* background-color: red; */
    font-weight: 400;
    font-size: var(--fs-4);
    line-height: 130%;
    color: #000000;
  }

  .nameContainer {
    svg {
      position: absolute;
      top: 0;
      right: 0;
      height: 12px;
    }

    h3 {
      font-weight: 500;
      font-size: var(--fs-3);
      line-height: 120%;
      color: #666666;
      margin-bottom: 8px;
    }

    span {
      font-weight: 400;
      font-size: var(--fs-5);
      line-height: 130%;
      color: #000000;
    }
  }

  .tableSettings {
    position: relative;
  }

  .chooseCompanyTitleContainer {
    position: relative;
    height: 50vh;

    .chooseCompanyTitle {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: var(--lg-fs-1);
      font-weight: 700;
    }
  }

  .commentContainer {
    display: flex;
    justify-content: center;

    .commentField {
      width: 180px;
      text-align: center;
      word-wrap: break-word;
      font-size: var(--fs-5);
    }
  }

  .extraQuestionFieldAnswerContainer {
    display: flex;
    justify-content: flex-start;

    .extraQuestionFieldAnswer {
      font-size: var(--fs-5);
      text-align: start;
      word-wrap: break-word;
    }
  }

  .categoryContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 200px;
    flex-wrap: wrap;
    position: relative;

    .categoryName {
      background-color: #f5f5f5;
      padding: 5px 8px;
      margin: 5px 5px;
      font-size: var(--fs-5);
      border-radius: 9px;
      text-align: center;
      word-wrap: break-word;
      border: 1px solid #ccc;
      position: relative;
      z-index: 5;

      .details {
        z-index: 10;
        top: 100%;
        left: 100%;
        position: absolute;
        background-color: #f5f5f5;
        font-size: var(--fs-5);
        border-radius: 9px;
        text-align: center;
        word-wrap: break-word;
        border: 1px solid #ccc;
        width: 150px;
        border-top-left-radius: 0px;

        p:last-child {
          border-bottom: none;
        }
        .detail {
          text-align: start;
          padding: 5px 10px;
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }

  .extraFilterFormStyles {
    height: 100%;
  }

  .extraFilterStyles {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .optionsExtraStyles {
    border: none !important;
    border-radius: 0 !important;
    overflow-y: auto !important;
    max-height: none !important;
  }

  .chip-container {
    padding: 5px 10px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .attachmentContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    .attachmentItem {
      display: flex;
      align-items: center;
      gap: 5px;
      color: rgb(101, 101, 101);
      font-size: var(--fs-3);
    }
  }
`;

export const SearchContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: var(--sm-7) var(--sm-7) var(--sm-7) var(--sm-4);
  gap: var(--sm-5);
  background: #eef3f7;
  border: 2px solid #ffffff;
  border-radius: 999px;
  width: 50%;
  position: relative;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    width: 100%;
    height: 100%;
    min-height: 20px;
    flex: 1;
    padding: 0px;
    border: none;
    outline: none;
    background-color: #eef3f7;
    border-radius: 5px;
    padding-left: 10px;

    @media screen and (max-width: 768px) {
      margin-left: 0px;
    }
  }

  input::placeholder {
    font-size: var(--fs-3);
    color: #666666;
  }
`;

export const DropDownContainer = styled.div`
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 150px;
  background-color: #eef3f7;
  border-radius: 5px;
`;

export const StyledSelect = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
