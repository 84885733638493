/* eslint-disable class-methods-use-this */
import { filter } from 'lodash';
import baseApi from '../baseApi';
import { ApiResponse } from '../entities';
import IExportApi from './IExportApi';

class ExportApi implements IExportApi {
  public async exportNodes(companyID: number): Promise<ApiResponse<any>> {
    const { data, statusCode } = await baseApi.getData(
      `/exports/nodes/${companyID}`,
      true,
    );
    return {
      data,
      statusCode,
    };
  }

  public async exportUsers(): Promise<ApiResponse<any>> {
    return baseApi.getData('/exports/users', true);
  }

  public async exportForm(id: number): Promise<
    ApiResponse<{
      features: any[];
      name: string;
      type: string;
    }>
  > {
    return baseApi.getData(`/exports/forms/${id}/nodes`);
  }

  exportReviews(data: {
    companyID?: number;
    forms?: number[];
    nodes?: number[];
    statuses?: number[];
    sources?: number[];
    from?: number;
    to?: number;
    search?: { [key: string]: string };
    contacts?: string[];
  }): Promise<ApiResponse<any>> {
    return baseApi.postData('/exports/forms/reviews', data, true);
  }

  public async exportQrPublishedNodes({
    id,
    filters,
  }: {
    id: number;
    filters?: {};
  }) {
    const response = await baseApi.postData(
      `/exports/${id}/qr-confirmation-nodes`,
      filters,
      true,
    );

    // Get filename from Content-Disposition header
    const contentDisposition = response.headers['content-disposition'];

    let filename = 'download.xlsx'; // default filename

    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(
        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
      );
      if (filenameMatch && filenameMatch[1]) {
        filename = filenameMatch[1].replace(/['"]/g, '');
      }
    }
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(response.data);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  public async downloadStructure(companyID: number): Promise<ApiResponse<any>> {
    return baseApi.postData(`/exports/${companyID}/structure`, null, true);
  }
}

export const exportApi = new ExportApi();
