import React, { useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { LayoutStyles } from './styles';
import { useWindowSize } from 'usehooks-ts';
import { setCompanies, setFontScale, setSidebarFullWidth, useAppDispatch, useAppSelector } from '../../../state';
import { companyApi } from '../../../api';
import { Header } from '../header';
import { Footer } from '../footer';
import { Sidebar } from '../../molecules';
import { RegistrationStatus } from '../../../constants/registrationStatuses';
export const Layout: React.FC = React.memo(() => {
  const dispatch = useAppDispatch();
  const {
    pathname
  } = useLocation();
  const {
    grayMode,
    fontScale
  } = useAppSelector(state => state.interface);
  const {
    width
  } = useWindowSize();
  const {
    data
  } = useAppSelector(state => state.user);
  const loggedIn = data?.status === RegistrationStatus.assigned;
  useEffect(() => {
    if (loggedIn) {
      companyApi.getCompanies().then(res => {
        const companies = res.data.map(el => el.id);
        dispatch(setCompanies(companies));
      });
    }
  }, [loggedIn]);
  useEffect(() => {
    if (pathname.startsWith('/analytic') || pathname.includes('/reviews')) {
      dispatch(setSidebarFullWidth(false));
    } else {
      dispatch(setSidebarFullWidth(width > 991));
    }
  }, [width]);
  useEffect(() => {
    // if (pathname.startsWith('/public-dashboards')) {
    //   dispatch(setFontScale(2));
    // } else {
    //   dispatch(setFontScale(0));
    // }
    dispatch(setFontScale(0));
  }, []);
  useEffect(() => {
    // document.documentElement.style.fontSize = `clamp(0.65rem, 0.4rem + ${fontScale}vw, 1.05rem)`;
    document.documentElement.style.fontSize = `${14 + fontScale}px`;
    return () => {
      document.documentElement.style.fontSize = '';
    };
  }, [fontScale]);
  return <LayoutStyles grayMode={grayMode} fontScale={fontScale} style={pathname.startsWith('/public-dashboards') ? {
    padding: '0'
  } : {}}>
      {!pathname.startsWith('/public-dashboards') && <Header />}
      <main>
        <div className="mainBody" style={pathname.startsWith('/auth') || pathname.startsWith('/public-dashboards') ? {
        gridTemplateColumns: '1fr'
      } : {}}>
          {loggedIn && <Sidebar />}
          <div className="contentWrapper" style={pathname.startsWith('/public-dashboards') ? {
          maxHeight: '100vh'
        } : {}}>
            <Outlet />
          </div>
        </div>
      </main>
      {!loggedIn && !pathname.startsWith('/public-dashboards') && <Footer />}
    </LayoutStyles>;
});