import React, { FC, useEffect, useRef, useState } from 'react';
import { FormPickerStyles } from './styles';
import { Modal } from '../Modal';
import { ApiConstructorForm, constructorApi } from '../../../../api';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '../../../atoms';
type TFormPicker = {
  onClose: () => void;
  companyID: number;
  handleSelect: (form: ApiConstructorForm) => void;
};
export const FormPicker: FC<TFormPicker> = ({
  onClose,
  companyID,
  handleSelect
}) => {
  const [feedbackForms, setFeedbackForms] = useState<ApiConstructorForm[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const formsCount = useRef<number>(0);
  useEffect(() => {
    setLoading(true);
    constructorApi.getCompanyForms(companyID, (pageNumber ? +pageNumber - 1 : 0) * 10, 10).then(res => {
      setFeedbackForms(prev => [...prev, ...res.data]);
      formsCount.current = res.count ? res.count : 0;
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    });
  }, [pageNumber]);
  return <FormPickerStyles>
      <Modal title="Оберіть форму яку хочете додати" onClose={onClose}>
        <div className="forms">
          <InfiniteScroll dataLength={feedbackForms.length} next={() => {
          setPageNumber(prev => prev + 1);
        }} loader={loading ? <Loader margin={10} /> : null} hasMore={pageNumber * 10 < formsCount.current} scrollableTarget="scrollableContainer">
            <div className="forms-list">
              {feedbackForms.map((el, index) => <div key={el.id} className="forms-item" onClick={() => {
              onClose();
              handleSelect(el);
            }}>
                  {`${index + 1}. ${el.name}`}
                </div>)}
            </div>
          </InfiniteScroll>
        </div>
      </Modal>
    </FormPickerStyles>;
};