import React, { Dispatch, FC, SetStateAction, useEffect, useState, RefObject } from 'react';
import { YearMonthsStyles } from './styles';
import { ArrowDownIcon, CheckMark } from '../../../assets';
import { format } from 'date-fns';
type SelectedMonths = {
  [key: number]: number[];
};
type TTimeInterval = {
  from: {
    year: number;
    month: number;
  };
  to: {
    year: number;
    month: number;
  };
};
type TYearMonths = {
  filterParams: RefObject<{
    [key: string]: number | string | string[] | number[] | boolean;
  }>;
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
};
const monthNames: string[] = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];
export const YearMonths: FC<TYearMonths> = ({
  filterParams,
  setReQuery,
  reQuery
}) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const years: number[] = Array.from({
    length: currentYear - 2021 + 1
  }, (_, i) => 2021 + i);
  const months: number[] = Array.from({
    length: 12
  }, (_, i) => i + 1);
  const [selectedMonths, setSelectedMonths] = useState<SelectedMonths>({});
  const [selectedInterval, setSelectedInterval] = useState<TTimeInterval>({
    from: {
      year: currentYear,
      month: 1
    },
    to: {
      year: currentYear,
      month: currentMonth
    }
  });
  const [expandedYears, setExpandedYears] = useState<number[]>([]);
  const toggleMonth = (year: number, month: number) => {
    if (year < selectedInterval.from.year || year === selectedInterval.from.year && month < selectedInterval.from.month) {
      setSelectedInterval(prev => ({
        from: {
          year,
          month
        },
        to: prev.to
      }));
    } else if (year > selectedInterval.to.year || year === selectedInterval.to.year && month > selectedInterval.to.month) {
      setSelectedInterval(prev => ({
        from: prev.from,
        to: {
          year,
          month
        }
      }));
    } else {
      setSelectedInterval({
        from: {
          year,
          month
        },
        to: {
          year,
          month
        }
      });
    }
  };
  const expandYear = (year: number) => {
    setExpandedYears(prev => prev.includes(year) ? prev.filter(y => y !== year) : [...prev, year]);
  };
  const monthToString = (year: number, month: number, isStart: boolean): string => format(new Date(year, isStart ? month - 1 : month, +isStart), 'dd-MM-yyyy');
  const intervalToMonths = (interval: TTimeInterval): SelectedMonths => {
    const res: SelectedMonths = {};
    for (let i = interval.from.year; i <= interval.to.year; i++) {
      const startMonth = i === interval.from.year ? interval.from.month : 1;
      const endMonth = i === interval.to.year ? interval.to.month : 12;
      res[i] = [];
      for (let j = startMonth; j <= endMonth; j++) {
        res[i].push(j);
      }
    }
    return res;
  };
  const toggleYear = (year: number) => {
    if (year < selectedInterval.from.year) {
      setSelectedInterval(prev => ({
        from: {
          year,
          month: 1
        },
        to: prev.to
      }));
    } else if (year > selectedInterval.to.year) {
      setSelectedInterval(prev => ({
        from: prev.from,
        to: {
          year,
          month: 12
        }
      }));
    } else {
      setSelectedInterval(prev => ({
        from: {
          year,
          month: 1
        },
        to: {
          year,
          month: 12
        }
      }));
    }
  };
  const isYearSelected = (year: number) => (selectedMonths[year] || []).length > 0;
  const renderMonths = (year: number) => <div className="months">
      {months.map((month, index) => {
      const isCurrentYear = year === currentYear;
      const shouldShowMonth = !isCurrentYear || month <= new Date().getMonth() + 1;
      return shouldShowMonth ? <div key={month} className="month">
            <div role="button" className="month-checkmark" onClick={() => toggleMonth(year, month)}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0} onKeyDown={e => {
          if (e.key === 'Enter') {
            toggleMonth(year, month);
          }
        }} aria-label={monthNames[month - 1]}>
              {selectedMonths[year]?.includes(month) ? <CheckMark /> : null}
            </div>
            <p>{monthNames[month - 1]}</p>
          </div> : null;
    })}
    </div>;
  useEffect(() => {
    if (!filterParams.current?.from || !filterParams?.current.to) {
      setSelectedMonths({});
    }
  }, [reQuery]);
  useEffect(() => {
    setSelectedMonths(intervalToMonths(selectedInterval));
    if (filterParams.current) {
      filterParams.current.from = monthToString(selectedInterval.from.year, selectedInterval.from.month, true);
      filterParams.current.to = monthToString(selectedInterval.to.year, selectedInterval.to.month, false);
    }
    setReQuery(!reQuery);
  }, [selectedInterval]);
  return <YearMonthsStyles>
      <h4>Рік & Місяць</h4>
      <div className="list">
        {years.map((year, index) => <div key={year} className="year">
            <div className="year-label">
              <div role="button" className={`year-arrow ${expandedYears.includes(year) ? 'year-active' : ''}`} onClick={() => expandYear(year)}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0} onKeyDown={e => {
            if (e.key === 'Enter') {
              expandYear(year);
            }
          }} aria-label={`${year}`}>
                <ArrowDownIcon />
              </div>
              <div role="button" className="year-checkmark" onClick={() => toggleYear(year)}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0} onKeyDown={e => {
            if (e.key === 'Enter') {
              toggleYear(year);
            }
          }}>
                {isYearSelected(year) ? <CheckMark /> : null}
              </div>
              {year}
            </div>
            {expandedYears.includes(year) && renderMonths(year)}
          </div>)}
      </div>
    </YearMonthsStyles>;
};