import React, { useEffect, useMemo, useState, CSSProperties, useRef, useCallback, FC } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { CalendarFilterStyles } from './styles';
import { getTranslationByLangOrEng } from '../../../../i18n';
import { useAppSelector } from '../../../../state';
import { toDate, zonedTimeToUtc } from 'date-fns-tz';
import { addDay, getStartDate, periods, setMidnightTime } from './utils';
type TProps = {
  setReQuery: () => void;
  setFilterParams: (params: {
    [key: string]: number | string | string[] | number[] | any;
  }) => void;
  filterParams: {
    [key: string]: number | string | any;
  };
  onClose: () => void;
  extraBlockStyles?: CSSProperties;
  setApply?: (params: {
    [key: string]: number | string | any;
  }) => void;
  columnName?: string;
};
export const CalendarFilter: FC<TProps> = React.memo(({
  setReQuery,
  onClose,
  filterParams,
  setFilterParams,
  extraBlockStyles,
  setApply,
  columnName
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  let fromKey = 'from';
  let toKey = 'to';
  if (columnName) {
    toKey = `${columnName}To`;
    fromKey = `${columnName}From`;
  }
  const [dateFrom, setDateFrom] = useState<Date>(new Date('2024-12-30T22:00:00.000Z'));
  const [dateTo, setDateTo] = useState<Date>(new Date());
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const filterFrom = filterParams[fromKey] && filterParams[fromKey].includes('%') ? toDate(filterParams[fromKey]) : filterParams[fromKey] && !filterParams[fromKey].includes('%') ? filterParams[fromKey] : undefined;
  const filterTo = filterParams[toKey] && filterParams[toKey].includes('%') ? toDate(filterParams[toKey]) : filterParams[toKey] && !filterParams[toKey].includes('%') ? filterParams[toKey] : undefined;
  const initialFromDate: Date = useMemo(() => filterFrom ? new Date(filterFrom as number | string | Date) : getStartDate(), []);
  const initialToDate: Date = useMemo(() => filterTo ? new Date(filterTo as number | string) : setMidnightTime(new Date()), []);
  const handleDateFrom = (date: Date) => {
    if (date.toISOString() >= dateTo!.toISOString()) {
      setDateTo(date);
    }
    setDateFrom(date);
  };
  const handleSubmit = () => {
    const dateQuery: {
      [key: string]: string;
    } = {};
    const dateFromWithMidnightTime = setMidnightTime(dateFrom!);
    const dateToWithMidnightTime = setMidnightTime(dateTo!);
    if (dateFromWithMidnightTime.toISOString() !== initialFromDate!.toISOString() || !filterParams[fromKey]) {
      dateQuery[fromKey] = zonedTimeToUtc(new Date(dateFromWithMidnightTime!), timezone).toISOString();
    }
    if (dateToWithMidnightTime.toISOString() !== initialToDate!.toISOString() || !filterParams[toKey]) {
      dateQuery[toKey] = zonedTimeToUtc(new Date(addDay(dateToWithMidnightTime!, 1)), timezone).toISOString();
    }
    if (Object.keys(dateQuery).length) {
      setFilterParams({
        ...filterParams,
        ...dateQuery
      });
      if (setApply) {
        setApply({
          ...filterParams,
          ...dateQuery
        });
      }
      setReQuery();
    }
    onClose();
  };
  const handleReset = () => {
    const currentFilters = {
      ...filterParams
    };
    delete currentFilters[fromKey];
    delete currentFilters[toKey];
    setFilterParams(currentFilters);
    if (setApply) {
      setApply(currentFilters);
    }
    setReQuery();
    onClose();
  };
  const comparedTime = useCallback((period: string): boolean => {
    const comparedDate: Date = new Date();
    switch (period) {
      case 'quarter':
        comparedDate.setMonth(comparedDate.getMonth() - 3);
        break;
      case 'months':
        comparedDate.setMonth(comparedDate.getMonth() - 1);
        break;
      case 'days7':
        comparedDate.setDate(comparedDate.getDate() - 7);
        break;
      default:
        break;
    }
    const dateFromWithMidnightTime = setMidnightTime(comparedDate);
    const dateToWithMidnightTime = addDay(setMidnightTime(new Date()), 1);
    if (dateFrom && dateTo) {
      return dateFromWithMidnightTime.toString() === dateFrom.toString() && dateToWithMidnightTime.toString() === dateTo.toString();
    }
    return false;
  }, [dateFrom, dateTo]);
  const handleSetTemplatePeriod = useCallback((period: string) => {
    const dateQuery: {
      [key: string]: string;
    } = {};
    const templateDateFrom: Date = new Date();
    switch (period) {
      case 'quarter':
        templateDateFrom.setMonth(templateDateFrom.getMonth() - 3);
        break;
      case 'months':
        templateDateFrom.setMonth(templateDateFrom.getMonth() - 1);
        break;
      case 'days7':
        templateDateFrom.setDate(templateDateFrom.getDate() - 7);
        break;
      default:
        break;
    }
    const dateFromWithMidnightTime = setMidnightTime(templateDateFrom);
    const dateToWithMidnightTime = setMidnightTime(new Date());
    dateQuery[fromKey] = zonedTimeToUtc(new Date(dateFromWithMidnightTime), timezone).toISOString();
    dateQuery[toKey] = zonedTimeToUtc(new Date(addDay(dateToWithMidnightTime, 1)), timezone).toISOString();
    if (Object.keys(dateQuery).length) {
      setFilterParams({
        ...filterParams,
        ...dateQuery
      });
      if (setApply) {
        setApply({
          ...filterParams,
          ...dateQuery
        });
      }
      setReQuery();
    }
    onClose();
  }, []);
  useEffect(() => {
    setDateFrom(initialFromDate);
    setDateTo(initialToDate);
  }, []);
  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        submitButtonRef.current?.click();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  return <CalendarFilterStyles style={extraBlockStyles}>
        <div className="calendars">
          <div className="calendar from">
            <Calendar onChange={handleDateFrom} value={dateFrom} minDetail="year" minDate={new Date(2022, 1, 1)} maxDate={new Date()} locale={interfaceLanguage.toLowerCase()} tileClassName={({
          date,
          view
        }) => {
          if (view === 'month') {
            if (dateFrom && dateTo && date >= dateFrom && date <= dateTo) {
              return 'active';
            }
          }
          return null;
        }} />
          </div>
          <div className="calendar to">
            <Calendar onChange={setDateTo} value={dateTo} minDetail="year" minDate={dateFrom!} maxDate={new Date()} locale={interfaceLanguage.toLowerCase()} tileClassName={({
          date,
          view
        }) => {
          if (view === 'month') {
            if (dateFrom && dateTo && date >= dateFrom && date <= dateTo) {
              return 'active';
            }
          }
          return null;
        }} />
          </div>
        </div>

        <div className="templates">
          {periods.map(period => <button disabled={comparedTime(period)} key={period} type="button" onClick={() => handleSetTemplatePeriod(period)}>
              {getTranslationByLangOrEng(interfaceLanguage, period)}
            </button>)}
        </div>

        <div className="buttons">
          <button className="resetButton" type="button" onClick={() => handleReset()}>
            {getTranslationByLangOrEng(interfaceLanguage, 'reset_reviews_filter')}
          </button>
          <button className="submitButton" type="button" onClick={() => handleSubmit()} ref={submitButtonRef}>
            {getTranslationByLangOrEng(interfaceLanguage, 'apply_filters_button')}
          </button>
        </div>
      </CalendarFilterStyles>;
});