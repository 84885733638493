import styled from 'styled-components';

export const AnalyticsStartPageStyles = styled.div`
  flex: 1;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  h2 {
    width: 80%;
    text-align: center;
    font-size: var(--lg-fs-1);
  }

  .links {
    /* flex: 1; */
    overflow: auto;
    box-sizing: border-box;
    display: flex;

    &.wrap {
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: wrap;
      gap: 16px;
    }

    &.list {
      flex: 1;
      width: 100%;
      justify-content: flex-start;
      flex-direction: column;
      gap: 2px;
    }

    &-item {
      cursor: pointer;
      background: #ffffff;
      padding: 15px 5px;
      position: relative;
      transition: all 0.3s ease-in-out;

      &.wrap {
        padding: 24px;
        padding-left: 48px;
        width: calc(20% - 14px);
        min-height: 120px;
        border-radius: 30px;
        position: relative;

        @media screen and (max-width: 1500px) {
          width: calc(25% - 14px);
          min-height: 100px;
        }

        @media screen and (max-width: 1200px) {
          width: calc(33% - 14px);
        }

        span {
          font-weight: 400;
          font-size: var(--fs-3);

          @media screen and (max-width: 1500px) {
            font-size: var(--fs-4);
          }
          line-height: 115%;
          color: #000000;
        }

        &::before {
          position: absolute;
          content: '';
          left: 24px;
          top: 24px;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: #6eae93;
        }
      }

      &.list {
        box-sizing: border-box;
        padding: 24px;
        background: #ffffff;
        padding-left: 48px;

        span {
          font-weight: 400;
          font-size: var(--fs-3);

          @media screen and (max-width: 1500px) {
            font-size: var(--fs-4);
          }

          line-height: 115%;
          color: #000000;
        }

        &::before {
          position: absolute;
          content: '';
          left: 24px;
          top: 24px;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: #6eae93;
        }
      }

      &:hover {
        background: #fff4d7;
      }

      span {
        font-size: var(--fs-3);

        @media screen and (max-width: 1500px) {
          font-size: var(--fs-4);
        }
      }
    }
  }
`;
