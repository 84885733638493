import React, { FC } from 'react';
import { TIcon } from './TIcon';
export const OpenedMassMailings: FC<TIcon> = React.memo(({
  color = '#0C0005',
  width = 36,
  height = 36
}) => <svg width={width} height={height} viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="9.66675" y="7" width="18" height="22" rx="1" stroke={color} strokeWidth="2" />
    <path d="M14.7989 9.9563L15.3825 11.1388L16.6875 11.3284L15.7432 12.2488L15.9661 13.5485L14.7989 12.9349L13.6318 13.5485L13.8547 12.2488L12.9104 11.3284L14.2154 11.1388L14.7989 9.9563Z" fill={color} />
    <path d="M18.7701 9.9563L19.3537 11.1388L20.6586 11.3284L19.7144 12.2488L19.9373 13.5485L18.7701 12.9349L17.603 13.5485L17.8259 12.2488L16.8816 11.3284L18.1865 11.1388L18.7701 9.9563Z" fill={color} />
    <path d="M22.7418 9.9563L23.3254 11.1388L24.6303 11.3284L23.6861 12.2488L23.909 13.5485L22.7418 12.9349L21.5746 13.5485L21.7975 12.2488L20.8533 11.3284L22.1582 11.1388L22.7418 9.9563Z" fill={color} />
    <path d="M13.6667 17H23.6667" stroke="black" strokeWidth="2" strokeLinecap="round" />
    <path d="M13.6667 21H19.6667" stroke="black" strokeWidth="2" strokeLinecap="round" />
    <path d="M13.6667 25H21.6667" stroke="black" strokeWidth="2" strokeLinecap="round" />
  </svg>);