import React, { FC, useEffect, useState } from 'react';
import { ApiSmsCampaigns } from '../../../../api';
import { CampaignStyles } from './styles';
import { truncateString } from '../../../../utils';
import { format } from 'date-fns';
import { statsContent } from './utils';
import { ArrowDownIcon } from '../../../../assets';
type TCampaign = {
  campaign: ApiSmsCampaigns;
};
export const Campaign: FC<TCampaign> = ({
  campaign
}) => {
  const [open, setOpen] = useState<boolean>(false);
  return <CampaignStyles>
      <div className="campaign-header" onClick={() => setOpen(!open)}>
        <div className="campaign-info">
          <h3>{truncateString(campaign.name, 150)}</h3>
          <div className="campaign-stat" style={{
          backgroundColor: statsContent.itemsTotal.color
        }}>
            Всього: {campaign.itemsTotal}
          </div>
          <div className="campaign-stat" style={{
          backgroundColor: statsContent.itemsDelivered.color
        }}>
            Доставлено: {campaign.itemsDelivered}
          </div>
          <div className="campaign-stat" style={{
          backgroundColor: statsContent.itemsFailed.color
        }}>
            Не вдалося: {campaign.itemsFailed}
          </div>
        </div>
        <div className="campaign-utils">
          <p>Створено: {format(new Date(campaign.createdAt), 'dd.MM.yyyy | HH:mm:ss')}</p>
          <div className={open ? 'campaign-arrow' : 'campaign-arrow active'}>
            <ArrowDownIcon />
          </div>
        </div>
      </div>
      <div className={open ? 'campaign-division active' : 'campaign-division'} />
      <div className={open ? 'campaign-content active' : 'campaign-content'}>
        <h3>{campaign.name}</h3>
        <div className="campaign-charts">
          {Object.keys(statsContent).map(stat => {
          const key = stat as keyof typeof campaign;
          const part = parseFloat(campaign[key].toString()) / campaign.itemsTotal * 100;
          return <div className="campaign-chart">
                <div className="campaign-bar">
                  <h4>{campaign[key].toString()}</h4>
                  <div style={{
                height: `${part}%`,
                background: statsContent[stat].color
              }} />
                </div>
                <p>{statsContent[stat].text}</p>
              </div>;
        })}
        </div>
      </div>
    </CampaignStyles>;
};