import styled from 'styled-components';
export const CustomSelectStyles = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 4rem;
  background-color: transparent;
  border: 3px solid rgb(255, 255, 255);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 5px 0 16px;
  position: relative;
  cursor: pointer;

  .labelContainer {
    margin-bottom: 10px;
    display: flex;

    label {
      font-weight: 500;
      font-size: var(--fs-3);
      line-height: 120%;
      color: #000000;
    }

    .required {
      color: #ff0101;
    }
  }

  .placeholder {
    font-size: var(--fs-3);
    color: #666666;
  }

  .value {
    font-size: var(--fs-3);
    color: #000;
  }

  .searchWrapper {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c4c4c4;
    box-sizing: border-box;
    padding: 0 12px;
    background-color: #fcfcfc;
    height: 40px;

    svg {
      flex-shrink: 0;
    }

    input {
      flex-grow: 1;
      margin-left: 12px;
      padding: 0;
      border: none;
      outline: none;
      font-size: var(--fs-5);
      color: #000;
      background-color: #fcfcfc;

      &::placeholder {
        font-size: var(--fs-5);
        color: #6e6e6e;
      }
    }
  }

  .selectedValue {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .arrowDownActive {
      transform: rotate(180deg);
    }
  }

  .options {
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%);
    z-index: 100;
    width: 100%;
    overflow-y: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    max-height: 200px;

    & > .option:last-child .row {
      border-bottom: none;
    }

    .option {
      height: 42px;
      border-bottom: 1px solid rgb(196, 196, 196);

      .noItems {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--fs-2);
        color: #6e6e6e;
        width: 100%;
        height: 100%;
      }

      button {
        width: 100%;
        height: 100%;
        font-size: var(--fs-3);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;

        .labelContent {
          display: flex;
          justify-content: flex-start;
          gap: 20px;
          align-items: center;
          width: 100%;
          height: 100%;
          color: #6e6e6e;
          box-sizing: border-box;

          svg {
            flex-shrink: 0;
          }

          span {
            font-size: var(--fs-5);
            text-align: left;
          }
        }

        .default {
          cursor: pointer;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 22px;
          height: 22px;
          background-color: #ffffff;
          border: 1px solid #e5e5e5;
          box-sizing: border-box;
        }

        .selected {
          border: 1px solid #000;
        }

        .square {
          width: 10px;
          height: 10px;
          background-color: #000;
        }
      }
    }
  }
`;
export const CustomSelectWrapperStyles = styled.div`
  width: 100%;

  .labelContainer {
    margin-bottom: 10px;
    display: flex;

    label {
      font-weight: 400;
      font-size: var(--fs-3);
      line-height: 120%;
    }

    .required {
      color: #ff0101;
    }
  }

  .selectErrorContainer {
    font-size: var(--fs-5);
    line-height: 120%;
    color: #c32900;
    position: absolute;
    width: 100%;
  }
`;