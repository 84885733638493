import styled from 'styled-components';

export const NodeFilterStyles = styled.div`
  font-weight: 400;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  background: #fff;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  z-index: 200;
  min-width: 270px;
  padding: var(--sm-4);
  border-radius: 4px;
  max-height: 350px;

  @media screen and (max-width: 1500px) {
    position: fixed;
    z-index: 99;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 250px;
  }

  .filterBtnContainer {
    width: 100%;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: var(--sm-5) 0;

    button {
      padding: var(--sm-4) 0;
    }
  }

  .selectContainer {
    width: 100%;
    height: 100%;
    max-height: calc(100% - 82px);
    overflow-y: auto;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
  }
`;
