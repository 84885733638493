import { accessesApi } from '../../../api/accessesApi/Accesses';
import { usersFilterApi } from '../../../api/usersFilter';
import { TOption } from '../../../components/molecules/customFilter/types';
export enum FilterKeys {
  // managerNames = 'managerNames',
  // managerOrgNames = 'managerOrgNames',
  // managerOrgTypes = 'managerOrgTypes',
  // workerOrgTypes = 'workerOrgTypes',
  // workerRegions = 'workerRegions',
  orgName = 'orgName',
  orgType = 'orgType',
  role = 'role',
  position = 'position',
  region = 'region',
  processedOrgType = 'processedOrgType',
  processedOrgName = 'processedOrgName',
  processedRole = 'processedRole',
  processedName = 'processedName',
}
const fetchMethods = {
  orgType: (searchString: string, limit: number, offset: number) => usersFilterApi.getOrgTypes(offset, limit, searchString),
  orgName: (searchString: string, limit: number, offset: number) => usersFilterApi.getOrgNames(offset, limit, searchString),
  // user filters
  role: (searchString: string, limit: number, offset: number) => usersFilterApi.getRoles(offset, limit, searchString),
  // TODO fix
  position: (searchString: string, limit: number, offset: number) => usersFilterApi.getPositions(offset, limit, searchString),
  region: (searchString: string, limit: number, offset: number) => usersFilterApi.getRegions(offset, limit, searchString),
  // status: (searchString: string, limit: number, offset: number) => usersFilterApi.getRegions(offset, limit, searchString),
  // manager filters
  processedOrgType: (searchString: string, limit: number, offset: number) => usersFilterApi.getProcessedOrgTypes(offset, limit, searchString),
  processedOrgName: (searchString: string, limit: number, offset: number) => usersFilterApi.getProcessedOrgNames(offset, limit, searchString),
  processedRole: (searchString: string, limit: number, offset: number) => usersFilterApi.getProcessedRoles(offset, limit, searchString),
  processedName: (searchString: string, limit: number, offset: number) => usersFilterApi.getProcessedNames(offset, limit, searchString)
  // managerNames: (searchString: string, limit: number, offset: number) => accessesApi.accessReviewManagerNameFilters(searchString, limit, offset),
  // managerOrgNames: (searchString: string, limit: number, offset: number) => accessesApi.accessReviewManagerOrgNameFilters(searchString, limit, offset),
  // managerOrgTypes: (searchString: string, limit: number, offset: number) => accessesApi.accessReviewManagerOrgTypeFilters(searchString, limit, offset),
  // // worker filters
  // workerOrgTypes: (searchString: string, limit: number, offset: number) => accessesApi.accessReviewWorkerOrgTypeFilters(searchString, limit, offset),
  // workerRegions: (searchString: string, limit: number, offset: number) => accessesApi.accessReviewWorkerRegionFilters(searchString, limit, offset),
};
export const usePaginatedFilters = (): {
  getPaginatedInitialValues?: (key: FilterKeys, limit: number, offset: number, q: string) => Promise<{
    count: number;
    items: TOption[];
  }>;
} => {
  const getPaginatedInitialValues = async (key: FilterKeys, l: number, o: number, q: string): Promise<{
    count: number;
    items: TOption[];
  }> => {
    const {
      data
    } = await fetchMethods[key](q, l, o);
    return {
      count: data.count,
      items: data.items.map((e: string) => ({
        id: e,
        value: false,
        name: e
      }))
    };
  };
  return {
    getPaginatedInitialValues
  }; // Empty dependency array means this runs once on mount
};