import {
  ConversionMassMailings,
  DeliveredMassMailings,
  FailedMassMailings,
  OpenedMassMailings,
  SentMassMailings,
  TotalMassMailings,
} from '../../assets';

export enum TabMailKeys {
  createCampaign = 'createCampaign',
  campaign = 'campaign',
}

export type TTabMail = {
  key: TabMailKeys;
  text: string;
};

export const tabsEdit: (lang: string) => TTabMail[] = (lang: string) => [
  {
    key: TabMailKeys.createCampaign,
    text: 'Нові розсилки',
  },
  {
    key: TabMailKeys.campaign,
    text: 'Відправлені розсилки',
  },
];

export const channels = [
  { channel: 'sms' },
  { channel: 'viber' },
  { channel: 'email' },
];

export enum StatsKeys {
  delivered = 'delivered',
  failed = 'failed',
  formOpened = 'formOpened',
  formSubmitted = 'formSubmitted',
  processed = 'processed',
  sent = 'sent',
  total = 'total',
}

export type TStats = {
  delivered: number;
  failed: number;
  formOpened: number;
  formSubmitted: number;
  processed: number;
  sent: number;
  total: number;
};

export const statsContent: {[key: string]: { [key: string]: any }} = {
  // total: {
  //   icon: TotalMassMailings,
  //   text: 'Створено розсилок',
  // },
  sent: {
    icon: SentMassMailings,
    text: 'Відправлено',
  },
  delivered: {
    icon: DeliveredMassMailings,
    text: 'Доставлено',
  },
  failed: {
    icon: FailedMassMailings,
    text: 'Не вдалося',
  },
  formOpened: {
    icon: OpenedMassMailings,
    text: 'Відкрили форму',
  },
  formSubmitted: {
    icon: ConversionMassMailings,
    text: 'Відправили форму',
  },
};
