import styled from 'styled-components';

export const ChartSelectorStyles = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: 1500px) {
    gap: 15px;
  }

  button {
    &.chart-next,
    &.chart-prev {
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 1500px) {
        height: 20px;
        width: 20px;
      }
    }
  }

  .chart-next {
    transform: rotate(180deg);
  }

  .chart-selector {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;

    .chart-position {
      border: 2px solid rgba(12, 0, 5, 0.2);
      border-radius: 12px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 1500px) {
        border-radius: 6px;
      }
    }

    .chart-name {
      font-size: var(--fs-5);
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .chart-options {
      position: absolute;
      overflow: auto;
      z-index: 10;
      left: 50%;
      transform: translate(-50%, -100%);
      top: -10px;
      max-height: 200px;
      background: #fff;
      width: 100%;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;

      .chart-option {
        font-size: var(--fs-7);
        padding: 8px 12px;
        height: 30px;
        text-align: start;

        &:not(:last-child) {
          border-bottom: 1px solid rgb(196, 196, 196);
        }

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
`;
