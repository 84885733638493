export const getQuarterDates = (quarter: number, year: number): { from: string, to: string } => {
  const startMonth = (quarter - 1) * 3 + 1;

  const startDate = new Date(year, startMonth - 1, 1);

  let endDate: Date;
  if (quarter === 4) {
    endDate = new Date(year + 1, 0, 0);
  } else {
    endDate = new Date(year, startMonth + 2, 0);
  }

  const formatDate = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const startDateStr = formatDate(startDate);
  const endDateStr = formatDate(endDate);

  return {
    from: startDateStr,
    to: endDateStr,
  };
}
