import { useRef, useState } from 'react';
import { usersApi } from '../../api';
import { DeleteIcon } from '../../assets';
import { ModalConfirmDecline } from '../../components';
import { useClickOutside } from '../../hooks/useClickOutside';
import { getTranslationByLangOrEng } from '../../i18n';
import { useAppSelector } from '../../state';
import { usePermissionView } from '../../hooks';
import { FilterKeys } from './hooks/usePaginatedFilters';
export const filtersAdapter = (params: {
  [key: string]: {
    id: string;
  }[];
}): {
  // frontOffices: string[],
  orgNames: string[];
  orgTypes: string[];
  positions: string[];
  processedNames: string[];
  processedOrgNames: string[];
  processedOrgTypes: string[];
  processedRoles: string[];
  regions: string[];
  roles: string[];
  searchQuery: string;
} => ({
  // frontOffices: params.frontOffices?.map((e) => e.id),
  orgNames: params.orgName?.map(e => e.id),
  orgTypes: params.orgType?.map(e => e.id),
  positions: params.position?.map(e => e.id),
  processedNames: params.processedName?.map(e => e.id),
  processedOrgNames: params.processedOrgName?.map(e => e.id),
  processedOrgTypes: params.processedOrgType?.map(e => e.id),
  processedRoles: params.processedRole?.map(e => e.id),
  regions: params.region?.map(e => e.id),
  roles: params.roles?.map(e => e.id),
  searchQuery: params.searchQuery as unknown as string
});
export const extraFieldsSettings = {
  email: {
    justifyContent: 'center'
  },
  role: {
    justifyContent: 'center'
    // minWidth: 0.5,
  },
  name: {
    justifyContent: 'center'
    // minWidth: 0.2,
  }
};
export function EditButtons({
  id,
  filterItemsAfterDelete
}: {
  id: number;
  filterItemsAfterDelete: (id: number) => void;
}) {
  const hasAnyAccess = usePermissionView();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [modalIndex, setModalIndex] = useState<number>(0);
  const [modalErrorIndex, setModalErrorIndex] = useState<number>(0);
  const menuRef = useRef<any>();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  useClickOutside(menuRef, () => setMenuOpen(false));
  return <div className="editButtonsContainer">
      <div className="menuOpenerContainer" ref={menuRef}>
        {hasAnyAccess('/auth/users').delete && <button type="button" onClick={e => {
        e.stopPropagation();
        setModalIndex(1);
      }}>
            <DeleteIcon />
          </button>}
      </div>
      {modalIndex === 1 && <ModalConfirmDecline onClose={() => setModalIndex(0)} onDecline={() => setModalIndex(0)} onConfirm={() => {
      usersApi.deleteUser(id).then(res => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          filterItemsAfterDelete(id);
          setModalIndex(0);
        } else {
          setModalErrorIndex(1);
        }
      });
    }} title="Видалити користувача" buttonsLabel={getTranslationByLangOrEng(interfaceLanguage, 'users_main_delete_description')} confirmText={getTranslationByLangOrEng(interfaceLanguage, 'delete_button')} declineText={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} firstButtonStyles={{
      border: '1px solid #C4C4C4',
      backgroundColor: '#ffffff',
      color: '#000000'
    }} secondButtonStyles={{
      backgroundColor: '#FF0101',
      border: '1px solid #FF0101'
    }} error={modalErrorIndex === 1} errorText={getTranslationByLangOrEng(interfaceLanguage, 'delete_button')} />}
    </div>;
}