import React, { FC, useEffect, useState, useRef } from 'react';
import { read, utils } from 'xlsx';
import { useAppSelector } from '../../../state';
import { CustomSelect, InputField, Modal, NameCustomField, Table, TransparentButton } from '../../../components';
import { ApiGetTemplates, formApi, massMailingApiV2, massMailingApi, ApiMassSmsItem, ApiMassViberItem } from '../../../api';
import { ICompanyForm } from '../../../entities';
import { WrapBlocks } from '../../../components/organisms/templaters/utils';
import { providers } from '../../../constants';
import { UploadFileButton } from '../../../components/form/uploadFileButton/UploadFileButton';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { getErrorMessage, handleKeyUp } from '../../../utils';
import SubmitButton from '../../../components/form/submitButton/SubmitButton';
import { SmsStyles } from './SmsStyles';
import { validationSchema, exportFile, removeDuplicates, validateXLSX } from './utils';
import { MassForm, TSms } from './SmsTypes';
import { toast } from 'react-toastify';
import { Link } from './components/Link';
import { getTranslationByLangOrEng } from '../../../i18n/translations';
import { campaignApi } from '../../../api/campaign/CampaignApi';
const monthNames: {
  [key: string]: string;
} = {
  January: 'січня',
  February: 'лютого',
  March: 'березня',
  April: 'квітня',
  May: 'травня',
  June: 'червня',
  July: 'липня',
  August: 'серпня',
  September: 'вересня',
  October: 'жовтня',
  November: 'листопада',
  December: 'грудня'
};

/**
 * Функция для форматирования даты в удобный для SMS формат
 * @param {string} isoDate - Дата в формате ISO 8601
 * @returns {string} - Форматированная дата
 */
const formatDateToSMS = (isoDate: string) => {
  const date = new Date(isoDate);
  // if (isNaN(date)) throw new Error('Неверный формат даты');

  const day = date.getUTCDate();
  const month = monthNames[date.toLocaleString('en-US', {
    month: 'long'
  })];
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  return `${day} ${month} ${year} року о ${hours}:${minutes}`;
};
const getRecepients = async ({
  params,
  companyID,
  campaign
}: {
  params: any;
  companyID: number;
  campaign: string;
}) => {
  const items = await campaignApi.getCampaignDataByCompany(companyID, {
    ...(params.from ? {
      from: params.from
    } : {}),
    ...(params.to ? {
      to: params.to
    } : {}),
    ...(params.region ? {
      region: params.region
    } : {}),
    ...(params.serviceName ? {
      serviceТame: params.serviceName
    } : {}),
    ...(params.clientGender ? {
      clientGender: params.clientGender
    } : {}),
    ...(params.serviceResult ? {
      serviceResult: params.serviceResult
    } : {}),
    ...(params.serviceStatus ? {
      serviceStatus: params.serviceStatus
    } : {}),
    ...(params.idf ? {
      idf: params.idf
    } : {}),
    // @ts-ignore
    ...(params.clientPhone && params.clientPhone.length < 2 // @ts-ignore
    ? {
      hasPhone: params.clientPhone[0] === '1'
    } : {}),
    // @ts-ignore
    ...(params.clientEmail && params.clientEmail.length < 2 // @ts-ignore
    ? {
      hasEmail: params.clientEmail[0] === '1'
    } : {})
  }, 0, 10, true);
  const recepients = items.data.map(e => {
    const {
      id,
      clientPhone,
      serviceInTime,
      state,
      clientEmail,
      visitedAt,
      ...rest
    } = e;
    const clPhone = clientPhone;
    const linkParams: {
      [key: string]: any;
    } = {};
    Object.keys(rest).forEach(key => {
      // @ts-ignore
      linkParams[`url_${key}`] = rest[key];
    });
    return {
      email: clientEmail,
      phone: clPhone.startsWith('+') ? clPhone : `+${clPhone}`,
      caption: campaign,
      contentType: 'text',
      imageUrl: e.image,
      variables: {
        ...rest,
        ...linkParams,
        visitedAt
      }
    };
  });
  return recepients;
};
const mapFileItems = (fileItems: any) => fileItems?.map((el: any) => {
  const {
    phone,
    email,
    visitedAt,
    ...rest
  } = el;
  const clPhone = el.clientPhone ?? el.phone;
  const clEmail = el.clientEmail ?? el.email;
  const linkParams: {
    [key: string]: any;
  } = {
    url_phone: phone,
    url_email: email
  };
  Object.keys(rest).forEach(key => {
    // @ts-ignore
    linkParams[`url_${key}`] = rest[key];
  });
  return {
    phone: clPhone.startsWith('+') ? clPhone : `+${clPhone}`,
    email: clEmail,
    variables: {
      ...rest,
      ...linkParams
    }
  };
});
const initialValues: MassForm = {
  mailingType: null,
  campaign: '',
  template: null,
  form: null,
  type: null,
  fileItems: []
};
const channels = [{
  channel: 'Файл',
  id: 'file'
}, {
  channel: 'Список контактів',
  id: 'list'
}];
const mailingTypes = [{
  mailingType: 'SMS',
  id: 'sms'
}, {
  mailingType: 'Viber',
  id: 'viber'
}, {
  mailingType: 'Email',
  id: 'email'
}];
export const Sms: FC<TSms> = ({
  companyID,
  setAddCampaign,
  filters
}) => {
  const [warnings, setWarnings] = useState<string[]>([]);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [templates, setTemplates] = useState<ApiGetTemplates[]>([]);
  const [forms, setForms] = useState<ICompanyForm[]>([]);
  const [fileData, setFileData] = useState<{
    [key: string]: string;
  }[]>([]);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: any;
    accessor: string;
    Cell?: any;
  }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [pageNumber, setPageNumber] = useState<number>(1);
  const formRef = useRef<FormikProps<MassForm>>(null);
  const formCount = useRef<number>(0);
  useEffect(() => {
    if (companyID) {
      if (formRef.current?.values.mailingType?.id === 'sms') {
        massMailingApiV2.getSmsTemplates(companyID).then(res => {
          setTemplates(res.data.templates.filter(e => e.companyID === companyID));
        });
      } else if (formRef.current?.values.mailingType?.id === 'viber') {
        massMailingApiV2.getViberTemplates(companyID).then(res => {
          setTemplates(res.data.templates.filter(e => e.companyID === companyID));
        });
      } else {
        massMailingApiV2.getEmailTemplates(companyID).then(res => {
          setTemplates(res.data.templates);
        });
      }
    }
  }, [companyID, formRef.current?.values.mailingType?.id]);
  useEffect(() => {
    if (companyID) {
      setFormLoading(true);
      formApi.getFormsByCompany(+companyID, (pageNumber ? +pageNumber - 1 : 0) * 20, 20).then(res => {
        setForms(prev => [...prev, ...res.data]);
        formCount.current = res.count ? res.count : 0;
        setFormLoading(false);
      });
    }
  }, [companyID, pageNumber]);
  const createSmsCampaign = async ({
    params,
    typeId,
    campaign,
    formId,
    templateId,
    fileItems
  }: {
    params: any;
    typeId: string;
    campaign: string;
    formId: number;
    templateId: number;
    fileItems?: any[];
  }) => {
    let items: ApiMassSmsItem[] | ApiMassViberItem[];
    if (typeId === 'file') {
      items = mapFileItems(fileItems);
      const resSms = await massMailingApi.createMassSmsMailing({
        companyID,
        name: campaign,
        formID: formId,
        templateID: templateId,
        items
      });
      if (resSms.statusCode === 200) {
        setTableHeaders([]);
        formRef?.current?.resetForm();
        setAddCampaign(false);
        setIsLoading(false);
      } else {
        setErrorMessage('Помилка');
        setIsLoading(false);
      }
    } else {
      const resSms = await massMailingApi.createFilteredMassSmsMailing({
        companyID,
        name: campaign,
        formID: formId,
        templateID: templateId,
        filter: {
          ...(params.createdAtFrom ? {
            from: params.createdAtFrom
          } : {}),
          ...(params.createdAtTo ? {
            to: params.createdAtTo
          } : {}),
          ...(params.parentName ? {
            parentName: params.parentName
          } : {}),
          ...(params.region ? {
            region: params.region
          } : {}),
          ...(params.serviceName ? {
            serviceName: params.serviceName
          } : {}),
          ...(params.clientGender ? {
            clientGender: params.clientGender
          } : {}),
          ...(params.serviceResult ? {
            serviceResult: params.serviceResult
          } : {}),
          ...(params.serviceStatus ? {
            serviceStatus: params.serviceStatus
          } : {}),
          ...(params.idf ? {
            idf: params.idf
          } : {}),
          ...(params.serviceId ? {
            serviceId: params.serviceId
          } : {}),
          ...(params.clientPhone && params.clientPhone.length < 2 ? {
            hasPhone: params.clientPhone[0] === '1'
          } : {}),
          ...(params.clientEmail && params.clientEmail.length < 2 ? {
            hasEmail: params.clientEmail[0] === '1'
          } : {})
        }
      });
      if (resSms.statusCode === 200) {
        setTableHeaders([]);
        formRef?.current?.resetForm();
        setAddCampaign(false);
        setIsLoading(false);
      } else {
        setErrorMessage('Помилка');
        setIsLoading(false);
      }
    }
  };
  const createViberCampaign = async ({
    params,
    typeId,
    campaign,
    formId,
    templateId,
    fileItems
  }: {
    params: any;
    typeId: string;
    campaign: string;
    formId: number;
    templateId: number;
    fileItems?: any[];
  }) => {
    let items: ApiMassSmsItem[] | ApiMassViberItem[];
    if (typeId === 'file') {
      items = mapFileItems(fileItems);
    } else {
      items = await getRecepients({
        params,
        companyID,
        campaign
      });
    }
    const resViber = await massMailingApi.createMassViberMailing({
      companyID,
      name: campaign,
      contentType: 'text',
      formID: formId,
      templateID: templateId,
      items
    });
    setIsLoading(false);
    if (resViber.statusCode === 200) {
      setTableHeaders([]);
      formRef?.current?.resetForm();
      setAddCampaign(false);
    } else {
      setErrorMessage('Something went wrong');
    }
  };
  const createEmailCampaign = async ({
    params,
    typeId,
    campaign,
    formId,
    templateId,
    fileItems
  }: {
    params: any;
    typeId: string;
    campaign: string;
    formId: number;
    templateId: number;
    fileItems?: any[];
  }) => {
    let items: ApiMassSmsItem[] | ApiMassViberItem[];
    if (typeId === 'file') {
      items = mapFileItems(fileItems);
    } else {
      items = await getRecepients({
        params,
        companyID,
        campaign
      });
    }
    const resEmail = await massMailingApi.createMassEmailMailing({
      companyID,
      name: campaign,
      formID: formId,
      templateID: templateId,
      items
    });
    setIsLoading(false);
    if (resEmail.statusCode >= 200 && resEmail.statusCode < 300) {
      setTableHeaders([]);
      formRef?.current?.resetForm();
      setAddCampaign(false);
    } else {
      setErrorMessage('Something went wrong');
    }
  };
  const onSubmit = async (values: MassForm, {
    setSubmitting,
    resetForm,
    validateForm
  }: FormikHelpers<MassForm>) => {
    if (!isLoading) {
      setSubmitting(false);
      setIsLoading(true);
      const params = filters;
      switch (values.mailingType?.id) {
        case 'sms':
          await createSmsCampaign({
            params,
            typeId: values.type?.id!,
            templateId: values.template?.id!,
            fileItems: values.fileItems,
            formId: values.form?.id!,
            campaign: values.campaign
          });
          break;
        case 'viber':
          await createViberCampaign({
            params,
            typeId: values.type?.id!,
            templateId: values.template?.id!,
            fileItems: values.fileItems,
            formId: values.form?.id!,
            campaign: values.campaign
          });
          break;
        case 'email':
          await createEmailCampaign({
            params,
            typeId: values.type?.id!,
            templateId: values.template?.id!,
            fileItems: values.fileItems,
            formId: values.form?.id!,
            campaign: values.campaign
          });
          break;
      }
    }
  };
  useEffect(() => {
    if (fileData.length) {
      setTableHeaders(Object.entries(fileData[0]).map(([key, value]) => ({
        Header: () => key,
        accessor: key,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <NameCustomField key={key} name={row.original[key]} />
      })));
    }
  }, [fileData]);
  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
    setErrors
  }: FormikProps<MassForm>) => <Form>
      <div className="mass-wrapper">
        <div className="mass-row" style={{
        alignItems: 'flex-end',
        marginBottom: 10
      }}>
          <CustomSelect options={mailingTypes} selected={values?.mailingType} valueField="mailingType" labelField="mailingType" name="mailingType" onChange={(value: any) => {
          setFieldValue('mailingType', value);
          setFieldValue('fileItems', []);
          setFieldValue('template', null);
        }} placeholder="Тип розсилки" />
        </div>
        <div className="mass-row" style={{
        alignItems: 'flex-end'
      }}>
          <InputField name="campaign" onChange={setFieldValue} onKeyUp={() => handleKeyUp('campaign', setErrorMessage, errorMessage)} placeholder="Введіть назву розсилки" value={values.campaign} error={typeof errorMessage === 'object' ? getErrorMessage('campaign', errorMessage) : undefined} label="Назва кампанії" />
          <CustomSelect options={forms} selected={values?.form} valueField="id" labelField="name" name="form" totalCount={formCount.current} pageNumberHandler={setPageNumber} offset={20} currentPage={pageNumber} onChange={(value: any) => {
          setFieldValue('form', value);
          setFileData([]);
          setFieldValue('fileItems', []);
        }} placeholder="Оберіть форму" />
        </div>
        {values?.mailingType && <div className="mass-row">
            <CustomSelect options={templates} selected={values?.template} valueField="id" labelField="name" name="template" onChange={(value: any) => {
          setFieldValue('template', value);
          setFieldValue('fileItems', []);
        }} placeholder="Оберіть шаблон" />
          </div>}
        <div className="mass-row">
          <CustomSelect options={channels} selected={values?.type} valueField="id" labelField="channel" name="type" onChange={(value: any) => {
          setFieldValue('type', value);
          setFieldValue('fileItems', []);
        }} placeholder="Джерело даних" />
        </div>
        {values?.type?.id === 'file' && <div className="fileUpload">
            <div className="row">
              <p style={{
            fontSize: 14
          }}>Завантажити файл (XLSX)</p>
              {/* <Link
                  disabled={!values?.form}
                  onPress={() => exportFile(formRef.current?.values.form?.id!)}
                /> */}
            </div>
            <UploadFileButton name={getTranslationByLangOrEng(interfaceLanguage, 'choose_file')} onChange={async (file?: File) => {
          setTableHeaders([]);
          setFileData([]);
          setFieldValue('fileItems', []);
          setErrors({});
          if (file) {
            const bufferData = await file.arrayBuffer();
            const workbook = read(bufferData);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const worksheetDataInJson = utils.sheet_to_json<{
              [key: string]: string;
            }>(worksheet);
            for (const object of worksheetDataInJson) {
              // eslint-disable-next-line guard-for-in
              for (const key in object) {
                object[key] = `${object[key]}`;
              }
              if (object.clientPhone && !object.clientPhone.startsWith('+')) {
                object.phone = `+${object.clientPhone}`;
              }
            }
            // Remove duplicates based on phone field
            const uniquePhoneData = removeDuplicates(worksheetDataInJson, 'clientPhone');
            setFileData(uniquePhoneData);
            setFieldValue('fileItems', uniquePhoneData);
          }
        }} extraButtonStyles={{
          height: 38,
          width: '100%'
        }} formats=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" fieldName="fileItems" />
          </div>}
        <div className="mass-row" style={{
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'flex-start'
      }}>
          {errors.fileItems && <p className="extraErrorMessage">
              {Array.isArray(errors.fileItems) && errors.fileItems.length ? errors.fileItems.join(' ') : errors.fileItems}
            </p>}
          {typeof errorMessage === 'string' && <p className="extraErrorMessage">{errorMessage}</p>}
          {errors.form && <p className="extraErrorMessage">{errors.form}</p>}
          {!!warnings.length && <p className="extraWarningMessage">
              Відсутні поля: {warnings.join(', ')}
            </p>}
        </div>
        {values?.template && <div className="mass-row">
            <WrapBlocks text={values?.template.template} />
          </div>}
        <div className="mass-row">
          <SubmitButton isLoading={isLoading} isError={touched && Object.keys(errors).length > 0} extraBlockStyles={{
          width: '100%'
        }}>
            Створити розсилку
          </SubmitButton>
        </div>
        {!!tableHeaders.length && !!fileData.length && <div className="table-wrapper">
            <Table pagination columns={tableHeaders} data={values.fileItems.slice(0, 5)} hideFieldsSplitters />
          </div>}
      </div>
    </Form>;
  function onModalSuccess() {
    // @ts-ignore
    onSubmit(formRef.current?.values!, {
      setStatus: formRef.current?.setStatus!,
      setErrors: formRef.current?.setErrors!,
      setSubmitting: formRef.current?.setSubmitting!
    }).then(() => {
      setIsOpened(false);
    });
  }
  return <SmsStyles>
      {companyID && <Formik validateOnBlur={false} validateOnChange validateOnMount={false} innerRef={formRef} initialValues={initialValues} onSubmit={warnings.length ? () => setIsOpened(true) : onSubmit} enableReinitialize validationSchema={validationSchema(interfaceLanguage)}>
          {renderForm}
        </Formik>}
      {isOpened && <Modal title="Ви впевнені?" extraStyles={{
      width: 430,
      paddingBottom: 0
    }} onClose={() => setIsOpened(false)}>
          <div className="mass-row" style={{
        justifyContent: 'flex-start',
        marginBottom: 20
      }}>
            {getTranslationByLangOrEng(interfaceLanguage, 'mass_mailing_confirmation_modal_subtitle1') + warnings.join(', ') + getTranslationByLangOrEng(interfaceLanguage, 'mass_mailing_confirmation_modal_subtitle2')}
          </div>
          <div className="mass-row">
            <TransparentButton extraContainerStyles={{
          width: '100%'
        }} extraButtonTextStyles={{
          color: '#000'
        }} extraStyles={{
          width: '100%',
          backgroundColor: '#ccc'
        }} disabled={false} handleClick={() => setIsOpened(false)} text="Cancell" filled />
            <TransparentButton extraContainerStyles={{
          width: '100%'
        }} extraStyles={{
          width: '100%'
        }} disabled={false} handleClick={onModalSuccess} text="Ok" filled />
          </div>
        </Modal>}
    </SmsStyles>;
};