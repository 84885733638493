import styled from 'styled-components';
export const FilterSelectStyles = styled.div`
  background: #fff;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 4px;
  display: flex;
  flex-direction: column;
  padding: 5px 15px 20px;
  gap: 10px;
  font-family: 'e-Ukraine', sans-serif !important;

  @media screen and (max-width: 1500px) {
      padding: 5px 12px 12px;
    }

  h4 {
    @media screen and (max-width: 1500px) {
      font-size: var(--fs-4);
    }
  }

  .select {
    width: 100%;

    &-wrapper {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      border-radius: 5px;
      height: 40px;
      border: 1px solid #ccc;
      padding: 0px 0px 0px 16px;
      cursor: pointer;

      @media screen and (max-width: 1500px) {
        padding: 0px 0px 0px 8px;
        height: 32px;
      }
    }

    &-value {
      width: 100%;
      display: flex;
      font-size: var(--fs-4);
      align-items: center;
      white-space: nowrap;
      justify-content: space-between;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-arrow {
      transition: all 0.3s ease-in-out;
      &-active {
        transform: rotate(180deg);
      }
    }

    &-options {
      background-color: #ffffff;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 4px;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 100;
      width: 100%;
      overflow-y: auto;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
      max-height: 200px;
    }

    &-search {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #c4c4c4;
      box-sizing: border-box;
      padding: 0 12px;
      background-color: #fcfcfc;
      height: 40px;

      @media screen and (max-width: 1500px) {
        height: 32px;
      }

      svg {
        flex-shrink: 0;
      }

      input {
        flex-grow: 1;
        margin-left: 12px;
        padding: 0;
        border: none;
        outline: none;
        font-size: var(--fs-4);
        color: #000;
        background-color: #fcfcfc;

        &::placeholder {
          font-size: var(--fs-5);
          color: #6e6e6e;
        }
      }
    }

    &-option {
      min-height: 40px;
      border-bottom: 1px solid rgb(196, 196, 196);
      display: flex;
      align-items: center;

      @media screen and (max-width: 1500px) {
        height: 32px;
      }

      button {
        width: 100%;
        height: 100%;
        font-size: var(--fs-4);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        gap: 10px;

        @media screen and (max-width: 1500px) {
          padding: 0 8px;
          gap: 6px;
        }
      }
    }

    &-noitem {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: var(--fs-4);
      color: #6e6e6e;
      width: 100%;
      height: 100%;
    }

    &-selected {
      border: 1px solid #000;
    }

    &-default {
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 22px;
      height: 22px;
      background-color: #ffffff;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;

      @media screen and (max-width: 1500px) {
        width: 18px;
        height: 18px;
      }
    }

    &-content {
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #6e6e6e;
      box-sizing: border-box;

      @media screen and (max-width: 1500px) {
        gap: 10px;
      }

      svg {
        flex-shrink: 0;
      }

      span {
        font-size: var(--fs-5);
        text-align: left;
        font-family: 'e-Ukraine', sans-serif !important;

        @media screen and (max-width: 1500px) {
          font-size: var(--fs-7);
        }
      }
    }
  }
`;