import { TAccessRequestFilters } from '../../../api/accessesApi/IAccessesApi';
import { FilterKeys } from '../hooks/usePaginatedFilters';
import { TOption } from '../../../components/molecules/customFilter/types';
import { sort } from 'fast-sort';

const mapFilters = (options: TOption[]) => options?.map((e) => e.id);

export const filterAdapter = (
  limit: number,
  offset: number,
  filters: any,
): TAccessRequestFilters => {
  console.log('filterAdapter');
  return {
    isManager: true,
    limit,
    offset,
    // created at
    createdAtFrom: filters.createdAtFrom,
    createdAtTo: filters.createdAtTo,
    // sorting
    // order: ['field:direction'], // fields [createdAt, updatedAt], directions [asc, desc]
    order: Object.keys(filters?.sorting ?? {})
      .filter((key) => filters.sorting[key] !== undefined)
      .map((key) => `${key}:${filters.sorting[key]}`),
    // manager filters
    managerNames: mapFilters(filters[FilterKeys.managerNames]),
    managerOrgNames: mapFilters(filters[FilterKeys.managerOrgNames]),
    managerOrgTypes: mapFilters(filters[FilterKeys.managerOrgTypes]),
    // processed filters
    processedAtFrom: filters.processedAtFrom,
    processedAtTo: filters.processedAtTo,
    processedByNames: filters.processedByNames,
    processedName: filters.processedName,
    // statuses filters
    statuses: filters.statuses,
    // worker filters and workerName search
    workerFrontOffices: filters.workerFrontOffices,
    workerName: filters.workerName,
    workerOrgTypes: mapFilters(filters[FilterKeys.workerOrgTypes]),
    workerOrgNames: filters.workerOrgNames,
    workerRegions: mapFilters(filters[FilterKeys.workerRegions]),
  };
};
