import { UsersStyles } from './UsersStyles';
import { getTranslationByLangOrEng } from '../../i18n';
import { SearchIcon } from '../../assets';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiResponse, TUserItem, usersApi } from '../../api';
import { IUser, IUserRole } from '../../entities';
import { availablePageSizes } from '../../constants';
import { useAppSelector } from '../../state';
import { Helmet } from 'react-helmet-async';
import { EditButtons, extraFieldsSettings, filtersAdapter } from './utils';
import { useDebounce, usePermissionView } from '../../hooks';
import { EditUserModal, Loader, NameCustomField, SubHeader, Table, TransparentButton } from '../../components';
import { TOption } from '../../components/molecules/customFilter/types';
import { CustomFilter } from '../../components/molecules/customFilter';
import { FilterKeys, usePaginatedFilters } from './hooks/usePaginatedFilters';
export const Users = () => {
  const {
    pageNumber
  } = useParams();
  const navigate = useNavigate();
  const hasAnyAccess = usePermissionView();
  const [search, setSearch] = useState<string>('');
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [uploading, setUploading] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<number>();
  const [editModal, setEditModal] = useState<boolean>(false);
  const [users, setUsers] = useState<TUserItem[]>([]);
  const [pageSize, setPageSize] = useState(availablePageSizes[4]);
  const filterParamsRef = useRef<{
    [key: string]: TOption[];
  }>({});
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [isFilterOpened, setIsFilterOpened] = useState<boolean>(false);
  const debouncedSearch = useDebounce(search, 1000);
  const [reQuery, setReQuery] = useState<boolean>(false);
  const itemsQuantityRef = useRef<number>(0);
  const setFilterParams = (params: any) => {
    filterParamsRef.current = params;
    setIsFilterOpened(false);
  };
  async function workWithResponse(response: ApiResponse<TUserItem[]>) {
    if (response.statusCode >= 200 && response.statusCode < 300) {
      const fullUsers = await response.data.map(async user => {
        const userRoles = await usersApi.getUser(user.id!).then(data => data.data.roles);
        return {
          ...user,
          roles: userRoles
        };
      });
      setUsers(await Promise.all(fullUsers));
      itemsQuantityRef.current = response.count!;
    }
  }
  const onClickToRow = async (data: any) => {
    setSelectedUser(data.id);
    setEditModal(true);
  };
  useEffect(() => {
    setUploading(true);
    usersApi.getFilteredUsersList({
      ...filtersAdapter({
        ...filterParamsRef.current,
        searchQuery: debouncedSearch
      }),
      offset: (pageNumber ? +pageNumber - 1 : 0) * pageSize,
      limit: pageSize
    }, (pageNumber ? +pageNumber - 1 : 0) * pageSize, pageSize).then(response => {
      workWithResponse(response);
    }).finally(() => {
      setUploading(false);
    });
  }, [pageNumber, pageSize, debouncedSearch, reQuery]);
  const filterItemsAfterDelete = useCallback((id: number) => {
    setUsers(prev => prev.filter(user => user.id !== id) || []);
  }, [users]);
  const {
    getPaginatedInitialValues
  } = usePaginatedFilters();
  const applyFilters = (params: any) => {
    filterParamsRef.current = {
      ...filterParamsRef.current,
      ...params
    };
    setReQuery(e => !e);
    navigate('/users/page/1');
  };
  useEffect(() => {
    if (users.length) {
      setTableHeaders([{
        Header: 'ID',
        accessor: 'id',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TUserItem;
          };
        }) => <NameCustomField name={data.row.original.id.toString()} />
      }, {
        Header: getTranslationByLangOrEng(interfaceLanguage, 'users_main_table_user_name_header'),
        accessor: 'name',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TUserItem;
          };
        }) => <NameCustomField name={data.row.original.name} />
      }, {
        Header: getTranslationByLangOrEng(interfaceLanguage, 'users_main_table_user_email_header'),
        accessor: 'email',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TUserItem;
          };
        }) => <NameCustomField name={data.row.original.email} />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <CustomFilter openerWidth={200} dropdownWidth={350} search resizable title="Тип установи користувача"
        // initialValues={initialPaginatedFilters.workerOrgTypes}
        filterParams={filterParamsRef.current} applyFilters={applyFilters} name={FilterKeys.orgType} getPaginatedInitialValues={getPaginatedInitialValues?.bind(null, FilterKeys.orgType)} />,
        accessor: 'orgType',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TUserItem;
          };
        }) => <NameCustomField name={data.row.original.orgType} textColor="#666666" />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <CustomFilter openerWidth={200} dropdownWidth={350} search resizable title="Назва установи користувача"
        // initialValues={initialPaginatedFilters.workerOrgTypes}
        filterParams={filterParamsRef.current} applyFilters={applyFilters} name={FilterKeys.orgName} getPaginatedInitialValues={getPaginatedInitialValues?.bind(null, FilterKeys.orgName)} />
        // <FilterSelectInput
        //   onlyEndingNodes
        //   filterWidth={200}
        //   filtersItemsWidth={300}
        //   search
        //   autoSelectChildren={false}
        //   resizable
        //   title=
        //   columnName="nodes2"
        //   // columnName="workerFrontOffice"
        //   filterParams={filterParamsRef.current}
        //   setFilterParams={setFilterParams}
        //   setReQuery={() => setReQuery(!reQuery)}
        //   setApply={applyFilters}
        //   containerExtraStyles="extraFilterStyles"
        //   extraFilterFormStyles="extraFilterFormStyles"
        //   optionsExtraStyles="optionsExtraStyles"
        // />
        ,
        accessor: 'workerOrgName',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TUserItem;
          };
        }) => <NameCustomField expandable name={data.row.original.orgName} textColor="#666666" />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <CustomFilter openerWidth={200} dropdownWidth={350} search resizable title="Роль"
        // initialValues={initialPaginatedFilters.workerOrgTypes}
        filterParams={filterParamsRef.current} applyFilters={applyFilters} name={FilterKeys.role} getPaginatedInitialValues={getPaginatedInitialValues?.bind(null, FilterKeys.role)} />,
        accessor: 'role',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TUserItem;
          };
        }) => <NameCustomField name={data.row.original.role} textColor="#666666" />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <CustomFilter openerWidth={200} dropdownWidth={350} search resizable title="Назва посади"
        // initialValues={initialPaginatedFilters.workerOrgTypes}
        filterParams={filterParamsRef.current} applyFilters={applyFilters} name={FilterKeys.position} getPaginatedInitialValues={getPaginatedInitialValues?.bind(null, FilterKeys.position)} />,
        accessor: 'position',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TUserItem;
          };
        }) => <NameCustomField name={data.row.original.position} />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <CustomFilter openerWidth={200} dropdownWidth={350} search resizable title="Регіон"
        // initialValues={initialPaginatedFilters.workerOrgTypes}
        filterParams={filterParamsRef.current} applyFilters={applyFilters} name={FilterKeys.region} getPaginatedInitialValues={getPaginatedInitialValues?.bind(null, FilterKeys.region)} />,
        accessor: 'region',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TUserItem;
          };
        }) => <NameCustomField name={data.row.original.region} textColor="#666666" />
      },
      // {
      //   // eslint-disable-next-line react/no-unstable-nested-components
      //   Header: () => (
      //     <CustomFilter
      //       openerWidth={200}
      //       dropdownWidth={350}
      //       search
      //       resizable
      //       title="Статус"
      //       // initialValues={initialPaginatedFilters.workerOrgTypes}
      //       filterParams={filterParamsRef.current}
      //       applyFilters={applyFilters}
      //       name={FilterKeys.status}
      //       getPaginatedInitialValues={getPaginatedInitialValues?.bind(
      //         null,
      //         FilterKeys.status,
      //       )}
      //     />
      //   ),
      //   accessor: 'status',
      //   // eslint-disable-next-line react/no-unstable-nested-components
      //   Cell: (data: { row : { original: TUserItem }}) => (
      //     <NameCustomField
      //       name="no status"
      //       textColor="#666666"
      //     />
      //   ),
      // },
      // {
      //   Header: getTranslationByLangOrEng(
      //     interfaceLanguage,
      //     'users_main_roles_button',
      //   ),
      //   accessor: 'role',
      //   // eslint-disable-next-line react/no-unstable-nested-components
      //   Cell: (data: any) => (
      //     <NameCustomField
      //       name={data.row.original.roles
      //         .map((role: { name: any }) => `${role.name}`)
      //         .join(', ')}
      //     />
      //   ),
      // },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <CustomFilter openerWidth={200} dropdownWidth={350} search resizable title="Установа, що опрацювала запит"
        // initialValues={initialPaginatedFilters.workerOrgTypes}
        filterParams={filterParamsRef.current} applyFilters={applyFilters} name={FilterKeys.processedOrgType} getPaginatedInitialValues={getPaginatedInitialValues?.bind(null, FilterKeys.processedOrgType)} />,
        accessor: 'processedOrgType',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TUserItem;
          };
        }) => data.row.original.processedOrgType ? <NameCustomField name={data.row.original.processedOrgType} textColor="#666666" /> : <div />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <CustomFilter openerWidth={200} dropdownWidth={350} search resizable title="Назва установи, що опрацювала запит"
        // initialValues={initialPaginatedFilters.workerOrgTypes}
        filterParams={filterParamsRef.current} applyFilters={applyFilters} name={FilterKeys.processedOrgName} getPaginatedInitialValues={getPaginatedInitialValues?.bind(null, FilterKeys.processedOrgName)} />,
        accessor: 'processedOrgName',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TUserItem;
          };
        }) => data.row.original.processedOrgName ? <NameCustomField name={data.row.original.processedOrgName} textColor="#666666" /> : <div />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <CustomFilter openerWidth={200} dropdownWidth={350} search resizable title="Роль користувача, що опрацював запит"
        // initialValues={initialPaginatedFilters.workerOrgTypes}
        filterParams={filterParamsRef.current} applyFilters={applyFilters} name={FilterKeys.processedRole} getPaginatedInitialValues={getPaginatedInitialValues?.bind(null, FilterKeys.processedRole)} />,
        accessor: 'processedByRole',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TUserItem;
          };
        }) => data.row.original.processedRole ? <NameCustomField name={data.row.original.processedRole} textColor="#666666" /> : <div />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <CustomFilter search resizable title="Опрацьовано"
        // initialValues={initialPaginatedFilters.workerOrgTypes}
        filterParams={filterParamsRef.current} applyFilters={applyFilters} name={FilterKeys.processedName} getPaginatedInitialValues={getPaginatedInitialValues?.bind(null, FilterKeys.processedName)} />,
        accessor: 'processedBy',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TUserItem;
          };
        }) => data.row.original.processedName ? <NameCustomField name={data.row.original.processedName} textColor="#666666" /> : <div />
      }, {
        Header: 'Edit buttons',
        accessor: 'editButtons',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TUserItem;
          };
        }) => <EditButtons id={data.row.original.id} filterItemsAfterDelete={filterItemsAfterDelete} />
      }]);
    }
  }, [users, interfaceLanguage]);
  return <UsersStyles>
      <Helmet>
        <title>Користувачі</title>
      </Helmet>

      <div style={{
      padding: 15
    }}>
        <SubHeader title={`${getTranslationByLangOrEng(interfaceLanguage, 'users_main_title')} (${itemsQuantityRef.current})`}>
          <div className="buttonsContainer">
            <TransparentButton handleClick={() => {
            usersApi.exportUsers(filterParamsRef.current);
          }} text="Експорт" filled />
            {hasAnyAccess('/auth/roles').view && <TransparentButton handleClick={() => navigate('/users/roles')} text={getTranslationByLangOrEng(interfaceLanguage, 'users_main_roles_button')} filled />}
            {hasAnyAccess('/auth/permissions').view && <TransparentButton handleClick={() => navigate('/users/permissions')} text={getTranslationByLangOrEng(interfaceLanguage, 'roles_main_permissions_button')} filled />}
          </div>
        </SubHeader>
        <div className="searchWrapper">
          <div className="search">
            <SearchIcon />
            <input placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search')} type="text" value={search} onChange={e => setSearch(e.target.value)} />
          </div>
        </div>
        {uploading && <Loader />}
        {!!users && !!tableHeaders && !uploading && <Table pagination columns={tableHeaders} data={users} hideFieldsSplitters extraFieldsSettings={extraFieldsSettings}
      // headerColumnCounts={{ name: itemsQuantityRef.current }}
      rowTitle={getTranslationByLangOrEng(interfaceLanguage, 'open_user_title')} hiddenHeaders={['customField', 'editButtons', 'customFormsField']} fullWidthColumns={['editButtons', 'name']} onClickToRow={onClickToRow} pageSize={pageSize} sortableColumns={['id']} setPageSize={setPageSize} currentPage={pageNumber ? +pageNumber : 1} rowsQuantity={itemsQuantityRef.current} pageNumberHandler={page => {
        if (page) {
          navigate(`/users/page/${page}`);
        }
      }} />}
      </div>
      {editModal && selectedUser && <EditUserModal onClose={() => setEditModal(false)} userId={selectedUser} />}
    </UsersStyles>;
};