import React, { FC } from 'react';
import { NameCustomFieldStyles } from './NameCustomFieldStyles';
import { proxyFile } from '../../../utils';
type TNameCustomField = {
  name: string;
  logo?: string;
  textColor?: string;
  onClick?: () => void;
  expandable?: boolean;
};
export const NameCustomField: FC<TNameCustomField> = React.memo(({
  name,
  logo,
  textColor,
  onClick,
  expandable
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const expandField = () => {
    setIsExpanded(prev => !prev);
  };
  return <NameCustomFieldStyles className={`${onClick ? 'onClick' : ''}`} onClick={onClick} aria-label={name}>
        <div style={expandable ? {
      ...(isExpanded ? {
        paddingBottom: 20
      } : {
        height: 30,
        width: 250,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      })
    } : {
      width: 250
    }} className="customNameWrapper">
          {logo && <div className={logo ? 'imageContainer' : 'imageContainer default'}>
            <div className="image">{logo && <img src={proxyFile(logo, true)} alt="logo" />}</div>
          </div>}
          <span style={{
        color: textColor || '#000'
      }}>{name}</span>
          {expandable && name && name?.length && name?.length > 28 && <div onClick={e => {
        expandField();
        e.stopPropagation();
      }} style={{
        position: 'absolute',
        cursor: 'pointer'
      }}>
            <span style={{
          color: 'blue',
          textDecoration: 'underline'
        }}>
              {!isExpanded ? 'більше інформації' : 'меньше'}
            </span>
          </div>}
        </div>
      </NameCustomFieldStyles>;
});