import styled from 'styled-components';

export const AnalyticStyles = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  overflow: hidden;
  position: relative;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;

  h2 {
    width: 80%;
    font-size: var(--fs-2);
    font-weight: 400;
    text-align: center;
    margin: 0 auto;

    &.centered {
      margin: 0 auto;
    }

    @media screen and (max-width: 1500px) {
      font-size: var(--fs-3);
    }
  }

  .analytics {
    width: 100%;

    &-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &-subheader {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
    }

    &-select {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &-btns {
      display: flex;
      align-items: center;
      gap: 15px;
    }

    &-fullscreen,
    &-save,
    &-regime,
    &-filters {
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      svg {
        opacity: 1;
      }

      &:hover {
        svg {
          rect {
            fill: #fff4d7;
          }
          path {
            stroke: #768e9d;
          }
        }
      }

      &.active {
        svg {
          opacity: 1;
        }
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      position: relative;
      transition: all 0.3s ease-in-out;
      gap: var(--md-4);
      overflow-y: auto;
      overflow-x: hidden;
      padding-left: 16px;
      padding-top: 10px;
      box-sizing: border-box;

      @media screen and (max-width: 1500px) {
        gap: var(--sm-7);
      }

      &.active-dashboard {
        background: rgba(255, 255, 255, 0.7);
        padding: var(--md-4);
        overflow: hidden;

        @media screen and (max-width: 1500px) {
          padding: var(--sm-5);
        }

        & .analytics-actions {
          top: 10px;
        }
      }

      &.full-screen {
        background: rgb(255, 255, 255) !important;
        position: fixed;
        max-height: inherit;
        left: 0;
        top: 0;
        height: 100vh !important;
        z-index: 100;
        margin: 0 !important;
        width: 100vw !important;
        padding: 25px;

        @media screen and (max-width: 1500px) {
          padding: 15px;
        }
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      gap: 15px;
      position: absolute;
      right: 25px;
      top: 0;

      @media screen and (max-width: 1500px) {
        right: 15px;
        top: 0;
      }
    }

    &-charts {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      transform-origin: center;

      height: 100%;
      box-sizing: border-box;
      position: relative;
      overflow: auto;
      gap: 20px;
    }
  }

  .dashboard-name {
    font-size: var(--fs-4);
    display: flex;
    flex-direction: column;
    text-align: start;

    small {
      font-size: var(--fs-8);
    }
  }
`;
