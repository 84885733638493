import styled from 'styled-components';

export const AddLanguageStyles = styled.div`
  .uploadError {
    font-size: var(--fs-5);
    line-height: 120%;
    color: #c32900;
  }

  form {
    max-width: 544px;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
  }
`;
