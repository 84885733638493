import { FC, useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { ArrowDownIcon, FaSort, FaSortDown, FaSortUp, TableFilterIcon } from './assets';
import { useDebounce } from './hooks/useDebounce';
import { TOption, TDropdownFilterValues, TCustomFilterProps, Directions } from './types';
import { CustomSelectWithMultipleCheckboxes, TransparentButton, SubmitButton, Loader } from './components';
import { DropdownFilterStyles, FilterSelectInputStyles, resetFilterButtonStyles } from './styles';
import { useClickOutside } from '../../../hooks';
import { initial, isArray, isNumber, isString } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import assert from 'assert-ts';
export const CustomFilter: FC<TCustomFilterProps> = ({
  // filter state props
  filterParams,
  applyFilters,
  additionalResetHandlers,
  // useNamesForFiltering, // use option name as params in filtering request, option id is default
  search,
  // labels
  title,
  name,
  applyFiltersButtonText,
  resetFilterButtonText,
  searchFieldPlaceholder,
  // initial values without pagination
  initialValues,
  // styles
  resizable,
  // dropdown styles
  dropdownWidth,
  openerWidth,
  // pagination props (if initial values presents this values skips)
  getPaginatedInitialValues,
  limit = 10,
  // enable sort
  sortable
}) => {
  assert(!!getPaginatedInitialValues === !initialValues, 'Only one of these props should be passed {getPaginatedInitialValues, initialValues}');
  assert(!getPaginatedInitialValues === !!initialValues, 'Only one of these props should be passed {initialValues, getPaginatedInitialValues}');
  const [isLoading, setIsLoading] = useState(!initialValues?.length);
  const handleChange = () => {};
  const formRef = useRef<FormikProps<TDropdownFilterValues>>(null);
  const filtersRef = useRef<any>();
  const [isFilterOpened, setIsFilterOpened] = useState<boolean>(false);
  const [initialList, setInitialList] = useState<TOption[]>([]);
  const [page, setPage] = useState(1);
  const itemsCountRef = useRef<number>(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [reQuery, setReQuery] = useState(false);
  const handleNext = () => {
    setPage(e => e + 1);
  };
  const debouncedQuery = useDebounce(searchQuery, 500);
  useEffect(() => {
    if (debouncedQuery.length) {
      setReQuery(e => !e);
      setPage(1);
      setInitialList([]);
    }
  }, [debouncedQuery]);
  useEffect(() => {
    if (initialValues) {
      setInitialList(initialValues);
      return;
    }
    if (getPaginatedInitialValues && isFilterOpened && !initialValues && !initialList.length) {
      setIsLoading(true);
      getPaginatedInitialValues(limit, (page ? +page - 1 : 0) * limit, searchQuery).then(e => {
        itemsCountRef.current = e.count;
        setInitialList(prev => [...prev, ...e.items]);
        setIsLoading(false);
      });
    }
  }, [isFilterOpened]);
  useEffect(() => {
    if (getPaginatedInitialValues && isFilterOpened && !initialValues) {
      setIsLoading(true);
      getPaginatedInitialValues(limit, (page ? +page - 1 : 0) * limit, searchQuery).then(e => {
        itemsCountRef.current = e.count;
        setInitialList(prev => [...prev, ...e.items]);
        setIsLoading(false);
      });
    }
  }, [page, reQuery]);
  useClickOutside(filtersRef, () => {
    setIsFilterOpened(false);
  });
  const persistedValues: TDropdownFilterValues = useMemo(() => ({
    [name]: initialList?.map(initialValue => ({
      ...initialValue,
      // persist previos filter params
      value: isArray(filterParams[name]) ? (filterParams[name] as TOption[])?.some((param: TOption) => isString(initialValue) || isNumber(initialValue) ? String(param.id) === String(param) : String(param.id) === String(initialValue?.id)) : initialValue.id === String(filterParams[name])
    }))
  }), [initialList, initialValues, filterParams[name], isFilterOpened]);
  const renderSortingType = (sorting?: 'asc' | 'desc') => {
    switch (sorting) {
      case 'asc':
        return <FaSortUp scale={0.5} width={26} />;
      case 'desc':
        return <FaSortDown scale={0.5} width={26} />;
      default:
        return <FaSort scale={0.5} width={26} />;
    }
  };
  const onSubmit = (values: TDropdownFilterValues, {
    setSubmitting
  }: FormikHelpers<TDropdownFilterValues>) => {
    const filtersToApply = values[name].filter(e => e.value).map(e => ({
      id: e.id,
      name: e.name
    }));
    applyFilters({
      [name]: filtersToApply
    });
    setIsFilterOpened(false);
  };
  const applySorting = () => {
    const index = Directions.findIndex(e => e === filterParams[`${name}_sorting`]);
    applyFilters({
      [`${name}_sorting`]: Directions[index + 1]
    });
  };

  // const shouldRenderIcon = true;

  const additionalResetHandler = () => {
    if (filterParams[name]) {
      delete filterParams[name];
    }
    applyFilters({
      ...filterParams
    });
    setReQuery(e => !e);
  };
  const renderForm = ({
    values,
    errors,
    setFieldValue,
    handleChange,
    resetForm
  }: FormikProps<TDropdownFilterValues>) => <Form>
      <SubmitButton extraButtonStyles={{
      width: '100%',
      height: 32,
      fontSize: 16,
      fontWeight: 500
    }}>
        {applyFiltersButtonText ?? 'Застосувати'}
      </SubmitButton>
      <TransparentButton handleClick={() => {
      resetForm();
      // additionalResetHandlers?.();
      additionalResetHandler();
      setIsFilterOpened(false);
    }} text={resetFilterButtonText ?? 'Скинути'} extraStyles={resetFilterButtonStyles} extraButtonTextStyles={{
      color: '#ffffff',
      fontSize: 16,
      fontWeight: 500
    }} />
      <CustomSelectWithMultipleCheckboxes isOffliteSearch={!getPaginatedInitialValues && !!initialValues?.length} searchQuery={searchQuery} setSearchQuery={setSearchQuery} search={search} name={name} handleChange={handleChange} options={values[name] || []}
    // extraOneOptionStyles={extraOneOptionStyles}
    optionsContainerStyles={{
      width: '100%'
    }} placeholder="Filter"
    // placeholder={translations[interfaceLanguage].select_multiple_Scores}
    placeholderStyles={{
      color: '#6c6c6c'
    }} oneOptionValueStyles={{
      marginLeft: '4px'
    }} isLoading={false} alwaysOpened />
      {isLoading && <Loader margin={20} />}
    </Form>;
  return <FilterSelectInputStyles ref={filtersRef}>
      <div style={{
      width: openerWidth
    }} className="filterRow">
        <div
      //   style={filtersOpenerStyles}
      className={`filtersOpener ${isFilterOpened ? 'active' : ''}`} onClick={() => setIsFilterOpened(!isFilterOpened)}>
          <span className="filterTitle">
            {`${title} ${filterParams[name]?.length ? `(${filterParams[name]?.length})` : ''}`}
          </span>
          <ArrowDownIcon />
        </div>
        {/* {shouldRenderIcon && <TableFilterIcon width={15} height={15} />} */}
      </div>
      {sortable && <div onClick={applySorting} className="sortContainer">
          {renderSortingType(filterParams[`${name}_sorting`] as 'asc' | 'desc' | undefined)}
        </div>}
      {isFilterOpened && <DropdownFilterStyles width={dropdownWidth} id="scrollable" style={resizable ? {
      overflow: 'auto',
      resize: 'both'
    } : {}}>
          <InfiniteScroll scrollableTarget="scrollable" next={handleNext} hasMore={itemsCountRef?.current > initialList.length} loader={isLoading} dataLength={initialList.length}>
            <Formik enableReinitialize innerRef={formRef} initialValues={persistedValues} onSubmit={onSubmit}>
              {renderForm}
            </Formik>
          </InfiniteScroll>
        </DropdownFilterStyles>}
    </FilterSelectInputStyles>;
};