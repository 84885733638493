import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { FC } from 'react';
import { TIcon } from './TIcon';
const FaDownload_1 = _compiledBlock(_props => <svg xmlns="http://www.w3.org/2000/svg" width={_props.v0} height={_props.v1} viewBox="0 0 512 512">
    <path fill={_props.v2} d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32v242.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64h384c35.3 0 64-28.7 64-64v-32c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352zm368 56a24 24 0 1 1 0 48a24 24 0 1 1 0-48" />
  </svg>, {
  name: "FaDownload_1",
  svg: true
});
export const FaDownload: FC<TIcon> = ({
  color,
  width,
  height
}) => /*@million jsx-skip*/<FaDownload_1 v0={width} v1={height} v2={color} />;