import styled from 'styled-components';
export const SentimentFilterStyles = styled.div`
  font-weight: 400;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  background: #fff;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  z-index: 200;
  width: 270px;
  padding: var(--sm-4);
  border-radius: 4px;

  @media screen and (max-width: 1500px) {
    position: fixed;
    z-index: 99;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .list-wrapper {
    display: flex;
    margin-top: 15px;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(229, 229, 229);
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    overflow-y: auto;
    max-height: 200px;

    .list-active {
      background-color: rgb(240, 245, 245);
    }

    .list-item {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      gap: 10px;
      box-sizing: border-box;
      padding-left: 6px;
      cursor: pointer;

      g {
        height: 20px;
      }

      span {
        line-height: 120%;
        font-size: var(--fs-5);
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgb(196, 196, 196);
      }
    }
  }
`;