import styled from 'styled-components';

export const CalendarFilterStyles = styled.div`
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: var(--sm-4);
  position: absolute;
  display: flex;
  top: 100%;
  left: 100%;
  gap: var(--sm-6);
  transform: translate(-50%);
  background: #fff;
  flex-direction: column;
  border-radius: 5px;
  z-index: 99;
  border: 1px solid rgba(12, 0, 5, 0.1);
  background: #eef3f7;
  box-sizing: border-box;
  width: 530px;

  @media screen and (max-width: 1500px) {
    position: fixed;
    z-index: 99;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  * {
    box-sizing: border-box !important;
  }

  .calendars {
    display: flex;
    flex-direction: row;
    gap: var(--sm-6);

    .calendar {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 50%;
    }
  }

  .react-calendar {
    width: 100%;
    border: none;
    padding: var(--sm-6);
    background: #ffffff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--sm-6);
  }

  .react-calendar__month-view__weekdays,
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none !important;
  }

  .react-calendar__navigation button:disabled {
    background: #fff;
  }

  .react-calendar__navigation button {
    width: 24px;
    height: 24px;
    min-width: inherit;
  }

  .react-calendar__navigation {
    border-bottom: 1px solid #dee6eb;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0;
    height: auto;
    padding-bottom: var(--sm-6);
  }

  .react-calendar__tile {
    width: var(--md-1);
    height: var(--md-1);
    padding: 0;
    font-weight: 400;
    font-size: var(--fs-3);
    line-height: 120%;
    color: #0c0005 !important;

    &.active {
      background: linear-gradient(0deg, #fff4d7, #fff4d7), #ffffff;
    }
  }

  .from .react-calendar__tile--active {
    background: linear-gradient(0deg, #fff4d7, #fff4d7), #ffffff;
    border-radius: 1000px 0px 0px 1000px;
  }

  .to .react-calendar__tile--active {
    background: linear-gradient(0deg, #fff4d7, #fff4d7), #ffffff;
    border-radius: 0px 1000px 1000px 0px;
    color: #6EAE93 !important;
  }

  .react-calendar__tile--now {
    background: #fff;
  }

  .react-calendar__tile:disabled {
    background: #fff;
    opacity: 0.3;
  }

  .templates {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    gap: var(--sm-6);

    button {
      box-sizing: border-box;
      transition: all 0.3s ease 0s;
      width: 30%;
      padding: var(--sm-8) var(--sm-2);
      background: #ffffff;
      border-radius: 5px;
      font-weight: 400;
      font-size: var(--fs-5);
      color: #0c0005;
      cursor: pointer;

      &:hover {
        background: #fff4d7;
      }

      &:active, &:disabled {
        background: #dbe2e7;
      }
    }
  }

  .buttons {
    display: flex;
    width: 100%;
    gap: var(--sm-6);

    button {
      box-sizing: border-box;
      padding: var(--sm-7) var(--sm-2);
      background: #ffffff;
      border: 1px solid #0c0005;
      border-radius: 1000px;
      font-weight: 400;
      font-size: var(--fs-5);
      line-height: 120%;
      color: #0c0005;
      width: 100%;
      cursor: pointer;

      &:hover {
        background: #fff4d7;
      }

      &:active {
        background: #dbe2e7;
      }

      &.submitButton {
        background: #000;
        color: #fff;

        &:hover {
          color: #000;
          border-color: transparent;
          background: radial-gradient(
            114.08% 574.56% at 114.08% 50%,
            #b7e884 0%,
            #dda9d1 49.27%,
            #66b1ff 100%
          );
        }

        &:active {
          color: #000;
          border-color: transparent;
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0.2)
            ),
            radial-gradient(
              114.08% 574.56% at 114.08% 50%,
              #b7e884 0%,
              #dda9d1 49.27%,
              #66b1ff 100%
            );
        }
      }
    }
  }
`;
