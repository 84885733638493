import styled from 'styled-components';

export const SmsStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 100%;
  width: 800px;

  @media screen and (max-width: 768px) {
    width: 360px;
  }

  @media screen and (max-width: 991px) {
    width: 600px;
  }

  @media screen and (max-width: 450px) {
    width: 360px;
  }

  form {
    position: relative;
    overflow: auto;
    max-height: 600px;
  }

  .mass {
    &-wrapper {
      max-width: 800px;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }

      & > div {
        position: relative;
      }
    }
  }
  .fileUpload {
    padding: 10px;
    background: '#FAFAFA';
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 9px;
    }
  }

  .table-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    max-height: 350px;
    overflow: auto;
  }

  .template {
    width: 100%;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid rgb(229, 229, 229);
    font-size: var(--fs-3);
    background-color: rgb(250, 250, 250);

    span {
      color: #e41212;
      font-weight: 900;
    }
  }

  .extraErrorMessage {
    font-size: var(--fs-5);
    line-height: 120%;
    color: #c32900;
  }
  .extraErrorMessage {
    font-size: var(--fs-5);
    line-height: 120%;
    color: #c32900;
  }
  .extraWarningMessage {
    font-size: var(--fs-5);
    line-height: 120%;
    color: orange;
  }
`;
