import styled from 'styled-components';

export const AnswersFilterStyles = styled.div`
  #scrollable {
    font-weight: 400;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    background: #fff;
    position: absolute;
    left: 0px;
    z-index: 200;
    width: 400px;
    max-height: 450px;
    padding: var(--sm-4);
    border-radius: 4px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .infinite-scroll-component__outerdiv {
      height: 100%;
    }
  }
`;
