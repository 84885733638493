import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { FC, useEffect, useRef, useState } from 'react';
import { ApiContacts, rolesApi, usersApi, userContactsApi, authApi } from '../../../api';
import { IUserRole } from '../../../entities';
import { getTranslationByLangOrEng } from '../../../i18n';
import { updateRolesList, validationUserSchema } from '../../../pages/addUser/AddUserUtils';
import { useAppSelector } from '../../../state';
import { getErrorMessage, handleKeyUp } from '../../../utils';
import { Loader } from '../../atoms';
import SubmitButton from '../../form/submitButton/SubmitButton';
import { RoleSelect, InputField } from '../../molecules';
import { MultiTenancy } from '../multiTenancy';
import { EditUserStyles } from './EditUserStyles';
import { availablePageSizes } from '../../../constants';
import { difference, intersection, debounce } from 'lodash';
import { accessesApi } from '../../../api/accessesApi/Accesses';
import { useDebounce, usePermissionView } from '../../../hooks';
export function deduplicateArray(array: any[]) {
  const map = new Map();
  array.forEach(item => {
    if (!map.has(item.id)) {
      map.set(item.id, item);
    }
  });
  return Array.from(map.values());
}
type TEditUser = {
  id: number;
  onClose: () => void;
};
interface FormValues {
  PIB: string;
  INN: string;
  email: string;
  phone: string;
  position: string;
  roles: number[];
}
const formInitialValues: FormValues = {
  PIB: '',
  INN: '',
  email: '',
  phone: '+380',
  position: '',
  roles: []
};
export const EditUser: FC<TEditUser> = React.memo(({
  id,
  onClose
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const hasAnyAccess = usePermissionView();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [reMultiQuery, setReMultiQuery] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [initialValues, setInitialValues] = useState<FormValues>(formInitialValues);
  const [roleList, setRoleList] = useState<IUserRole[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const [contacts, setContacts] = useState<ApiContacts[]>([]);
  const debouncedInput = useDebounce(searchQuery, 1000);
  const pageSizeRef = useRef<number>(availablePageSizes[2]);
  const fetchUserData = async () => {
    setIsLoading(true);
    try {
      const userData = await usersApi.getCrmUser(id);
      const roles = await rolesApi.getRoles(0, 100, '', [id]);
      const res = await userContactsApi.getUserContacts(+id);
      if (userData.statusCode >= 200 && userData.statusCode < 300 && roles.statusCode >= 200 && roles.statusCode < 300 && res.statusCode >= 200 && res.statusCode < 300) {
        setInitialValues({
          PIB: userData.data.name,
          INN: userData.data.drfo,
          email: userData.data.email,
          phone: res.data.find(e => e.phone)?.phone ?? '',
          position: userData.data.position,
          roles: roles.data.map(role => role.id!)
        });
        setContacts(res.data);
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchUserData();
  }, [reQuery, id]);
  async function onSubmit(values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) {
    setIsLoading(true);
    const rolesToAttach = difference(values.roles, initialValues.roles);
    const rolesToDettach = difference(initialValues.roles, values.roles);
    try {
      const editUserRequest = usersApi.editCrmUser(+id, {
        name: values.PIB,
        email: values.email,
        position: values.position,
        phone: `${values.phone}`
      });
      const editPositionRequest = accessesApi.sendPosition({
        userID: +id,
        position: values.position
      });
      const updateRolesRequest = updateRolesList(rolesToAttach, rolesToDettach, id);
      const response = await Promise.all([editUserRequest, editPositionRequest, updateRolesRequest]);
      const res = response[0];
      if (res.statusCode >= 200 && res.statusCode < 300) {
        setErrorMessage('');
        onClose();
        setReQuery(!reQuery);
      } else {
        setErrorMessage(`${res.statusCode} помилкове тіло запиту`);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage('Помилкове тіло запиту');
    }
    setSubmitting(false);
  }
  const renderForm = ({
    values,
    errors,
    setFieldValue
  }: FormikProps<FormValues>) => <Form>
      <div className="formSection">
        <h3>Загальна інформація</h3>
        <div className="inputsCol">
          <InputField disabled name="PIB" onChange={() => {}} onKeyUp={() => handleKeyUp('PIB', setErrorMessage, errorMessage)} placeholder="ПІБ" autocomplete value={values.PIB} />
          <InputField name="email" onChange={setFieldValue} onKeyUp={() => handleKeyUp('email', setErrorMessage, errorMessage)} placeholder="Електронна пошта" autocomplete value={values.email} error={typeof errorMessage === 'object' ? getErrorMessage('email', errorMessage) : undefined} />
          <InputField type="tel" name="phone" onChange={setFieldValue} onKeyUp={() => handleKeyUp('email', setErrorMessage, errorMessage)} placeholder="Номер телефону" autocomplete value={values.phone} error={typeof errorMessage === 'object' ? getErrorMessage('email', errorMessage) : undefined} />
          <InputField type="text" name="position" onChange={setFieldValue} onKeyUp={() => handleKeyUp('email', setErrorMessage, errorMessage)} placeholder="Посада" autocomplete value={values.position} error={typeof errorMessage === 'object' ? getErrorMessage('email', errorMessage) : undefined} />
        </div>
        <div className="row">
          <a className="mail-link" href={`mailto:${initialValues.email}`}>
            Написати листа
          </a>
        </div>
        {hasAnyAccess('/auth/users/*/roles').view && <div className="row">
            <RoleSelect setSearchQuery={setSearchQuery} label={getTranslationByLangOrEng(interfaceLanguage, 'roles_main_title')} name="roles" required userRoles={values.roles} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'user_role_placeholder')} value="" search autoloadable autoloading={uploading} handleSelect={option => {
          const selectedRole = values.roles.includes(option.id as number);
          setFieldValue('roles', selectedRole ? values.roles.filter(role => role !== option.id) : [...values.roles, option.id]);
        }} error={errors.roles as string} />
          </div>}
        {hasAnyAccess('users/user/view').view && <>
            <div className="horizontalLine" />
            <h3>Налаштування доступів</h3>
            <MultiTenancy id={id} reQuery={reMultiQuery} setReQuery={setReMultiQuery} />
          </>}
        {/* {!!contacts.length && (
          <>
            <div className="horizontalLine" />
            <Notifications setContacts={setContacts} contacts={contacts} />
          </>
         )} */}
        {/* <div className="horizontalLine" />
         <NotifiableSource
          id={id}
          reQuery={reMultiQuery}
          setReQuery={setReMultiQuery}
         /> */}
        {typeof errorMessage === 'string' && <p className="extraErrorMessage">{errorMessage}</p>}
        <SubmitButton extraBlockStyles={{
        maxWidth: 600,
        width: '100%'
      }}>
          {getTranslationByLangOrEng(interfaceLanguage, 'save_button')}
        </SubmitButton>
      </div>
    </Form>;
  return <EditUserStyles>
      {isLoading || !initialValues ? <Loader /> : <Formik validateOnChange key={JSON.stringify(initialValues)} initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationUserSchema(interfaceLanguage, 1)}>
          {renderForm}
        </Formik>}
    </EditUserStyles>;
});