import styled from 'styled-components';

export const DynamicDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
  height: 100%;
  /* overflow: hidden; */

  .charts {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--sm-1);

    &-title {
      position: relative;
      width: 25px;
      flex-shrink: 0;

      h3 {
        width: 100%;
        font-weight: 400;
        font-size: var(--fs-4);
        width: 100%;
        height: 25px;
        white-space: nowrap;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: rotate(-90deg) translate(50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-item {
      width: calc(100% - 20px);
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      gap: 10px;
      border: 1px solid rgb(222, 230, 235);
      border-radius: 10px;
      padding: 20px 10px;
      box-sizing: border-box;
    }
  }
`;
