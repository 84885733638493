import React, { Dispatch, FC, SetStateAction, useEffect, RefObject, useState } from 'react';
import { AnalyticsFiltersStyles } from './styles';
import { Checkbox, FilterSelect, QuarterFilter, YearMonths } from '../../molecules';
import { analyticsApi, ApiAnalyticFilter, ApiPostAnalyticFilter, sourcesApi } from '../../../api';
import { JustPlusIcon } from '../../../assets';
import { TransparentButton } from '../../atoms';
import { ISource } from '../../../entities';
import { useAppSelector } from '../../../state';
type TAnalyticsFilters = {
  companyId: number;
  filterParams: RefObject<{
    [key: string]: number | string | string[] | number[] | boolean;
  }>;
  setReQuery: Dispatch<SetStateAction<boolean>>;
  setFilters: Dispatch<SetStateAction<boolean>>;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  reQuery: boolean;
  filters: boolean;
  selectedTab: string;
  handleRefreshFilter: () => void;
};
const initialFilter: ApiAnalyticFilter = {
  cnapTypes: [],
  idfs: [],
  organTypes: [],
  regions: [],
  relatedByOrgans: []
};
const quarterDate: string[] = ['ratingPopulationOne', 'ratingPopulationTwo', 'ratingPopulationThree', 'ratingPopulationFour', 'ratingPopulationFive', 'ratingPopulationSix', 'complexRating', 'locationOutOfRating', 'complexGeneral'];
export const AnalyticsFilters: FC<TAnalyticsFilters> = ({
  companyId,
  filterParams,
  setReQuery,
  reQuery,
  selectedTab,
  filters,
  setFilters,
  setSelectedTab,
  handleRefreshFilter
}) => {
  const {
    data: userData
  } = useAppSelector(state => state.user);
  const [data, setData] = useState<ApiAnalyticFilter>(initialFilter);
  const [sources, setSources] = useState<ISource[]>([]);
  const [first, setFirst] = useState<boolean>(true);
  const handleClose = () => {
    setFilters(false);
  };
  useEffect(() => {
    if (filterParams.current) {
      const {
        companyID,
        regions,
        relatedByOrgans,
        ...restFilterParams
      } = filterParams.current;
      const filteredData: ApiPostAnalyticFilter = {
        regions: regions as number[],
        relatedByOrgans: relatedByOrgans as number[]
      };
      analyticsApi.analyticsFilters(companyID as number, filteredData, !userData)
      // .analyticsFilters(companyID as number, filteredData)
      .then(res => {
        if (res.statusCode === 200) {
          setData(res.data);
        }
      });
      if (companyID) {
        sourcesApi.getSourcesByCompanyId(+companyID, !userData).then(res => {
          setSources(res.data.filter(source => source.id !== 66));
          if (filterParams?.current && first) {
            filterParams.current.sourceIDs = res.data.filter(source => source.id !== 66).map(el => el.id);
            setFirst(false);
            setReQuery(!reQuery);
          }
        });
      }
    }
  }, [reQuery, first]);
  return <AnalyticsFiltersStyles filters={filters}>
      <div role="button" className="close-filters" onClick={handleClose} title="Закрити фільтри" aria-label="Закрити фільтри"
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    tabIndex={0} onKeyDown={e => {
      if (e.key === 'Enter') {
        handleClose();
      }
    }}>
        <JustPlusIcon color="#999" width={25} height={25} />
      </div>
      <TransparentButton text="Очистити фільтри" handleClick={handleRefreshFilter} extraStyles={{
      backgroundColor: '#767676',
      border: 'none'
    }} extraButtonTextStyles={{
      color: '#ffffff'
    }} />
      <Checkbox name="isDiia" value={!!filterParams.current?.isDiia} onChange={e => {
      if (filterParams.current) {
        filterParams.current.isDiia = !filterParams.current.isDiia;
        setReQuery(!reQuery);
      }
    }}>
        <span className="checkboxValue">Дія Центри</span>
      </Checkbox>
      <div className="filter-wrapper">
        {quarterDate.includes(selectedTab) ? <QuarterFilter filterParams={filterParams} reQuery={reQuery} setReQuery={setReQuery} /> : <YearMonths filterParams={filterParams} reQuery={reQuery} setReQuery={setReQuery} />}
        {selectedTab !== '' && <>
            <FilterSelect options={sources || []} accessor="sourceIDs" filterParams={filterParams} reQuery={reQuery} valueField="id" labelField="name" setReQuery={setReQuery} label="Канал комунікації" search />
            <FilterSelect options={data?.regions || []} accessor="regions" filterParams={filterParams} reQuery={reQuery} valueField="id" labelField="name" setReQuery={setReQuery} label="Регіон" search />
            <FilterSelect options={data.organTypes ? data.organTypes.map(el => el.name) : []} accessor="organTypes" filterParams={filterParams} reQuery={reQuery} setReQuery={setReQuery} label="Тип органу, що утворив центр" search />
            <FilterSelect options={data?.relatedByOrgans || []} accessor="relatedByOrgans" filterParams={filterParams} reQuery={reQuery} valueField="id" labelField="name" setReQuery={setReQuery} label="Назва органу, що утворив центр" search />
            <FilterSelect options={data.cnapTypes ? data?.cnapTypes.map(el => el.name) : []} accessor="cnapTypes" filterParams={filterParams} reQuery={reQuery} setReQuery={setReQuery} label="Тип центру" />
            <FilterSelect options={data?.idfs || []} accessor="idfs" filterParams={filterParams} reQuery={reQuery} setReQuery={setReQuery} label="IDF" search />
          </>}
      </div>
    </AnalyticsFiltersStyles>;
};