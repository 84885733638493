import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { ScoreFilterStyles } from './ScoreFilterStyles';
import { translations } from '../../../../i18n';
import { useAppSelector } from '../../../../state';
import CustomSelectWithMultipleCheckboxes from '../../../form/customSelect/CustomSelectWithMultipleCheckboxes';
import SubmitButton from '../../../form/submitButton/SubmitButton';
import { extraOneOptionStyles, resetFilterButtonStyles } from '../reviewsTableFiltersUtils';
import { Loader, TransparentButton } from '../../../atoms';
import { TScore, TScoreFilter, TScoreFilterValues } from './TScoreFilter';
// todo possible to get initial score from parent (0-5 or 0-10)
const initialValues: TScoreFilterValues = {
  scores: new Array(11).fill(1).map((item, index) => ({
    value: false,
    name: index.toString()
  }))
};
export const ScoreFilter = React.memo(({
  initialValues,
  filterParams,
  setReQuery,
  setApply,
  setFilterParams,
  onClose
}: TScoreFilter) => {
  // useMemo
  const persistedValues = useMemo(() => ({
    ...initialValues,
    scores: initialValues?.scores?.map(score => ({
      ...score,
      // persist previos filter params
      value: !!filterParams?.scores?.includes(+score.name)
    }))
  }), []);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const ref = useRef<any>();
  const formRef = useRef<FormikProps<TScoreFilterValues>>(null);
  async function onSubmit(values: TScoreFilterValues, {
    setSubmitting
  }: FormikHelpers<TScoreFilterValues>) {
    const params: {
      [key: string]: any;
    } = {};
    const checkedScores: Array<number> = values?.scores?.filter(item => item.value).map(e => +e.name) ?? [];
    params.scores = checkedScores;
    const resultParams: {
      [key: string]: any;
    } = {};
    Object.entries({
      ...filterParams,
      ...params
    }).forEach(item => {
      if (!!item[0] && item[1] !== undefined && item[1] !== null && item[1] !== '') {
        resultParams[item[0]] = item[1];
      }
    });
    setFilterParams(resultParams);
    setApply(resultParams);
    setReQuery();
    setSubmitting(false);
  }
  const additionalResetHandlers = () => {
    if (filterParams.scores) {
      delete filterParams.scores;
    }
    setFilterParams({
      ...filterParams
    });
    setApply({
      ...filterParams
    });
    setReQuery();
    onClose();
  };
  const renderForm = ({
    values,
    errors,
    setFieldValue,
    handleChange,
    resetForm
  }: FormikProps<TScoreFilterValues>) => <Form>
        <div>
          <SubmitButton extraButtonStyles={{
        width: '100%',
        maxWidth: '500px',
        height: 32,
        fontSize: 'var(--fs-5)',
        fontWeight: 500
      }}>
            {translations[interfaceLanguage].apply_filters_button}
          </SubmitButton>
          <TransparentButton handleClick={() => {
        resetForm();
        additionalResetHandlers();
      }} text={translations[interfaceLanguage].reset_reviews_filter} extraStyles={resetFilterButtonStyles} extraButtonTextStyles={{
        color: '#ffffff',
        fontSize: 'var(--fs-5)',
        fontWeight: 500
      }} />
          <CustomSelectWithMultipleCheckboxes name="scores" handleChange={handleChange} options={values.scores || []} extraOneOptionStyles={extraOneOptionStyles} optionsContainerStyles={{
        width: '100%'
      }} placeholder={translations[interfaceLanguage].select_multiple_Scores} placeholderStyles={{
        color: '#666666'
      }} oneOptionValueStyles={{
        marginLeft: '4px'
      }} isLoading={false} alwaysOpened />
        </div>
      </Form>;
  return <ScoreFilterStyles ref={ref}>
        {initialValues ? <Formik innerRef={formRef} initialValues={persistedValues} onSubmit={onSubmit}>
            {renderForm}
          </Formik> : <Loader margin={0} />}
      </ScoreFilterStyles>;
});