import { AcessRequestStyles, DropDownContainer, SearchContainer, StyledSelect } from './AcessRequestStyles';
import { getTranslationByLangOrEng } from '../../i18n';
import React, { useEffect, useRef, useState } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { ApiResponse, companyApi, formApi, rolesApi, usersApi } from '../../api';
import { availablePageSizes, hiddenReviewFilterStatuses, reviewsStatusIconsAndText } from '../../constants';
import { useAppSelector } from '../../state';
import { Helmet } from 'react-helmet-async';
import { accessesApi, AccessRequest } from '../../api/accessesApi/Accesses';
import format from 'date-fns/format';
import { CompanyFilter, CustomSelect, FilterSelectInput, Loader, SubHeader, Table, ToggleSwitcher, TransparentButton } from '../../components';
import { CompaniesMainInfo, ICompanyUser, INode, IOption, IReviewStatus, IUser } from '../../entities';
import { DropdownFilter } from '../../components/molecules/dropDownFilter';
import { FilterSelectInputStyles } from '../../components/molecules/reviewsTableFilters/filterSelectInput/FilterSelectInputStyles';
import { ArrowDownIcon, BsSortNumericDown, SearchIcon, TableFilterIcon } from '../../assets';
import { useClickOutside, useDebounce } from '../../hooks';
import { proxyFile } from '../../utils';
import { debounce } from 'lodash';
import SubmitButton from '../../components/form/submitButton/SubmitButton';
import { TOption } from '../../components/molecules/reviewsTableFilters/filterSelectInput/initialValues';
import { TAccessRequest, TAccessRequestFilters, TAccessRequestResponse } from '../../api/accessesApi/IAccessesApi';
import { CustomFilter } from '../../components/molecules/customFilter';
import { FilterKeys, usePaginatedFilters } from './hooks/usePaginatedFilters';
import { filterAdapter } from './utils/filterAdapter';
const sortingVariants = ['asc', 'desc'];
export const initialServicesTypeValues = [{
  id: 'pending',
  value: false,
  name: 'Очікує'
}, {
  id: 'approved',
  value: false,
  name: 'Підтверджено'
}, {
  id: 'rejected',
  value: false,
  name: 'Відхилено'
}];
const statuses: {
  [key: string]: string;
} = {
  pending: 'Очікує',
  approved: 'Підтверджено',
  rejected: 'Відхилено'
};
const statusesColors: {
  [key: string]: string;
} = {
  pending: '#666666',
  approved: '#666666',
  rejected: '#666666'
};
function NameCustomField({
  name,
  logo,
  textColor
}: {
  name: string;
  logo?: string;
  textColor?: string;
}) {
  return <div style={{
    marginTop: 7,
    marginBottom: 7
  }} className="customNameWrapper">
      {logo && <div className={logo ? 'imageContainer' : 'imageContainer default'}>
          <div className="image">
            {logo && <img src={proxyFile(logo, true)} alt="logo" />}
          </div>
        </div>}
      <span style={{
      color: textColor || '#000'
    }}>{name}</span>
    </div>;
}
export enum DropDownKeys {
  workerName = 'workerName',
  processedByName = 'processedByName',
}
export type TDropDownValue = {
  name: DropDownKeys;
  value: boolean;
  label: string;
  placeholder: string;
};
export const options: Array<TDropDownValue> = [{
  name: DropDownKeys.workerName,
  label: 'ПІБ заявника',
  value: true,
  placeholder: 'Пошук за Ім\'ям працівника'
}, {
  name: DropDownKeys.processedByName,
  label: 'Опрацювано',
  value: true,
  placeholder: 'Пошук за Ім\'ям'
}];
export const AccessRequests = () => {
  const {
    pageNumber
  } = useParams();
  const navigate = useNavigate();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [uploading, setUploading] = useState<boolean>(false);
  const [reQuery, setReQuery] = useState<boolean>(false);
  const isManager = true;
  const [isFilterOpened, setIsFilterOpened] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [companies, setCompanies] = useState<{
    [key: string]: CompaniesMainInfo;
  }>();
  const filtersRef = useRef<any>();
  const shouldBeCanceled = useRef<boolean>(false);
  const accessesRef = useRef<TAccessRequest[]>();
  const itemsQuantityRef = useRef<number>(0);
  const pageSizeRef = useRef<number>(availablePageSizes[1]);
  const filterParamsRef = useRef<{
    [key: string]: TOption[];
  }>({});
  const [selectedType, setSelectedType] = useState<TDropDownValue>(options[0]);
  const debouncedInput = useDebounce(searchQuery, 1000);
  useClickOutside(filtersRef, () => setIsFilterOpened(false));
  function workWithResponse(response: ApiResponse<TAccessRequest[]>) {
    if (response.statusCode >= 200 && response.statusCode < 300) {
      const newItems = response.data;
      if (!shouldBeCanceled.current) {
        accessesRef.current = [...(Array.isArray(newItems) ? newItems : [])];
        itemsQuantityRef.current = response.count!;
        setUploading(false);
      }
    }
  }
  const getCompanies = (data: CompaniesMainInfo[]) => {
    const apiCompanies: {
      [key: string]: CompaniesMainInfo;
    } = data.reduce((acc, item) => {
      // @ts-ignore
      acc[`${item.id}`] = item;
      return acc;
    }, {});
    if (Object.keys(apiCompanies).length > 1) {
      setCompanies(apiCompanies);
    } else {
      const compID = Object.values(apiCompanies)[0].id;
      // @ts-ignore
      filterParamsRef.current = {
        companyID: compID
      };
      navigate('/access_requests/manage/page/1');
      setReQuery(!reQuery);
    }
  };
  useEffect(() => {
    companyApi.getCompanies().then(res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        getCompanies(res.data);
      }
    });
  }, []);
  function findNodeNames(ids: number[], tree: INode[]): string[] {
    const idSet = new Set(ids);
    const result: string[] = [];
    function traverse(nodes: INode[]) {
      for (const node of nodes) {
        if (idSet.has(node.id)) {
          result.push(node.name);
        }
        if (node.subNodes) {
          traverse(node.subNodes);
        }
      }
    }
    traverse(tree);
    return result;
  }
  const fetchData = async () => {
    setUploading(true);
    accessesRef.current = undefined;
    // @ts-ignore
    const nodes = await formApi.getCompanyNodes(filterParamsRef.current.companyID);
    // @ts-ignore
    const nodenames = findNodeNames(filterParamsRef.current?.nodes as number[], nodes.data);
    // @ts-ignore
    const nodenames2 = findNodeNames(filterParamsRef.current?.nodes2 as number[], nodes.data);
    const accessesFull = await accessesApi.accessReviewListFull(filterAdapter(pageSizeRef.current, (pageNumber ? +pageNumber - 1 : 0) * pageSizeRef.current, {
      ...filterParamsRef.current,
      workerOrgNames: nodenames2,
      workerFrontOffices: nodenames,
      ...(selectedType.name === DropDownKeys.workerName && {
        workerName: debouncedInput
      }),
      ...(selectedType.name === DropDownKeys.processedByName && {
        processedName: debouncedInput
      })
    }));
    workWithResponse({
      statusCode: accessesFull.statusCode,
      count: accessesFull.data.count,
      data: accessesFull.data.requests
    });
  };
  const [isExporting, setIsExporting] = useState(false);
  const exportTable = async () => {
    if (filterParamsRef.current.companyID) {
      setIsExporting(true);
      // @ts-ignore
      const nodes = await formApi.getCompanyNodes(filterParamsRef.current.companyID);
      // @ts-ignore
      const nodenames = findNodeNames(filterParamsRef.current?.nodes as number[], nodes.data);
      // @ts-ignore
      const nodenames2 = findNodeNames(filterParamsRef.current?.nodes2 as number[], nodes.data);
      await accessesApi.exportAccessRequests(filterAdapter(pageSizeRef.current, (pageNumber ? +pageNumber - 1 : 0) * pageSizeRef.current, {
        ...filterParamsRef.current,
        workerOrgNames: nodenames2,
        workerFrontOffices: nodenames,
        ...(selectedType.name === DropDownKeys.workerName && {
          workerName: debouncedInput
        }),
        ...(selectedType.name === DropDownKeys.processedByName && {
          processedByName: debouncedInput
        })
      }));
      setIsExporting(false);
    }
  };
  useEffect(() => {
    if (!uploading && filterParamsRef.current.companyID) {
      fetchData();
    }
  }, [pageNumber, reQuery, isManager, filterParamsRef.current]);
  const applyFilters = (params: any) => {
    filterParamsRef.current = {
      ...filterParamsRef.current,
      ...params
    };
    setReQuery(e => !e);
  };
  useEffect(() => {
    setReQuery(e => !e);
  }, [debouncedInput]);
  const setFilterParams = (params: any) => {
    filterParamsRef.current = params;
    setIsFilterOpened(false);
  };
  const approveRequest = async (id: number) => {
    const res = await accessesApi.approveAccessRequest(id);
    setReQuery(e => !e);
  };
  const rejectRequest = async (id: number) => {
    const res = await accessesApi.rejectAccessRequest(id);
    setReQuery(e => !e);
  };
  const reviewStatuses = Object.values(reviewsStatusIconsAndText(interfaceLanguage))?.filter(item => !hiddenReviewFilterStatuses.includes(item?.value));
  const {
    getPaginatedInitialValues
  } = usePaginatedFilters();
  useEffect(() => {
    if (accessesRef.current) {
      setTableHeaders([{
        Header: 'ID запиту',
        accessor: 'id',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.id} textColor="#666666" />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: <FilterSelectInput sortable filtersOpenerStyles={{
          minWidth: 120
        }} columnName="createdAt" type="dataInterval" title="Дата запиту" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'createdAt',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TAccessRequest;
          };
        }) => <NameCustomField name={format(new Date(data.row.original.createdAt), 'dd.MM.yyyy HH:mm')} textColor="#666666" />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: 'ПІБ заявника',
        accessor: 'workerName',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.worker.name} textColor="#666666" />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <CustomFilter search resizable title="Тип установи користувача"
        // initialValues={initialPaginatedFilters.workerOrgTypes}
        filterParams={filterParamsRef.current} applyFilters={applyFilters} name={FilterKeys.workerOrgTypes} getPaginatedInitialValues={getPaginatedInitialValues?.bind(null, FilterKeys.workerOrgTypes)} />,
        accessor: 'workerOrgType',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.worker.orgType} textColor="#666666" />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <FilterSelectInput onlyEndingNodes filterWidth={200} filtersItemsWidth={300} search autoSelectChildren={false} resizable title="Назва установи користувача" columnName="nodes2"
        // columnName="workerFrontOffice"
        filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} containerExtraStyles="extraFilterStyles" extraFilterFormStyles="extraFilterFormStyles" optionsExtraStyles="optionsExtraStyles" />,
        accessor: 'workerOrgName',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.worker.orgName.join(' ')} textColor="#666666" />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: 'Роль',
        accessor: 'workerRole',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TAccessRequest;
          };
        }) => <NameCustomField name={data.row.original.worker.role} textColor="#666666" />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: 'Назва посади',
        accessor: 'workerPosition',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.worker.position} textColor="#666666" />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <CustomFilter dropdownWidth={300} search resizable title="Регіон" filterParams={filterParamsRef.current} applyFilters={applyFilters} name={FilterKeys.workerRegions} getPaginatedInitialValues={getPaginatedInitialValues?.bind(null, FilterKeys.workerRegions)} />,
        accessor: 'workerRegion',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.worker.region} textColor="#666666" />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <FilterSelectInput filterWidth={200} filtersItemsWidth={300} search
        // initiaFilterlValues={initialFilterValues.managers}
        autoSelectChildren={false} resizable title="Фронт-офіс" columnName="nodes"
        // columnName="workerFrontOffice"
        filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} containerExtraStyles="extraFilterStyles" extraFilterFormStyles="extraFilterFormStyles" optionsExtraStyles="optionsExtraStyles" />,
        accessor: 'workerFrontOffice',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.worker.frontOffice.join(' ')} textColor="#666666" />
      }, {
        // @ts-ignore
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <FilterSelectInputStyles ref={filtersRef}>
              <div className="filterRow">
                {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
                <div aria-label="Статус" role="button" tabIndex={0}
            // style={filtersOpenerStyles}
            className={`filtersOpener ${isFilterOpened ? 'filtersOpener-active' : ''}`} onClick={() => {
              setIsFilterOpened(!isFilterOpened);
            }}>
                  <span className="filterTitle">Статус запиту</span>
                  <ArrowDownIcon />
                </div>
                {/* @ts-ignore */}
                {filterParamsRef?.current?.statuses?.length && <TableFilterIcon width={15} height={15} />}
              </div>
              {isFilterOpened && <div style={{
            width: 200,
            position: 'absolute',
            top: 30
          }}>
                  <DropdownFilter name="statuses" initialValues={initialServicesTypeValues} filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(e => !e)}
            // @ts-ignore
            setApply={applyFilters}
            // @ts-ignore
            onClose={() => setIsFilterOpened(false)} />
                </div>}
            </FilterSelectInputStyles>,
        accessor: 'status',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TAccessRequest;
          };
        }) => <NameCustomField name={statuses[data.row.original.status]} textColor={statusesColors[data.row.original.status]} />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <FilterSelectInput sortable filtersOpenerStyles={{
          minWidth: 120
        }} columnName="processedAt" type="dataInterval" title="Дата та час опрацювання" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'processedAt',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.processedAt ? format(new Date(data.row.original.processedAt), 'dd.MM.yyyy HH:mm') : '-'} textColor="#666666" />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <CustomFilter dropdownWidth={300} search resizable title="ПІБ користувача до якого надійшов запит" filterParams={filterParamsRef.current} applyFilters={applyFilters}
        // name="workerRegions"
        name={FilterKeys.managerNames} getPaginatedInitialValues={getPaginatedInitialValues?.bind(null, FilterKeys.managerNames)} />,
        accessor: 'responsible_name',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.manager.name} textColor="#666666" />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <CustomFilter dropdownWidth={300} search resizable title="Тип установи, до якої надійшов запит" filterParams={filterParamsRef.current} applyFilters={applyFilters} name={FilterKeys.managerOrgTypes} getPaginatedInitialValues={getPaginatedInitialValues?.bind(null, FilterKeys.managerOrgTypes)} />,
        accessor: 'managerOrgType',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.manager.orgType} textColor="#666666" />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <CustomFilter dropdownWidth={300} search resizable title="Назва установи, до якої надійшов запит" filterParams={filterParamsRef.current} applyFilters={applyFilters} name={FilterKeys.managerOrgNames} getPaginatedInitialValues={getPaginatedInitialValues?.bind(null, FilterKeys.managerOrgNames)} />,
        accessor: 'managerOrgNames',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.manager.orgName} textColor="#666666" />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: 'Роль користувача, що опрацював запит',
        accessor: 'processedRole',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TAccessRequest;
          };
        }) => <NameCustomField name={data.row.original.processed.role} textColor="#666666" />
      }, {
        Header: 'Опрацьовано',
        accessor: 'processedBy',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TAccessRequest;
          };
        }) => data.row.original.processed ? <NameCustomField name={data.row.original.processed.name} textColor="#666666" /> : <div />
      }, {
        Header: '',
        accessor: 'accept',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TAccessRequest;
          };
        }) => data.row.original.status === 'pending' ? <SubmitButton extraButtonStyles={{
          backgroundColor: '#04c65d',
          height: 32,
          paddingRight: 15,
          paddingLeft: 15
        }} onClick={() => approveRequest(data.row.original.id)}>
              <p>Підтвердити</p>
            </SubmitButton> : <div />
      }, {
        Header: '',
        accessor: 'reject',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: TAccessRequest;
          };
        }) => data.row.original.status === 'pending' || data.row.original.status === 'approved' ? <SubmitButton extraButtonStyles={{
          backgroundColor: '#000',
          height: 32,
          paddingRight: 15,
          paddingLeft: 15
        }} onClick={() => rejectRequest(data.row.original.id)}>
                <p>Відхилити</p>
              </SubmitButton> : <div />
      }]);
    }
  }, [accessesRef.current, reQuery, isFilterOpened, filterParamsRef.current
  // users,
  ]);
  const extraFieldsSettings = {
    editButtons: {
      justifyContent: 'flex-end'
    }
  };
  return <AcessRequestStyles>
      <Helmet>
        <title>Надання доступів</title>
      </Helmet>
      <SubHeader title={`Запити на доступ (${itemsQuantityRef.current})`}>
        {companies && <CompanyFilter extraStyles={{
        width: 200,
        maxWidth: 300
      }} filterParams={filterParamsRef.current} companies={companies} setFilterParams={applyFilters} setReQuery={() => setReQuery(!reQuery)} />}
        <div className="buttonsContainer">
          <TransparentButton handleClick={() => navigate('/users')} text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')} />
        </div>
      </SubHeader>
      {filterParamsRef.current.companyID && <div className="row">
        <SearchContainer style={{
        marginBottom: 60
      }}>
          <input placeholder="Пошук..." type="text" value={searchQuery as string} onChange={({
          target: {
            value
          }
        }) => {
          setSearchQuery(value);
        }} />
          <DropDownContainer>
            <StyledSelect>
              <CustomSelect containerStyle={{
              borderWidth: 0,
              backgroundColor: '#E7EEF3',
              marginTop: 0,
              padding: '2px 4px 2px 12px',
              borderRadius: 999,
              height: 28
            }} options={options} valueField={selectedType.name} selected={selectedType} labelField="label" name="name" onChange={e => {
              setSelectedType(e);
              setSearchQuery('');
            }} placeholder="Enter type" />
            </StyledSelect>
          </DropDownContainer>
        </SearchContainer>
        <SubmitButton isLoading={isExporting} onClick={() => {
        exportTable();
      }}>Експортувати в XLSX
        </SubmitButton>
      </div>}
      <div className="access-wrapper">
        {uploading && <Loader />}
        {!!accessesRef.current && !!tableHeaders && !uploading && filterParamsRef.current.companyID && <Table
      // sortableColumns={['date']}
      fixedHeader pagination columns={tableHeaders} data={accessesRef.current} hideFieldsSplitters extraFieldsSettings={extraFieldsSettings}
      // headerColumnCounts={{ role: itemsQuantityRef.current }}
      rowTitle={getTranslationByLangOrEng(interfaceLanguage, 'open_role_title')} hiddenHeaders={['editButtons']} fullWidthColumns={['editButtons']} pageSize={pageSizeRef.current} currentPage={pageNumber ? +pageNumber : 1} rowsQuantity={itemsQuantityRef.current} pageNumberHandler={page => {
        if (page) {
          navigate(`/access_requests/manage/page/${page}`);
        }
      }} />}
      </div>
    </AcessRequestStyles>;
};