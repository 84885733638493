import React, { CSSProperties } from 'react';
import { SubmitButtonStyles } from './styles';
import { ErrorIcon } from '../../../assets';
import { Loader } from '../../atoms';
import { Oval } from 'react-loader-spinner';
interface SubmitButtonProps {
  extraBlockStyles?: CSSProperties;
  extraButtonStyles?: CSSProperties;
  onClick?: () => void;
  bgColor?: string;
  textColor?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  isLoading?: boolean;
  isError?: boolean;
  disabled?: boolean;
}
const SubmitButton: React.FC<SubmitButtonProps> = ({
  children,
  extraBlockStyles,
  extraButtonStyles,
  onClick,
  bgColor,
  textColor,
  type,
  isLoading,
  isError,
  disabled
}) => <SubmitButtonStyles disabled={disabled} style={extraBlockStyles}>
    <button
  // eslint-disable-next-line react/button-has-type
  type={type || 'submit'} style={extraButtonStyles} onClick={onClick} disabled={isLoading || disabled}>
      {!isLoading ? children : <Oval color="white" height={20} width={20} strokeWidth={20 / 4} strokeWidthSecondary={20 / 4} secondaryColor={`${'#000'}99`} />}
    </button>
  </SubmitButtonStyles>;
export default SubmitButton;