import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { ComplexRatingDashboardStyles } from './styles';
import { analyticsApi, ApiComplexRating, TPostComplexRating } from '../../../../api';
import { Table } from '../../../organisms';
import { setSaveFilters, setSaveLink, useAppDispatch } from '../../../../state';
type TComplexRatingDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
const ComplexRatingDashboard_1 = _compiledBlock(_props => _props.v0, {
  name: "ComplexRatingDashboard_1",
  portals: ["v0"]
});
export const ComplexRatingDashboard: FC<TComplexRatingDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<ApiComplexRating[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const dataCount = useRef<number>(0);
  useEffect(() => {
    if (!loading) {
      setData([]);
      setPageNumber(1);
      setReload(!reload);
    }
  }, [reQuery]);
  useEffect(() => {
    const {
      companyID,
      year,
      quarter,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      isDiia,
      sourceIDs,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostComplexRating = {
      year: +year as number,
      quarter: +quarter as number,
      cnapTypesRating: ['ВРМ'],
      csiFieldIDs: [858718, 858809, 858866],
      csiPositiveAnswers: ['Чудово', 'Добре'],
      npsFieldIDs: [858735, 858818, 858826],
      npsPositiveAnswers: ['9', '10'],
      npsNegativeAnswers: ['1', '2', '3', '4', '5', '6'],
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean,
      sourceIDs: sourceIDs as number[],
      withTrustLevel: true
    };
    setLoading(true);
    analyticsApi.getComplexRatingData(+companyID, filteredData, (pageNumber ? +pageNumber - 1 : 0) * 10, 10).then(res => {
      setData(prev => [...prev, ...res.data]);
      dataCount.current = res.count ? res.count : 0;
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    });
    dispatch(setSaveLink('analytics/rating/complex/file'));
    dispatch(setSaveFilters({
      year: +year as number,
      quarter: +quarter as number,
      cnapTypesRating: ['ВРМ'],
      csiFieldIDs: [858718, 858809, 858866],
      csiPositiveAnswers: ['Чудово', 'Добре'],
      npsFieldIDs: [858735, 858818, 858826],
      npsPositiveAnswers: ['9', '10'],
      npsNegativeAnswers: ['1', '2', '3', '4', '5', '6'],
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      sourceIDs: sourceIDs as number[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean
    }));
  }, [pageNumber, reload]);
  useEffect(() => {
    setTableHeaders([{
      Header: 'IDF',
      accessor: 'idf',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.idf}</span>
          </div>
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <div className="dashboard-name">
            <span>Регіон</span>
          </div>,
      accessor: 'regionName',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.regionName}</span>
          </div>
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <div className="dashboard-name">
            <span>Назва центру</span>
          </div>,
      accessor: 'name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.name}</span>
          </div>
    }, {
      Header: 'Рейтинг',
      accessor: 'rating',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.rating}</span>
          </div>
    }, {
      Header: 'CSI',
      accessor: 'csi',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>
              {data.row.original.csi}
            </span>
          </div>
    }, {
      Header: 'NPS',
      accessor: 'nps',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>
              {data.row.original.nps}
            </span>
          </div>
    }, {
      Header: 'Кількість відгуків',
      accessor: 'reviews',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>
              {data.row.original.reviews}
            </span>
          </div>
    }]);
  }, [data]);
  return /*@million jsx-skip*/<ComplexRatingDashboard_1 v0={<ComplexRatingDashboardStyles>
      <div className="rating">
        {tableHeaders && <Table columns={tableHeaders} data={data} fullWidthColumns={['name']} totalCount={dataCount.current} currentPage={pageNumber} offset={10} loading={loading} pageNumberHandler={value => setPageNumber(value)} fixedHeader />}
      </div>
    </ComplexRatingDashboardStyles>} />;
};