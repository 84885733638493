import React, { FC } from 'react';
import { TIcon } from './TIcon';
export const ConversionMassMailings: FC<TIcon> = React.memo(({
  color = '#0C0005',
  width = 36,
  height = 36
}) => <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10 8H26V15H28V8C28 6.89543 27.1046 6 26 6H10C8.89543 6 8 6.89543 8 8V28C8 29.1046 8.89543 30 10 30H26C27.1046 30 28 29.1046 28 28V25H26V28H10V8Z" fill={color} />
    <path d="M14.1322 9.9563L14.7158 11.1388L16.0207 11.3284L15.0764 12.2488L15.2994 13.5485L14.1322 12.9349L12.965 13.5485L13.1879 12.2488L12.2437 11.3284L13.5486 11.1388L14.1322 9.9563Z" fill={color} />
    <path d="M18.1034 9.9563L18.687 11.1388L19.9919 11.3284L19.0476 12.2488L19.2705 13.5485L18.1034 12.9349L16.9362 13.5485L17.1591 12.2488L16.2149 11.3284L17.5198 11.1388L18.1034 9.9563Z" fill={color} />
    <path d="M22.0751 9.9563L22.6586 11.1388L23.9636 11.3284L23.0193 12.2488L23.2422 13.5485L22.0751 12.9349L20.9079 13.5485L21.1308 12.2488L20.1865 11.3284L21.4915 11.1388L22.0751 9.9563Z" fill={color} />
    <path d="M13 17H19" stroke="black" strokeWidth="2" strokeLinecap="round" />
    <path d="M13 21H19" stroke="black" strokeWidth="2" strokeLinecap="round" />
    <path d="M13 25H20" stroke="black" strokeWidth="2" strokeLinecap="round" />
    <path d="M25 19.9167L26.4054 21L29 19" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <circle cx="27" cy="20" r="5" stroke={color} strokeWidth="2" />
  </svg>);