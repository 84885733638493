/* eslint-disable class-methods-use-this */
import { ISource } from '../../entities';
import baseApi from '../baseApi';
import { ApiResponse } from '../entities';
import ISourcesApi from './ISourcesApi';

class SourcesApi implements ISourcesApi {
  public async getSourcesByCompanyId(
    companyId: number,
    isPublic: boolean = false,
  ): Promise<ApiResponse<ISource[]>> {
    const { data, statusCode } = await baseApi.getData(
      `${isPublic ? '/public' : ''}/companies/${companyId}/sources`,
    );

    return {
      data,
      statusCode,
    };
  }

  public async getSourceById(id: number): Promise<ApiResponse<ISource>> {
    const { statusCode, data } = await baseApi.getData(`/sources/${id}`);

    return Promise.resolve({
      statusCode,
      data,
    });
  }

  public async createSource(source: {
    companyID: number;
    name: string;
  }): Promise<ApiResponse<ISource>> {
    return baseApi.postData('/sources', source);
  }

  public async updateSource(
    id: number,
    source: { companyID: number; name: string },
  ): Promise<ApiResponse<ISource>> {
    return baseApi.putData(`/sources/${id}`, source);
  }

  public async deleteSource(id: number): Promise<ApiResponse<any>> {
    return baseApi.deleteData(`/sources/${id}`);
  }
}

export const sourcesApi = new SourcesApi();
