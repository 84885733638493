import styled from 'styled-components';

export const ExportStyles = styled.div`
  .exportPageWrapper {
    display: flex;
    padding: 17px 38px 17px 17px;
    .formSection {
      margin-right: 50px;
      width: 100%;

      .submitError {
        max-width: 300px;
        margin-top: 12px;
        color: #c32900;
        font-size: var(--fs-3);
        text-align: center;
      }
    }
  }
`;
