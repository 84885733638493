import styled from 'styled-components';

export const CustomSelectWithMultipleCheckboxesStyles = styled.div<{
  disableScroll?: boolean;
}>`
  .row {
    width: 100%;
  }
  .form-group {
    .labelContainer {
      margin-bottom: 8px;

      label {
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;
        color: #000000;
      }
    }

    &.error input {
      border-color: #f4cfcf;
      background-color: #fff;
    }

    &.valid input {
      border-color: #eaf6dd;
      background-color: #fff;
    }

    .form-field-error {
      height: 24px;
      font-size: 11px;
      line-height: 16px;
      color: #ff3333;
    }

    .fieldContainer {
      position: relative;
      display: flex;
      gap: 16px;
      align-items: center;
    }
  }

  .mainBodyWrapper {
    position: relative;
  }

  .addButtonWrapper {
    margin-top: 8px;
  }

  .selectField {
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 16px;
    position: relative;
    cursor: pointer;

    .loading {
      width: 100%;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(230, 230, 230, 0.7);
      display: grid;
      place-items: center;
      border-radius: 4px;
    }

    .selectedValue {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrowDownActive {
        transform: rotate(180deg);
      }
    }

    .valueContainer {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 20px;
      align-items: center;
      font-size: 14px;
      color: #000;
    }

    .placeholder {
      font-size: 14px;
      color: #6c6c6c;
    }

    label {
      font-size: 14px;
      line-height: 14px !important;
      padding: 0;
      margin: 0;
    }
  }

  .options {
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
    width: 100%;
    ${({ disableScroll }) => !disableScroll && 'overflow-y:  scroll;'}
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);

    &.alwaysOpened {
      position: static;
      top: none;
      left: none;
      z-index: auto;
      box-shadow: none;
      margin-top: 24px;
    }
  }

  .iconWrapper {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
  }

  .option {
    width: 100%;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #c4c4c4;
    box-sizing: border-box;
    overflow: hidden;
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    &:last-child {
      border-bottom: none;
    }

    label {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      cursor: pointer;
      width: 100%;

      .checkboxWrapper {
        width: 100%;
      }
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .searchWrapper {
    height: 32px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c4c4c4;
    box-sizing: border-box;
    padding: 0 16px;
    background-color: #fcfcfc;
    input {
      flex-grow: 1;
      margin-left: 12px;
      padding: 0;
      border: none;
      outline: none;
      font-size: 14px;
      color: #000;
      background-color: #fcfcfc;
      height: 22px;
      &::placeholder {
        font-size: 14px;
        color: #6e6e6e;
      }
    }
  }
`;
