import { CompanyInfoFormStyles } from './CompanyInfoFormStyles';
import React, { useEffect, useRef, useState } from 'react';
import DefaultCompanyImage from '../../assets/images/defaultCompanyImage.png';
import { UploadFileButton } from '../form/uploadFileButton/UploadFileButton';
import { FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import SubmitButton from '../form/submitButton/SubmitButton';
import { DeleteIcon } from '../../assets';
import { useNavigate, useParams } from 'react-router-dom';
import { mapFromApiCompanyToICompany, languagesApi, companyApi, filesApi } from '../../api';
import { useAppDispatch, useAppSelector, setCompanyMetadata, setSelectedCompany } from '../../state';
import { ICompanyExtraField, ILanguage } from '../../entities';
import CustomSelectWithMultipleCheckboxes from '../form/customSelect/CustomSelectWithMultipleCheckboxes';
import { getLanguages, getErrorMessage, handleKeyUp, proxyFile } from '../../utils';
import { getTranslationByLangOrEng } from '../../i18n';
import { Loader, TransparentButton } from '../atoms';
import { CompaniesInfoModal, ModalConfirmDecline } from '../organisms';
import { InputField } from '../molecules';
interface FormValues {
  name: string;
  logo: {
    value: string | File;
    id?: number;
  };
  originUrl: {
    value: string;
    id?: number;
  };
  domain: string;
  extraFields: {
    id?: number;
    name: string;
    value: string;
    key?: string;
    position: string;
  }[];
  languages: (ILanguage & {
    value: boolean;
  })[];
}
export default function CompanyInfoForm() {
  const {
    id
  } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const company = useAppSelector(state => state.company.selectedCompany);
  const companyMetadata = useAppSelector(state => state.company.companyMetadata);
  const {
    languages: languagesState
  } = useAppSelector(state => state.languages);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [maxSizeError, setMaxSizeError] = useState<string>('');
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [afterSaveModal, setAfterSaveModal] = useState<number>(0);
  const [confirmSaveModalOpen, setConfirmSaveModalOpen] = useState<boolean>(false);
  const [dataSaveError, setDataSaveError] = useState<string>('');
  const initialCompanyLanguagesRef = useRef<ILanguage[] | undefined>();
  const companyInfoRef = useRef<FormikProps<FormValues>>(null);
  const maxSize = 819200;
  const getData = async () => {
    let companyLanguages: ILanguage[] = id ? await languagesApi.getCompanyLanguages(+id).then(res => res.data) : [];
    if (!companyLanguages) {
      companyLanguages = [];
    }
    initialCompanyLanguagesRef.current = [...companyLanguages];
    const companyLanguageIds = companyLanguages.map(item => item.id) || [];
    if (id && company && company.id === +id) {
      companyApi.getCompanyMetadataByCompanyId(+id).then(res => {
        dispatch(setCompanyMetadata(res.data));
        setInitialValues({
          name: company.name!,
          logo: {
            value: res.data.logo?.value || '',
            id: res.data.logo?.id
          },
          originUrl: {
            value: res.data.originUrl?.value || '',
            id: res.data.originUrl?.id
          },
          domain: company.domain!,
          extraFields: res.data.extraFields || [],
          languages: languagesState?.map(lang => ({
            ...lang,
            value: companyLanguageIds.includes(lang.id)
          })) || []
        });
      });
    } else if (id) {
      companyApi.getCompanyById(+id).then(result => {
        dispatch(setSelectedCompany(result.data));
        companyApi.getCompanyMetadataByCompanyId(+id).then(res => {
          dispatch(setCompanyMetadata(res.data));
          setInitialValues({
            name: result.data.name!,
            logo: {
              value: res.data.logo?.value || '',
              id: res.data.logo?.id
            },
            originUrl: {
              value: res.data.originUrl?.value || '',
              id: res.data.originUrl?.id
            },
            domain: result.data.domain!,
            extraFields: res.data.extraFields || [],
            languages: languagesState?.map(lang => ({
              ...lang,
              value: companyLanguageIds.includes(lang.id)
            })) || []
          });
        });
      });
    } else if (!id) {
      setInitialValues({
        name: '',
        logo: {
          value: ''
        },
        originUrl: {
          value: ''
        },
        domain: '',
        extraFields: [],
        languages: languagesState?.map(lang => ({
          ...lang,
          value: false
        })) || []
      });
    } else {
      navigate('/companies');
    }
  };
  useEffect(() => {
    if (languagesState) {
      getData();
    } else {
      getLanguages(dispatch);
    }
  }, [id, languagesState]);
  const logoExamination = (file: File | undefined) => {
    if (file!.size > maxSize) {
      setMaxSizeError(getTranslationByLangOrEng(interfaceLanguage, 'max_size_error'));
      return;
    }
    setMaxSizeError('');
  };
  const validationSchema = Yup.object({
    name: Yup.string().required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required')),
    domain: Yup.string().required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required')).matches(/^[a-z0-9]+([.-]{0,1}([a-z0-9]+))+([a-z0-9]*)$/gm, getTranslationByLangOrEng(interfaceLanguage, 'validation_domain')),
    languages: Yup.array().of(Yup.object().shape({
      id: Yup.number().required(),
      name: Yup.string().required(),
      icon: Yup.string().required(),
      value: Yup.boolean().default(false)
    })).min(1).test('at-least-one-selected', 'At least one language must be selected', values => {
      if (!Array.isArray(values)) {
        return false;
      }
      return values.some(value => value.value === true);
    })
  });
  const onSaveModalConfirm = async (values: FormValues) => {
    setIsLoading(true);
    const selectedLanguages = values.languages.filter(lang => lang.value);
    const extraFieldsWithPosition: ICompanyExtraField[] = values.extraFields.map((item, index) => ({
      ...item,
      position: String(index)
    }));
    if (id && company && companyMetadata) {
      const extraFieldToDelete = companyMetadata.extraFields?.filter(extraField => extraFieldsWithPosition.every(item => extraField.id !== item.id));
      const extraFieldToCreate = extraFieldsWithPosition?.filter(extraField => !extraField.id);
      const extraFieldToUpdate = extraFieldsWithPosition?.filter(extraField => {
        const fieldToCheck = companyMetadata.extraFields?.find(item => item.id === extraField.id);
        if (fieldToCheck) {
          return fieldToCheck.value !== extraField.value || fieldToCheck.value !== extraField.name || fieldToCheck.position !== extraField.position;
        }
        return false;
      });
      if (initialCompanyLanguagesRef.current?.length !== 0 && selectedLanguages.length === 0) {
        languagesApi.updateCompanyLanguages(+id, selectedLanguages);
      } else if (initialCompanyLanguagesRef.current?.length === 0 && selectedLanguages.length !== 0) {
        languagesApi.updateCompanyLanguages(+id, selectedLanguages);
      } else if (selectedLanguages.length !== initialCompanyLanguagesRef.current?.length || selectedLanguages.filter(lang => initialCompanyLanguagesRef.current?.map(language => language.id!).includes(lang.id!)).length !== initialCompanyLanguagesRef.current?.length) {
        languagesApi.updateCompanyLanguages(+id, selectedLanguages);
      }
      if (extraFieldToDelete?.length) {
        extraFieldToDelete.forEach(async item => {
          await companyApi.deleteCompanyMetadata(item.id!);
        });
      }
      if (extraFieldToUpdate.length) {
        extraFieldToUpdate.forEach(async item => {
          await companyApi.updateCompanyMetadata(item.id!, [item.name, item.value, item.position].join('|||'));
        });
      }
      if (extraFieldToCreate.length) {
        extraFieldToCreate.forEach(async extraField => {
          await companyApi.createCompaniesMetadata({
            key: `extraField_${Math.random()}`,
            value: [extraField.name, extraField.value, extraField.position].join('|||')
          }, +id);
        });
      }
      if (company.name !== values.name || company.domain !== values.domain) {
        await companyApi.editCompanyMainInfo({
          name: values.name,
          alias: values.domain,
          id: +id
        });
      }
      if (values.logo.value instanceof File) {
        await filesApi.createFile({
          file: values.logo.value
        }).then(async res => {
          if (values.logo.id) {
            await companyApi.updateCompanyMetadata(values.logo.id, res.data.url);
          } else {
            await companyApi.createCompaniesMetadata({
              key: 'logo',
              value: res.data.url
            }, +id);
          }
        });
      }
      if (values.originUrl.value) {
        if (values.originUrl.id) {
          await companyApi.updateCompanyMetadata(values.originUrl.id, values.originUrl.value);
        } else {
          await companyApi.createCompaniesMetadata({
            key: 'officialSiteURL',
            value: values.originUrl.value
          }, +id);
        }
      }
      setAfterSaveModal(2);
      setConfirmSaveModalOpen(false);
    } else {
      await companyApi.createCompany({
        name: values.name,
        alias: values.domain
      }).then(async res => {
        if (selectedLanguages.length) {
          languagesApi.updateCompanyLanguages(+res.data.id, selectedLanguages);
        }
        if (extraFieldsWithPosition.length) {
          extraFieldsWithPosition.forEach(async extraField => {
            await companyApi.createCompaniesMetadata({
              key: `extraField_${Math.random()}`,
              value: [extraField.name, extraField.value, extraField.position].join('|||')
            }, res.data.id);
          });
        }
        if (values.logo.value instanceof File) {
          await filesApi.createFile({
            file: values.logo.value
          }).then(async fileRes => {
            await companyApi.createCompaniesMetadata({
              key: 'logo',
              value: fileRes.data.url
            }, res.data.id);
          });
        }
        if (values.originUrl.value) {
          await companyApi.createCompaniesMetadata({
            key: 'officialSiteURL',
            value: values.originUrl.value
          }, res.data.id);
        }
        dispatch(setSelectedCompany(mapFromApiCompanyToICompany(res.data)));
        setAfterSaveModal(1);
      }).catch(e => {
        console.log('e', e);
        setDataSaveError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
      });
    }
    setIsLoading(false);
  };
  async function onSubmit(values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) {
    if (id && company && companyMetadata) {
      setConfirmSaveModalOpen(true);
      setSubmitting(false);
    } else {
      const selectedLanguages = values.languages.filter(lang => lang.value);
      const extraFieldsWithPosition: ICompanyExtraField[] = values.extraFields.map((item, index) => ({
        ...item,
        position: String(index)
      }));
      await companyApi.createCompany({
        name: values.name,
        alias: values.domain
      }).then(async res => {
        if (selectedLanguages.length) {
          languagesApi.updateCompanyLanguages(+res.data.id, selectedLanguages);
        }
        if (extraFieldsWithPosition.length) {
          extraFieldsWithPosition.forEach(async extraField => {
            await companyApi.createCompaniesMetadata({
              key: `extraField_${Math.random()}`,
              value: [extraField.name, extraField.value, extraField.position].join('|||')
            }, res.data.id);
          });
        }
        if (values.logo.value instanceof File) {
          await filesApi.createFile({
            file: values.logo.value
          }).then(async fileRes => {
            await companyApi.createCompaniesMetadata({
              key: 'logo',
              value: fileRes.data.url
            }, res.data.id);
          });
        }
        if (values.originUrl.value) {
          await companyApi.createCompaniesMetadata({
            key: 'officialSiteURL',
            value: values.originUrl.value
          }, res.data.id);
        }
        dispatch(setSelectedCompany(mapFromApiCompanyToICompany(res.data)));
        setAfterSaveModal(1);
      }).catch(e => {
        console.log('e', e);
      });
      setSubmitting(false);
    }
  }
  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange
  }: FormikProps<FormValues>) => <Form>
      <div className="addLogoWrapper">
        <div className={values.logo.value ? 'logoContainer' : 'logoContainer default'}>
          {values.logo.value ? <img className="selectedImage" src={typeof values.logo.value === 'string' ? proxyFile(values.logo.value, true) : URL.createObjectURL(values.logo.value)} alt="" /> : <img src={DefaultCompanyImage} alt="logo" />}
        </div>

        <div className="infoContainer">
          <UploadFileButton onChange={file => {
          logoExamination(file);
          setFieldValue('logo.value', file);
        }} formats="image/*" />
          <p className="uploadError">{maxSizeError}</p>
          <span className="description">
            {getTranslationByLangOrEng(interfaceLanguage, 'companies_info_file_max_size')}
          </span>
        </div>
      </div>

      <InputField name="name" onChange={setFieldValue} onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)} placeholder="Введіть назву субʼєкта моніторингу" value={values.name} error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined} label={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_company_name_label')} />
      <InputField name="originUrl.value" onChange={setFieldValue} onKeyUp={() => handleKeyUp('originUrl.value', setErrorMessage, errorMessage)} placeholder="Введіть сайт суб’єкта моніторингу" value={values.originUrl.value} label="Введіть сайт суб’єкта моніторингу" />
      <div className="domainInputWrapper">
        <InputField name="domain" type="text" onChange={setFieldValue} onKeyUp={() => handleKeyUp('domain', setErrorMessage, errorMessage)} placeholder="Введіть домен суб’єкту моніторингу'" value={values.domain} error={typeof errorMessage === 'object' ? getErrorMessage('domain', errorMessage) : undefined} label={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_company_domain_label')} />
      </div>

      <CustomSelectWithMultipleCheckboxes name="languages" handleChange={handleChange} options={values.languages} search error={typeof errorMessage === 'object' ? getErrorMessage('languages', errorMessage) : undefined} optionsContainerStyles={{
      width: '100%'
    }} formGroupStyles={{
      width: '100%',
      marginBottom: '20px'
    }} label={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_add_multi_languages_label')} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_add_multi_languages_placeholder')} />

      {id ? <SubmitButton isLoading={isLoading} isError={touched && Object.keys(errors).length > 0}>
          {getTranslationByLangOrEng(interfaceLanguage, 'companies_info_submit_edit_button')}
        </SubmitButton> : <SubmitButton isLoading={isLoading} isError={touched && Object.keys(errors).length > 0}>
          {getTranslationByLangOrEng(interfaceLanguage, 'companies_info_submit_create_button')}
        </SubmitButton>}

      {typeof errorMessage === 'string' && <p className="extraErrorMessage">{errorMessage}</p>}
    </Form>;
  return <CompanyInfoFormStyles>
      {afterSaveModal === 1 && <CompaniesInfoModal onClose={() => setAfterSaveModal(0)} onDecline={() => navigate('/companies')} onConfirm={() => navigate(`/companies/${company?.id}/edit`)} confirmText={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_after_save_modal_confirm_button')} title={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_after_save_modal_title')} declineText={getTranslationByLangOrEng(interfaceLanguage, 'go_back')} />}
      {afterSaveModal === 2 && <CompaniesInfoModal onClose={() => setAfterSaveModal(0)} onDecline={() => navigate('/companies')} onConfirm={() => setAfterSaveModal(0)} />}
      {!initialValues ? <Loader /> : <div className="formContainer">
          <Formik innerRef={companyInfoRef} initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {renderForm}
          </Formik>
        </div>}

      {confirmSaveModalOpen && <ModalConfirmDecline onClose={() => setConfirmSaveModalOpen(false)} onConfirm={() => onSaveModalConfirm(companyInfoRef.current!.values)} onDecline={() => setConfirmSaveModalOpen(false)} confirmText={getTranslationByLangOrEng(interfaceLanguage, 'save_changes_button')} declineText={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} title={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_confirmation_before_save_modal_title')} error={!!dataSaveError} errorText={dataSaveError} firstButtonStyles={{
      background: '#e50000'
    }} secondButtonStyles={{
      background: '#000'
    }} />}
    </CompanyInfoFormStyles>;
}