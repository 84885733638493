/* eslint-disable class-methods-use-this */
import { saveAs } from 'file-saver';
import baseApi from '../baseApi';
import { ApiAuthResponse, ApiResponse, ApiUser } from '../entities';
import { TAccessRequestFilters, TAccessRequestResponse } from './IAccessesApi';

export type AccessRequest = {
  companyID: number;
  createdAt: string;
  id: number;
  managerID: number;
  nodesIDs: number[];
  processedBy: number;
  status: 'pending' | 'rejected' | 'approved';
  updatedAt: string;
  workerID: number;
};

class AccessesApi {
  async accessReviewManagerNameFilters(
    searchString: string,
    limit: number,
    offset: number,
  ): Promise<ApiResponse<{ count: number; items: string[] }>> {
    return baseApi.getData(
      `/view-access/filters/manager-name?query=${searchString}&limit=${limit}&offset=${offset}`,
    );
  }

  async accessReviewManagerOrgNameFilters(
    searchString: string,
    limit: number,
    offset: number,
  ): Promise<ApiResponse<{ count: number; items: string[] }>> {
    return baseApi.getData(
      `/view-access/filters/manager-org-name?query=${searchString}&limit=${limit}&offset=${offset}`,
    );
  }

  async accessReviewManagerOrgTypeFilters(
    searchString: string,
    limit: number,
    offset: number,
  ): Promise<ApiResponse<{ count: number; items: string[] }>> {
    return baseApi.getData(
      `/view-access/filters/manager-org-type?query=${searchString}&limit=${limit}&offset=${offset}`,
    );
  }

  async accessReviewWorkerOrgTypeFilters(
    searchString: string,
    limit: number,
    offset: number,
  ): Promise<ApiResponse<{ count: number; items: string[] }>> {
    return baseApi.getData(
      `/view-access/filters/worker-org-type?query=${searchString}&limit=${limit}&offset=${offset}`,
    );
  }

  async accessReviewWorkerRegionFilters(
    searchString: string,
    limit: number,
    offset: number,
  ): Promise<ApiResponse<{ count: number; items: string[] }>> {
    return baseApi.getData(
      `/view-access/filters/worker-region?query=${searchString}&limit=${limit}&offset=${offset}`,
    );
  }

  async accessReviewListFull(
    filters: TAccessRequestFilters,
  ): Promise<ApiResponse<TAccessRequestResponse>> {
    const response = await baseApi.postData('/view-access/list-full', filters);
    return {
      data: response.data,
      statusCode: response.statusCode,
    };
  }

  /**
   * @deprecated Use accessReviewListFull instead
   */
  async accessReviewList({
    isManager,
    status,
    limit,
    offset,
  }: any): Promise<ApiResponse<{ requests: AccessRequest[]; count: number }>> {
    const response = await baseApi.getData(
      `/view-access?limit=${limit}&offset=${offset}&isManager=${isManager}${
        status?.length ? `&statuses=${status.join('&statuses=')}` : ''
      }`,
    );
    return {
      data: response.data,
      statusCode: response.statusCode,
    };
  }

  async accessReviewListPost({
    isManager,
    status,
    limit,
    offset,
    nodesIDs,
    workerIDs,
    managerIDs,
    compayIDs,
    sorting,
    roles,
    query,
  }: any): Promise<ApiResponse<{ requests: AccessRequest[]; count: number }>> {
    console.error(
      'This method is deprecated. Please use accessReviewListFull instead.',
    );
    throw new Error(
      'This method is deprecated. Please use accessReviewListFull instead.',
    );
  }

  async accessReviewFiltersPost(): Promise<
    ApiResponse<{
      managers: {
        [key: string]: string;
      };
      roles: string[];
      workers: {
        [key: string]: string;
      };
    }>
    > {
    const response = await baseApi.postData('/view-access/filters');
    return {
      data: response.data,
      statusCode: response.statusCode,
    };
  }

  async createAccessReview({
    userID,
    company,
    organization,
    userType,
    companyID,
    nodesIDs,
  }: {
    company: string;
    organization: string;
    userType: string;
    companyID: number;
    nodesIDs: number[];
    userID: number;
  }): Promise<ApiResponse<null>> {
    const response = await baseApi.postData('/view-access', {
      company,
      organization,
      userType,
      companyID,
      nodesIDs,
    });
    return {
      data: response.data.requests,
      statusCode: response.statusCode,
    };
  }

  async sendPosition({
    userID,
    position,
  }: {
    position: string;
    userID: number; // посада
  }): Promise<ApiResponse<null>> {
    const response = await baseApi.putData(`/users/${userID}/position`, {
      position,
    });
    return {
      data: response.data.requests,
      statusCode: response.statusCode,
    };
  }

  async rejectAccessRequest(request_id: number): Promise<ApiResponse<null>> {
    const response = await baseApi.postData(
      `/view-access/${request_id}/reject`,
    );
    return { statusCode: response.statusCode, data: null };
  }

  async approveAccessRequest(request_id: number): Promise<ApiResponse<null>> {
    const response = await baseApi.postData(
      `/view-access/${request_id}/approve`,
    );
    return { statusCode: response.statusCode, data: null };
  }

  async exportAccessRequests(filters: TAccessRequestFilters): Promise<void> {
    const response = await baseApi.postData(
      '/view-access/export',
      filters,
      true,
    );
    saveAs(response.data, 'view-access.xlsx');
  }
}

export const accessesApi = new AccessesApi();
