import { compiledBlock as _compiledBlock } from "million/react";
const KeyAnswerExtraField_1 = _compiledBlock(_props => <div className="answersContainer">
      {_props.v0}
    </div>, {
  name: "KeyAnswerExtraField_1",
  portals: ["v0"]
});
const KeyAnswerExtraField = function KeyAnswerExtraField({
  answers
}: {
  answers: ApiReviewFormAnswer[];
}) {
  const scaleTypes: string[] = ['nps', 'csat', 'ces', 'scale'];
  const [scaleAnswers, setScaleAnswers] = useState<ApiReviewFormAnswer[]>([]);
  useEffect(() => {
    setScaleAnswers(answers.filter(answer => scaleTypes.includes(answer.type)));
  }, [answers]);
  const getKeyAnswerType = (answer: ApiReviewFormAnswer) => {
    const answerType = answer.field.options.find(option => option.key === 'scale_type')?.value;
    switch (answerType) {
      case 'star':
        return <div className="scaleContainer">
            <span>{answer.value.split('|||')[0]}</span>
            <div>
              <StarIcon color="#FF8C19" width={19} height={19} borderColor="#FF8C19" />
            </div>
          </div>;
      case 'color':
      case 'number':
        return <div className="scaleContainer">
            <span>{answer.value.split('|||')[0]}</span>
          </div>;
    }
  };
  return /*@million jsx-skip*/<KeyAnswerExtraField_1 v0={answers.map(answer => <Fragment key={answer.id}>{getKeyAnswerType(answer)}</Fragment>)} />;
};
import { block as _block } from "million/react";
import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { StarIcon } from '../../../assets';
import { networks, reviewsFilesExtraFieldText, reviewsFilesIcons, reviewsStatusIconsAndText, sentimentIcon } from '../../../constants';
import { ILanguage, IContact, TSentiment } from '../../../entities';
import { useAppSelector } from '../../../state';
import { getTypeOfContact, truncate } from '../../../utils';
import { ApiCategory, ApiReviewFormAnswer, ApiTag } from '../../../api';
export function NameExtraField({
  nodeName
}: {
  nodeName: string;
}) {
  return <div className="nameContainer">
      <span>{nodeName}</span>
    </div>;
}
export function TableCell({
  text
}: {
  text: string;
}) {
  return <div className="cellTextContainer">
      <span>{text}</span>
    </div>;
}
export function HeaderCell({
  text
}: {
  text: string;
}) {
  return <div className="headerTextContainer">
      <span>{text}</span>
    </div>;
}
export { KeyAnswerExtraField };
export function ReviewExtraField({
  files
}: {
  files: {
    [key: string]: string[];
  };
}) {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  return <div className="customReviewsWrapper">
      {Object.keys(files).map(item => {
      const Icon = reviewsFilesIcons[item];
      if (files[item].length) {
        return <div className="reviewContainer">
              <Icon width={16} height={16} color="#000" />
              {reviewsFilesExtraFieldText(interfaceLanguage)[item]}
              {files[item].length > 1 && <span>{files[item].length}</span>}
            </div>;
      }
      return '';
    })}
    </div>;
}
export function ConnectionChannelExtraField({
  answers,
  contacts
}: {
  answers?: ApiReviewFormAnswer[];
  contacts: IContact[];
}) {
  const reviewNetwork = answers?.find(answer => answer.type === 'connection_channel') ? answers?.find(answer => answer.type === 'connection_channel')?.value : '';
  const networksTypes = contacts.map(contact => getTypeOfContact(contact, reviewNetwork));
  const DisableNetworkIcon = (reviewNetwork === 'telegram' || reviewNetwork === 'viber') && reviewNetwork ? networks[reviewNetwork].icon : null;
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  return <div className="connectionChannelContainer">
      {networksTypes && [...new Set(networksTypes)].map(contact => {
      const NetworkIcon = contact ? networks[contact].icon : null;
      return <div key={contact} className="networkDataContainer">
              {NetworkIcon && <NetworkIcon width={32} height={28} color={networks[contact].color} key={contact} />}
            </div>;
    })}
      {reviewNetwork && !networksTypes.includes(reviewNetwork) && <div className="networkDataContainer">
          {DisableNetworkIcon && <>
              <DisableNetworkIcon width={32} height={28} color="rgb(204, 204, 204)" />
              <span>Чат не активовано</span>
            </>}
        </div>}
    </div>;
}
export function StatusExtraField({
  status,
  lang
}: {
  status: number;
  lang: string;
}) {
  const Icon = reviewsStatusIconsAndText(lang)[status].icon;
  return <div className="statusContainer">
      <Icon />
      <span>{reviewsStatusIconsAndText(lang)[status].text}</span>
    </div>;
}
export function LanguagesExtraField({
  lang
}: {
  lang: ILanguage;
}) {
  return <div className="connectionChannelContainer">
      <span aria-label="Мова анкети">{`${lang.icon} ${lang.name}`}</span>
    </div>;
}
export function SourceExtraField({
  reviewId,
  sources
}: {
  reviewId: number;
  sources: {
    reviewId: number;
    value: string | null | undefined;
  }[];
}) {
  const source = sources?.find(item => item.reviewId === reviewId)?.value;
  return <div className="sourceContainer">
      {source ? <span className="sourceTableCell">{source}</span> : <div />}
    </div>;
}
export function EmailExtraField({
  answers
}: {
  answers: ApiReviewFormAnswer[];
}) {
  const emailAnswer = answers?.find(answer => answer.type === 'email')?.value?.split('|||')[0];
  const networksAnswer = answers?.find(answer => answer.type === 'networks')?.value?.includes('email');
  function getEmail() {
    let email: string | undefined;
    if (emailAnswer) {
      email = emailAnswer;
    } else if (networksAnswer) {
      const answer = answers?.find(answer => answer.type === 'networks')?.value?.split('|||')[0];
      email = answer?.split('_')[2];
    }
    return email;
  }
  return <div>{(emailAnswer || networksAnswer) && <span>{getEmail()}</span>}</div>;
}
export function UserNameExtraField({
  answers
}: {
  answers: ApiReviewFormAnswer[];
}) {
  const nameAnswer = answers?.find(answer => answer.type === 'name')?.value?.split('|||')[0];
  const networksAnswer = answers?.find(answer => answer.type === 'networks')?.value;
  const nameInNetworksAnswer = networksAnswer?.split('|||')[0]?.split('_')[3];
  function getName() {
    let name: string | undefined;
    if (nameAnswer) {
      name = nameAnswer;
    } else if (nameInNetworksAnswer) {
      name = nameInNetworksAnswer;
    }
    return name;
  }
  return <div>
      {(nameAnswer || nameInNetworksAnswer) && <span>{getName()}</span>}
    </div>;
}
export function PhoneNumberExtraField({
  answers
}: {
  answers: ApiReviewFormAnswer[];
}) {
  const phoneAnswer = answers?.find(answer => answer.type === 'phone' || answer.type === 'phone_number')?.value?.split('|||')[0];
  const networksAnswer = answers?.find(answer => answer.type === 'networks')?.value;
  const phoneInNetworksAnswer = networksAnswer?.split('|||')[0]?.split('_')[2];
  function getPhoneNumber() {
    let phone: string | undefined;
    if (phoneAnswer) {
      phone = phoneAnswer;
    } else if (phoneInNetworksAnswer) {
      phone = phoneInNetworksAnswer;
    }
    return phone;
  }
  return <div>
      {(phoneAnswer || phoneInNetworksAnswer) && <span>{getPhoneNumber()}</span>}
    </div>;
}
export function CommentExtraField({
  comment
}: {
  comment: string;
}) {
  return <div title={comment} className="commentContainer">
      <p className="commentField">{comment && truncate(comment, 36)}</p>
    </div>;
}
export function ParentNodeNameExtraField({
  parentNodeName
}: {
  parentNodeName: string | null | undefined;
}) {
  return <div className="parentNodeNameContainer">
      {parentNodeName && truncate(parentNodeName, 36)}
    </div>;
}
export function KeyQuestionAnswerField({
  answers
}: {
  answers: ApiReviewFormAnswer[];
}) {
  const keyExtraQuestionField = answers?.find(answer => answer.field.options.find(option => option.key === 'key_question')?.value === 'true');
  const answerValueExtraQuestion = keyExtraQuestionField?.value.split('|||')[0];
  return <div title={answerValueExtraQuestion} className="extraQuestionFieldAnswerContainer">
      <p className="extraQuestionFieldAnswer">
        {answerValueExtraQuestion && truncate(answerValueExtraQuestion, 36)}
      </p>
    </div>;
}
export const CategoryField = ({
  index,
  categories,
  setSelectedRow,
  isOpened
}: {
  index: string;
  isOpened: boolean;
  categories: ApiCategory[];
  setSelectedRow: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const setNewIndex = () => {
    const newIndex = isOpened ? undefined : index;
    setSelectedRow(`${newIndex}`);
  };
  return <div className="categoryContainer">
      {categories?.slice(0, 1)?.map(e => <p className="categoryName">{e.name}</p>)}
      {categories?.length > 1 && <p onClick={e => {
      setNewIndex();
      e.stopPropagation();
    }} className="categoryName">
          {isOpened ? '...' : `+${categories.length - 1}`}
          {isOpened && <div className="details">
              {categories.slice(1, categories.length).map(e => <p className="detail">{e.name}</p>)}
            </div>}
        </p>}
    </div>;
};
export function TagsField({
  tags,
  index,
  isOpened,
  setSelectedRow
}: {
  tags: ApiTag[];
  index: string;
  isOpened: boolean;
  setSelectedRow: Dispatch<SetStateAction<string | undefined>>;
}) {
  const setNewIndex = () => {
    const newIndex = isOpened ? undefined : index;
    setSelectedRow(`${newIndex}`);
  };
  return <div className="categoryContainer">
      {tags?.slice(0, 1)?.map(e => <p className="categoryName">{e.name}</p>)}
      {tags?.length > 1 && <p onClick={e => {
      setNewIndex();
      e.stopPropagation();
    }} className="categoryName">
          {isOpened ? '...' : `+${tags.length - 1}`}
          {isOpened && <div className="details">
              {tags.slice(1, tags.length).map(e => <p className="detail">{e.name}</p>)}
            </div>}
        </p>}
    </div>;
}
export function AttachmentField({
  answers
}: {
  answers: ApiReviewFormAnswer[];
}) {
  const counts: {
    [key: string]: number;
  } = {};
  const types: {
    [key: string]: string[];
  } = {
    audio: ['.mp3', '.wav', '.aac', '.m4a'],
    document: ['.pdf', '.doc', '.xlsx', '.csv', '.txt'],
    image: ['.png', '.jpg', '.heic', '.jpeg'],
    video: ['.mp4', '.mov']
  };
  answers.forEach(item => {
    let matched = false;
    for (const [type, extensions] of Object.entries(types)) {
      if (extensions.some(ext => item.value.endsWith(ext))) {
        if (!counts[type]) {
          counts[type] = 0;
        }
        counts[type]++;
        matched = true;
        break;
      }
    }
  });
  return <div className="attachmentContainer">
      {Object.entries(counts).map(item => {
      const Icon = reviewsFilesIcons[item[0]];
      return <div className="attachmentItem">
            <Icon width={16} height={16} color="#000" />
            <span> - {item[1]}</span>
          </div>;
    })}
    </div>;
}
export function SentimentField({
  sentiment
}: {
  sentiment: TSentiment;
}) {
  const Icon = sentiment ? sentimentIcon(sentiment.sentiment) : null;
  return <div className="sentimentContainer">
      {Icon && <Icon width={35} height={35} />}
    </div>;
}