export const addOneDay = (dateStr: string): string => {
  if (!dateStr) return dateStr
  const [day, month, year] = dateStr.split('-').map(Number);

  const date = new Date(year, month - 1, day);

  date.setDate(date.getDate() + 1);

  const newDay = String(date.getDate()).padStart(2, '0');
  const newMonth = String(date.getMonth() + 1).padStart(2, '0');
  const newYear = date.getFullYear();

  return `${newDay}-${newMonth}-${newYear}`;
}
