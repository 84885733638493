import styled from 'styled-components';

export const LayoutStyles = styled.div<{ grayMode: boolean, fontScale: number }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 62px 0 0 0;
  box-sizing: border-box;
  background: #eef3f7;
  filter: ${({ grayMode }) => (grayMode ? 'grayscale(100%)' : 'inherit')} !important;
  /* font-size-adjust: ${({ fontScale }) => fontScale}; */

  main {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;

    .mainBody {
      flex: 1;
      display: grid;
      grid-template-columns: auto 1fr;
      position: relative;
      height: 100%;

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }

    .contentWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: auto;
      max-height: calc(100vh - 62px);
    }
  }
`;
