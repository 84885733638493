import React, { useEffect, useRef, useState } from 'react';
import { QRControlStyles } from './styles';
import { ApiCompanyField, FieldType, companyApi, exportApi, fieldsApi } from '../../../api';
import { FilterSelectInput } from '../../molecules';
import { CheckMark } from '../../../assets';
import { useParams } from 'react-router-dom';
import { NameCustomField, SubmitButton } from '../../atoms';
import { useDebounce } from '../../../hooks';
import { useAppSelector } from '../../../state';
import { Table } from '../table';
import { QRPublishedNode } from '../../../api/entities/QRPublishedNode';
import { QRModal } from '../modal';
import { deduplicateArray } from '../editUser';
const limit = 10;
export const QRControl = () => {
  const filterParams = useRef<{
    [key: string]: number | string | string[] | number[];
  }>({});
  const [qrModal, setQrModal] = useState<boolean>(false);
  const [qrModalLink, setQrModalLink] = useState<string>('');
  const {
    id
  } = useParams();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  // const [loading, setLoading] = useState<boolean>(true);
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  // const [pageNumber, setPageNumber] = useState<number>(1);
  const setFilterParams = (params: any) => {};
  const campaignCountRef = useRef(0);
  const [qrPublishedNodes, setQrPublishedNodes] = useState<QRPublishedNode[]>([]);
  const [filterVariants, setFilterVariants] = useState<{
    [key: string]: {
      id: string;
      value: boolean;
      name: string;
      icon?: any;
    }[];
  }>();
  const [searchParams, setSearchParams] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const debouncedSearch = useDebounce(searchParams, 1000);
  useEffect(() => {
    if (!loading) {
      setQrPublishedNodes([]);
      setPageNumber(1);
      setReload(!reload);
    }
  }, [reQuery]);
  const applyFilters = (params: any) => {
    if (filterParams.current) {
      // @ts-ignore
      filterParams.current = params;
      setReQuery(e => !e);
    }
  };
  useEffect(() => {
    if (id) {
      setLoading(true);
      companyApi.getQrPublishedNodesFilters({
        id: +id
      }).then(res => {
        const filters: {
          [key: string]: {
            id: string;
            value: boolean;
            name: string;
            icon?: any;
          }[];
        } = {};
        // eslint-disable-next-line guard-for-in
        for (const key in res.data) {
          filters[key] = res.data[key].map((e: string) => ({
            id: e,
            value: false,
            name: e
          }));
        }
        setFilterVariants({
          ...filters,
          isQr: [{
            id: 'yes',
            value: false,
            name: 'Так'
          }, {
            id: 'no',
            value: false,
            name: 'Ні'
          }]
        });
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [id]);
  useEffect(() => {
    if (id) {
      setLoading(true);
      const isYes = (filterParams.current.isQr as string[])?.includes('yes') && !(filterParams.current.isQr as string[])?.includes('no');
      const isNo = (filterParams.current.isQr as string[])?.includes('no') && !(filterParams.current.isQr as string[])?.includes('yes');
      const isPublished = isYes ? {
        published: true
      } : isNo ? {
        published: false
      } : {};
      companyApi.getQrPublishedNodes({
        id: +id,
        offset: (pageNumber ? +pageNumber - 1 : 0) * 10,
        limit,
        filters: {
          ...filterParams.current,
          ...isPublished,
          ...{
            from: '01-01-2024',
            to: '31-12-2024',
            idfs: ['SN20000066', 'SN12000138']
          }
        }
      }).then(res => {
        setQrPublishedNodes(prevState => deduplicateArray([...prevState, ...res.data]));
        campaignCountRef.current = res.count ?? 0;
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [id, pageNumber, reload]);
  const openLink = (link: string) => window.open(link, '_blank');
  useEffect(() => {
    if (filterVariants) {
      setTableHeaders([{
        Header: '№',
        accessor: 'id',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: {
          row: {
            original: QRPublishedNode;
          };
        }) => <NameCustomField name={data.row.original?.id?.toString() ?? '-'} />
      }, {
        Header: 'IDF',
        accessor: 'idf',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <p>{row.original.idf}</p>
      }, {
        Header: <FilterSelectInput resizable search type="checkbox" initiaFilterlValues={filterVariants.name}
        // filtersOpenerStyles={{ minWidth: 220 }}
        columnName="name" title="Орган, що утворив" filterParams={filterParams.current as {
          [key: string]: any;
        }} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'name',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <NameCustomField name={row.original.name} />
      }, {
        Header: 'Назва фронт-офісу',
        accessor: 'node',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <NameCustomField name={row.original.nodeName} />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: <FilterSelectInput resizable search type="checkbox" initiaFilterlValues={filterVariants.state}
        // filtersOpenerStyles={{ minWidth: 220 }}
        columnName="state" title="Область" filterParams={filterParams.current as {
          [key: string]: any;
        }} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'state',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.state} />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: <FilterSelectInput resizable search type="checkbox" initiaFilterlValues={filterVariants.region}
        // filtersOpenerStyles={{ minWidth: 220 }}
        columnName="region" title="Район" filterParams={filterParams.current as {
          [key: string]: any;
        }} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'region',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.region} />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <FilterSelectInput filtersItemsWidth={180} type="checkbox" initiaFilterlValues={filterVariants.isQr}
        // filtersOpenerStyles={{ minWidth: 220 }}
        columnName="isQr" title="Розміщено QR" filterParams={filterParams.current as {
          [key: string]: any;
        }} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'published',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <div>
              {row.original.published ? <CheckMark /> : <SubmitButton onClick={() => {
            setQrModalLink(row.original.link);
            setQrModal(true);
          }}>
                  Подати звіт
                </SubmitButton>}
            </div>
      }]);
    }
  }, [qrPublishedNodes, filterVariants]);
  return <QRControlStyles>
      <div className="container">
        <div className="row">
          {id && <SubmitButton onClick={() => {
          exportApi.exportQrPublishedNodes({
            id: +id,
            filters: filterParams.current
          });
        }}>
              Export
            </SubmitButton>}
        </div>

        {tableHeaders && <Table columns={tableHeaders} data={qrPublishedNodes!} onClickToRow={data => {
        if (data.published) openLink(data.link);
      }} loading={loading} totalCount={campaignCountRef.current} currentPage={pageNumber} offset={10} pageNumberHandler={(value: number) => setPageNumber(value)} fixedHeader />}
      </div>
      {qrModal && <QRModal btnTitle="Link" title="Посилання на форму контролю розміщення QR" onClose={() => setQrModal(false)} url={qrModalLink} />}
    </QRControlStyles>;
};