import React, { useEffect, useRef, useState } from 'react';
import { PublicAnalyticStyles } from './styles';
import { Helmet } from 'react-helmet-async';
import { AnalyticsFilters, AnalyticsStartPage, ChartSelector, ComplexGeneralDashboard, CsiDashboard, CsiDetailedDashboard, CsiRegionRatingsDashboard, DissatisfactionDashboard, DynamicDashboard, InformationRespondentsDashboard, LocationsWithoutReviews, NpsCsiSatisfactionDashboard, NpsDetailedDashboard, RatingByReviewsDashboard, SubHeader, TransparentButton } from '../../components';
import { groups, TabKeys, tabs, TGroup } from './utils';
import { AnalyticFilterIcon, FullScreen, ListRegime, WrapRegime } from '../../assets';
import { useWindowSize } from 'usehooks-ts';
export const PublicAnalytic = React.memo(() => {
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [filters, setFilters] = useState<boolean>(false);
  const [regime, setRegime] = useState<string>('wrap');
  const [selectedTab, setSelectedTab] = useState<string>('');
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0
  });
  const [group, setGroup] = useState<TGroup>(groups[0]);
  const {
    width
  } = useWindowSize();
  const filterParamsRef = useRef<{
    [key: string]: number | string | string[] | number[] | boolean;
  }>({});
  const blockRef = useRef<HTMLDivElement>(null);
  const fullScreenRef = useRef<HTMLDivElement>(null);
  const handleRefreshFilter = () => {
    filterParamsRef.current = {
      companyID: filterParamsRef.current.companyID
    };
    setReQuery(!reQuery);
  };
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      fullScreenRef.current?.requestFullscreen().then(() => setFullScreen(true));
    } else {
      document.exitFullscreen().then(() => setFullScreen(false));
    }
  };
  const toggleFilters = () => {
    setFilters(!filters);
  };
  useEffect(() => {
    filterParamsRef.current = {
      companyID: 169
    };
    setReQuery(!reQuery);
  }, []);
  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        setDimensions({
          width: entry.contentRect.width,
          height: entry.contentRect.height
        });
      }
    });
    if (blockRef.current) {
      observer.observe(blockRef.current);
    }
    return () => {
      if (blockRef.current) {
        observer.unobserve(blockRef.current);
      }
    };
  }, [reQuery, fullScreen]);
  useEffect(() => {
    if (width > 1300) {
      setRegime('wrap');
    } else {
      setRegime('list');
    }
  }, [width]);
  useEffect(() => {
    const handleFullScreenChange = () => {
      console.log(document);
      setFullScreen(!!document.fullscreenElement);
    };
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  // useEffect(() => {
  //   setReQuery(!reQuery)
  // }, [fullScreen])

  return <PublicAnalyticStyles ref={fullScreenRef} className="analytics">
      <Helmet>
        <title>Аналітика</title>
      </Helmet>
      {selectedTab !== '' && <SubHeader strechChild title="">
          <div className="analytics-subheader">
            <div className="analytics-btns">
              <TransparentButton text="Повернутися до меню" handleClick={() => {
            setSelectedTab('');
            setFilters(false);
          }} filled />
              <TransparentButton text="Очистити фільтри" handleClick={handleRefreshFilter} extraStyles={{
            backgroundColor: '#767676',
            border: 'none'
          }} extraButtonTextStyles={{
            color: '#ffffff'
          }} />
            </div>
          </div>
        </SubHeader>}
      <div className={`analytics-wrapper ${fullScreen ? 'full-screen' : ''} ${selectedTab ? 'active-dashboard' : ''}`}>
        {filterParamsRef.current.companyID && <div className="analytics-header">
            <h2 className={`${selectedTab ? 'centered' : ''}`}>
              {selectedTab ? tabs.find(tab => tab.key === selectedTab)?.text! : group.description}
            </h2>
            <div className="analytics-actions">
              <button className={`analytics-fullscreen ${fullScreen ? 'active' : ''}`} onClick={toggleFullScreen} aria-label="Повноекранний режим" type="button">
                <FullScreen height={20} width={20} color="#7D7D7D" />
              </button>
              {!selectedTab && <button className={`analytics-regime ${regime === 'wrap' ? 'active' : ''}`} onClick={() => setRegime('wrap')} aria-label="Відобразити у вигляді плиток" type="button">
                  <WrapRegime height={20} width={20} />
                </button>}

              {!selectedTab && <button className={`analytics-regime ${regime === 'list' ? 'active' : ''}`} onClick={() => setRegime('list')} aria-label="Відобразити у вигляді списку" type="button">
                  <ListRegime height={20} width={20} />
                </button>}

              {!filters && selectedTab && <button className="analytics-filters" onClick={toggleFilters} aria-label="Обрати фільтри" type="button">
                  <AnalyticFilterIcon height={20} width={20} color="#7D7D7D" />
                </button>}
            </div>
          </div>}
        {filterParamsRef.current.companyID && <AnalyticsFilters companyId={+filterParamsRef.current.companyID} filterParams={filterParamsRef} reQuery={reQuery} filters={filters} setFilters={setFilters} setReQuery={setReQuery} setSelectedTab={setSelectedTab} selectedTab={selectedTab} handleRefreshFilter={handleRefreshFilter} />}
        {filterParamsRef.current.companyID && <div className="analytics-charts" ref={blockRef}>
            {selectedTab === TabKeys.csi && <CsiDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.csiDetailed && <CsiDetailedDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} fullScreen={fullScreen} />}
            {selectedTab === TabKeys.npsCsiSatisfaction && <NpsCsiSatisfactionDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.npsDetailed && <NpsDetailedDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} fullScreen={fullScreen} />}
            {selectedTab === TabKeys.informationRespondents && <InformationRespondentsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} fullScreen={fullScreen} />}
            {selectedTab === TabKeys.csiRegionRatings && <CsiRegionRatingsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.ratingReviewsOne && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascCategories="I категорія (100 тис. осіб і більше)" ascTypes={['ЦНАП', 'Мобільний ЦНАП', 'ТП', 'Староста']} />}
            {selectedTab === TabKeys.ratingReviewsTwo && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascCategories="II категорія (від 50 до 100 тис.осіб)" ascTypes={['ЦНАП', 'Мобільний ЦНАП', 'ТП', 'Староста']} />}
            {selectedTab === TabKeys.ratingReviewsThree && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascCategories="III категорія (від 20 до 50 тис.осіб)" ascTypes={['ЦНАП', 'Мобільний ЦНАП', 'ТП', 'Староста']} />}
            {selectedTab === TabKeys.ratingReviewsFour && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascCategories="IV категорія (від 10 до 20 тис.осіб)" ascTypes={['ЦНАП', 'Мобільний ЦНАП', 'ТП', 'Староста']} />}
            {selectedTab === TabKeys.ratingReviewsFive && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascCategories="V категорія (від 5 до 10 тис.осіб)" ascTypes={['ЦНАП', 'Мобільний ЦНАП', 'ТП', 'Староста']} />}
            {selectedTab === TabKeys.ratingReviewsSix && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascCategories="VI категорія (менше 5 тис.осіб)" ascTypes={['ЦНАП', 'Мобільний ЦНАП', 'ТП', 'Староста']} />}
            {selectedTab === TabKeys.ratingVrmReviews && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascTypes={['ВРМ']} />}
            {selectedTab === TabKeys.locationWithoutReviews && <LocationsWithoutReviews filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.locationDissatisfaction && <DissatisfactionDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} divisionData="location-dissatisfaction" divisionPie="location-dissatisfaction-pie" />}
            {selectedTab === TabKeys.generalProcessDissatisfaction && <DissatisfactionDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} divisionData="general-process-dissatisfaction" divisionPie="general-process-dissatisfaction-pie" />}
            {selectedTab === TabKeys.staffDissatisfaction && <DissatisfactionDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} divisionData="staff-dissatisfaction" divisionPie="staff-dissatisfaction-pie" />}
            {selectedTab === TabKeys.servicesList && <DissatisfactionDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} divisionData="services-list-dissatisfaction" divisionPie="services-list-dissatisfaction-pie" />}
            {selectedTab === TabKeys.complexGeneral && <ComplexGeneralDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} group={group} />}
            {selectedTab === TabKeys.dynamic && <DynamicDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {!selectedTab && <AnalyticsStartPage setSelectedTab={setSelectedTab} tabs={tabs} group={group} regime={regime} />}
          </div>}
        {filterParamsRef.current.companyID && selectedTab && <ChartSelector setSelectedTab={setSelectedTab} charts={tabs} group={group} selectedTab={selectedTab} />}
      </div>
    </PublicAnalyticStyles>;
});