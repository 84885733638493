export type TOption = {
  value: boolean;
  name: string;
  id: string;
};

export type TQuestion = {
  value: boolean;
  name: string;
  answers?: TOption[];
};

export const initialScoreValues: TOption[] = new Array(11)
  .fill(1)
  .map((item, index) => ({
    value: false,
    name: index.toString(),
    id: index.toString(),
  }));

export const initialAgeValues = new Array(70).fill(1).map((item, index) => ({
  id: index.toString(),
  value: false,
  name: index.toString(),
}));

export const initialGenderValues = [
  {
    id: '1',
    value: false,
    name: 'Male',
  },

  {
    id: '2',
    value: false,
    name: 'Female',
  },
  {
    id: '3',
    value: false,
    name: 'Emale',
  },
  {
    id: '4',
    value: false,
    name: 'Transgender',
  },
  {
    id: '5',
    value: false,
    name: 'Beesexual',
  },
  {
    id: '6',
    value: false,
    name: 'Queer',
  },
];

export const initialCnapValues = [
  {
    id: 'CNAP',
    value: false,
    name: 'ЦНАП',
  },
  {
    id: 'SNAP',
    value: false,
    name: 'СНАП',
  },
];

export const initialTypeValues = [
  {
    id: 'Староста',
    value: false,
    name: 'Староста',
  },
  {
    id: 'ВРМ',
    value: false,
    name: 'ВРМ',
  },
  {
    id: 'ЦНАП',
    value: false,
    name: 'ЦНАП',
  },
];

export const initialServicesTypeValues = [
  {
    id: '1',
    value: false,
    name: 'Послуга 1',
  },
  {
    id: '2',
    value: false,
    name: 'Послуга 2',
  },
];

export const initialAnswersValues: TQuestion[] = [
  {
    value: false,
    name: 'Питання 1',
    answers: [
      {
        id: '1',
        value: false,
        name: 'Відповідь 1.1',
      },
      {
        id: '11',
        value: false,
        name: 'Відповідь 1.2',
      },
    ],
  },
  {
    value: false,
    name: 'Питання 2',
    answers: [
      {
        id: '12',
        value: false,
        name: 'Відповідь 2.1',
      },
      {
        id: '122',
        value: false,
        name: 'Відповідь 2.2',
      },
    ],
  },
  {
    value: false,
    name: 'Питання 3, gidgbsdfiuds jfnsdfidshfjdsbnf dsifjdskfndsfjs dkfbndshj fkbn dsf hsdkjfbn dsfhdskfjbdsnfd shfkbdsnfhkd sbnfhdjskf b nshjfb dsnfhjdsbfsdnfsd ?',
    answers: [
      {
        id: '132',
        value: false,
        name: 'Відповідь 3.1',
      },
      {
        id: '1231',
        value: false,
        name: 'Відповідь 3.2',
      },
    ],
  },
];
