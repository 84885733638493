import styled from 'styled-components';

export const InformationRespondentsDashboardStyles = styled.div<{ fullScreen: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .charts {
    height: 100%;
    width: 100%;
    display: grid;
    max-width: 1400px;
    aspect-ratio: 4 / 3;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1.1fr 1.1fr 1.1fr 1.1 0.9fr 0.9fr 0.9fr 0.9fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1.15fr 0.85fr 1fr;
    gap: 24px;
    grid-template-areas:
      'one one one one two two two two three three three three'
      'one one one one two two two two three three three three'
      'four four four four four four four four five five five five'
      'four four four four four four four four five five five five';

    @media screen and (max-width: 1500px) {
      gap: 8px;
    }

    &-item {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: var(--sm-1);
      gap: 8px;
      border: 1px solid #dee6eb;
      border-radius: 10px;

      @media screen and (max-width: 1500px) {
        padding: var(--sm-7);
      }

      h4 {
        font-weight: 400;
        font-size: var(--fs-4);
        line-height: 115%;
        text-align: center;
        color: #000000;
        padding-bottom: 10px;
        border-bottom: 1px solid #dee6eb;

        @media screen and (max-width: 1500px) {
          padding-bottom: 5px;
        }
      }
    }

    &-one,
    &-two,
    &-three {
      min-height: ${({ fullScreen }) => (fullScreen ? 'none' : '220px')};
      max-height: 320px;
    }

    &-five, &-four {
      min-height: ${({ fullScreen }) => (fullScreen ? 'none' : '250px')};
      max-height: 320px;
    }

    &-one {
      grid-area: one;
    }

    &-two {
      grid-area: two;
    }

    &-three {
      grid-area: three;
    }

    &-four {
      grid-area: four;
    }

    &-five {
      grid-area: five;
    }
  }

  .bars {
    display: flex;
    align-items: flex-end;
    gap: 35px;
    flex: 1;

    @media screen and (max-width: 1500px) {
      gap: 8px;
    }

    &-item {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 15px;

      @media screen and (max-width: 1500px) {
        gap: 8px;
      }

      h6 {
        font-weight: 400;
        font-size: var(--fs-5);
        line-height: 115%;
        text-align: center;
        color: #000000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 64px;
      }
    }

    &-wrapper {
      flex: 1;
      display: flex;
      align-items: flex-end;
      gap: 4px;
      width: 100%;
      height: 100%;
      max-width: 200px;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        width: 115%;
        height: 1px;
        bottom: 0;
        transform: translate(-50%);
        left: 50%;
        background: #dee6eb;
      }
    }

    &-fill {
      flex: 1;
      position: relative;
      width: 100%;
      height: 100%;
      max-width: 200px;

      div {
        border-radius: 100px 100px 0 0px;
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        width: 100%;
        max-width: 40px;
      }

      span {
        font-weight: 700;
        font-size: var(--fs-5);
        line-height: 115%;
        color: #000000;
        opacity: 0.8;
        position: absolute;
        z-index: 3;
        transform: translate(-50%, -50%) rotate(-90deg);
        bottom: 20px;
        left: 50%;
      }
    }
  }

  .legend {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--sm-7);
    flex-wrap: wrap;

    &-item {
      display: flex;
      align-items: center;
      gap: 3px;
      max-width: 120px;

      p {
        font-weight: 400;
        font-size: var(--fs-6);
        line-height: 115%;
        color: #000000;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        overflow: hidden;
      }

      div {
        border-radius: 50%;
        width: 8px;
        height: 8px;
        flex-shrink: 0;
      }
    }
  }

  .pies {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    height: 100%;
    padding-bottom: 25px;
  }
`;
