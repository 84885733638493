import { ClientCampaignStyles } from './styles';
import { deduplicateArray, FilterSelectInput, NameCustomField, Table } from '../..';
import React, { FC, RefObject, useEffect, useRef, useState } from 'react';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { campaignApi } from '../../../api/campaign/CampaignApi';
import { ApiCampaign } from '../../../api/entities/ApiCampaigns';
import format from 'date-fns/format';
import { extraFieldsSettings } from './utils';
type TClientCampaign = {
  filterParams: RefObject<{
    [key: string]: number | string | any;
  }>;
  companyID: number;
  mainReQuery: boolean;
};
export const ClientCampaign: FC<TClientCampaign> = ({
  filterParams,
  companyID,
  mainReQuery
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [campaignValues, setCampaigns] = useState<ApiCampaign[]>([]);
  const [filterVariants, setFilterVariants] = useState<{
    [key: string]: {
      id: string;
      value: boolean;
      name: string;
      icon?: any;
    }[];
  }>();
  const setFilterParams = (params: any) => {
    if (filterParams.current) {
      // @ts-ignore
      filterParams.current = params;
      setReQuery(e => !e);
    }
  };
  const campaignCountRef = useRef(0);
  const applyFilters = (params: any) => {
    if (filterParams.current) {
      // @ts-ignore
      filterParams.current = params;
      setReQuery(e => !e);
    }
  };
  useEffect(() => {
    if (!loading) {
      setCampaigns([]);
      setPageNumber(1);
      setReload(!reload);
    }
  }, [reQuery, mainReQuery, companyID]);
  useEffect(() => {
    if (filterParams.current && companyID) {
      setLoading(true);
      campaignApi.getCampaignDataByCompany(companyID, {
        ...(filterParams.current.createdAtFrom ? {
          from: filterParams.current.createdAtFrom
        } : {}),
        ...(filterParams.current.createdAtTo ? {
          to: filterParams.current.createdAtTo
        } : {}),
        ...(filterParams.current.parentName ? {
          parentName: filterParams.current.parentName
        } : {}),
        ...(filterParams.current.region ? {
          region: filterParams.current.region
        } : {}),
        ...(filterParams.current.serviceName ? {
          serviceName: filterParams.current.serviceName
        } : {}),
        ...(filterParams.current.clientGender ? {
          clientGender: filterParams.current.clientGender
        } : {}),
        ...(filterParams.current.serviceResult ? {
          serviceResult: filterParams.current.serviceResult
        } : {}),
        ...(filterParams.current.serviceStatus ? {
          serviceStatus: filterParams.current.serviceStatus
        } : {}),
        ...(filterParams.current.idf ? {
          idf: filterParams.current.idf
        } : {}),
        ...(filterParams.current.serviceId ? {
          serviceId: filterParams.current.serviceId
        } : {}),
        ...(filterParams.current.clientPhone && filterParams.current.clientPhone.length < 2 ? {
          hasPhone: filterParams.current.clientPhone[0] === '1'
        } : {}),
        ...(filterParams.current.clientEmail && filterParams.current.clientEmail.length < 2 ? {
          hasEmail: filterParams.current.clientEmail[0] === '1'
        } : {})
      }, (pageNumber ? +pageNumber - 1 : 0) * 10, 10).then(res => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          setCampaigns(prev => deduplicateArray([...prev, ...res.data]));
          campaignCountRef.current = res.count ? res.count : 0;
          setLoading(false);
        }
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [pageNumber, reload]);
  useEffect(() => {
    campaignApi.getCampaignFilters(companyID).then(res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        const filters: {
          [key: string]: {
            id: string;
            value: boolean;
            name: string;
            icon: any;
          }[];
        } = {};
        Object.keys(res.data).forEach(key => {
          // @ts-ignore
          filters[key] = res.data[key].map((e: string) => ({
            id: e,
            value: false,
            name: e
          }));
        });
        setFilterVariants({
          ...filters,
          clientPhone: [{
            id: '1',
            value: false,
            name: 'З номером телефону'
          }, {
            id: '2',
            value: false,
            name: 'Без номеру телефона'
          }],
          clientEmail: [{
            id: '1',
            value: false,
            name: 'З email'
          }, {
            id: '2',
            value: false,
            name: 'Без email'
          }]
        });
      }
    });
  }, [companyID]);
  useEffect(() => {
    setTableHeaders([{
      Header: '№',
      accessor: 'id',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={data.row.original.id} />
    }, {
      Header: getTranslationByLangOrEng(interfaceLanguage, 'campaign_table_header_node'),
      accessor: 'tsnap',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => {
        let fullName = data.row.original.tsnap;
        const spl = fullName.split(';');
        if (spl.length === 3) {
          fullName = [spl[0], spl[2]].join(';');
        }
        return <NameCustomField name={fullName} />;
      }
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <FilterSelectInput filtersItemsWidth={320} resizable search type="checkbox" initiaFilterlValues={filterVariants?.parentName} filtersOpenerStyles={{
        minWidth: 120
      }} columnName="parentName" title="Орган" filterParams={filterParams.current as {
        [key: string]: any;
      }} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
      accessor: 'parentName',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={data.row.original.state?.name} />
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <FilterSelectInput resizable search type="checkbox" initiaFilterlValues={filterVariants?.region} filtersOpenerStyles={{
        minWidth: 220
      }} columnName="region" title={getTranslationByLangOrEng(interfaceLanguage, 'campaign_table_header_district')} filterParams={filterParams.current as {
        [key: string]: any;
      }} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
      accessor: 'region',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={data.row.original.region} />
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <FilterSelectInput resizable search type="checkbox" initiaFilterlValues={filterVariants?.idf} filtersOpenerStyles={{
        minWidth: 170
      }} columnName="idf" title="IDF" filterParams={filterParams.current as {
        [key: string]: any;
      }} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
      accessor: 'idf',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={data.row.original.idf} />
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <FilterSelectInput type="dataInterval" filtersOpenerStyles={{
        minWidth: 100
      }} columnName="createdAt" title={getTranslationByLangOrEng(interfaceLanguage, 'campaign_table_header_visited_at')} filterParams={filterParams.current as {
        [key: string]: any;
      }} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
      accessor: 'visited_at',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={format(new Date(data.row.original.visitedAt), 'dd.MM.yyyy HH:mm')} />
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <FilterSelectInput resizable search type="checkbox" initiaFilterlValues={filterVariants?.serviceId} filtersOpenerStyles={{
        minWidth: 180
      }} columnName="serviceId" title={getTranslationByLangOrEng(interfaceLanguage, 'campaign_table_header_service_id')} filterParams={filterParams.current as {
        [key: string]: any;
      }} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
      accessor: 'serviceId',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={data.row.original.serviceId} />
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <FilterSelectInput search resizable type="checkbox" initiaFilterlValues={filterVariants?.serviceName} filtersOpenerStyles={{
        minWidth: 220
      }} columnName="serviceName" title={getTranslationByLangOrEng(interfaceLanguage, 'campaign_table_header_service_name')} filterParams={filterParams.current as {
        [key: string]: any;
      }} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
      accessor: 'serviceName',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={data.row.original.serviceName} />
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <FilterSelectInput resizable search type="checkbox" initiaFilterlValues={filterVariants?.serviceResult} filtersOpenerStyles={{
        minWidth: 220
      }} columnName="serviceResult" title={getTranslationByLangOrEng(interfaceLanguage, 'campaign_table_header_service_result')} filterParams={filterParams.current as {
        [key: string]: any;
      }} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
      accessor: 'serviceResult',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={data.row.original.serviceResult} />
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <FilterSelectInput search type="checkbox" initiaFilterlValues={filterVariants?.serviceStatus} filtersOpenerStyles={{
        minWidth: 220
      }} columnName="serviceStatus" title="Тип користувача" filterParams={filterParams.current as {
        [key: string]: any;
      }} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
      accessor: 'service_status',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={data.row.original.serviceStatus} />
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: getTranslationByLangOrEng(interfaceLanguage, 'campaign_table_header_service_in_time'),
      accessor: 'serviceIntime',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        row
      }: any) => <NameCustomField name={row.original.serviceInTime === null ? '-' : row.original.serviceInTime ? 'Вчасно' : 'Невчасно'} />
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <FilterSelectInput
      // search
      type="checkbox" initiaFilterlValues={filterVariants?.clientPhone} filtersOpenerStyles={{
        minWidth: 220
      }} columnName="clientPhone" title="Номер телефону" filterParams={filterParams.current as {
        [key: string]: any;
      }} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
      accessor: 'clientPhone',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={data.row.original.clientPhone} />
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <FilterSelectInput
      // search
      type="checkbox" initiaFilterlValues={filterVariants?.clientEmail} filtersOpenerStyles={{
        minWidth: 220
      }} columnName="clientEmail" title="Email" filterParams={filterParams.current as {
        [key: string]: any;
      }} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
      accessor: 'clientEmail',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={data.row.original.clientEmail} />
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <FilterSelectInput type="checkbox" initiaFilterlValues={filterVariants?.clientGender} filtersOpenerStyles={{
        minWidth: 220
      }} columnName="clientGender" title="Стать" filterParams={filterParams.current as {
        [key: string]: any;
      }} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
      accessor: 'clientGender',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={data.row.original.clientGender} />
    }]);
  }, [campaignValues, filterVariants]);
  return <ClientCampaignStyles>
      {tableHeaders && <Table columns={tableHeaders} data={campaignValues!} extraFieldsSettings={extraFieldsSettings} loading={loading} totalCount={campaignCountRef.current} currentPage={pageNumber} offset={10} pageNumberHandler={value => setPageNumber(value)} fixedHeader countLabel="tsnap" />}
    </ClientCampaignStyles>;
};