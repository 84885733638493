import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { CsiDetailedDashboardStyles } from './styles';
import { setSaveFilters, setSaveLink, useAppDispatch, useAppSelector } from '../../../../state';
import { analyticsApi, ApiGroupedCsi, TPostGroupedCsi } from '../../../../api';
import { Loader } from '../../../atoms';
import { addOneDay } from '../../../../utils';
const ageLabel: string[] = ['14-18', '19-25', '26-35', '36-45', '46-60', 'Більше 60'];
const statusLabel: string[] = ['Фіз. особа', 'ФОП', 'Юр.особа'];
const colors: string[] = ['#CFC4FF', '#F2C3DB', '#A7C4E9', '#6EAE93', '#F5EF67'];
type TCsiDetailedDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
  fullScreen: boolean;
};
export const CsiDetailedDashboard: FC<TCsiDetailedDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions,
  fullScreen
}) => {
  const {
    data
  } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const [csiData, setCsiData] = useState<ApiGroupedCsi | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      sourceIDs,
      cnapTypes,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostGroupedCsi = {
      from: from as string,
      to: addOneDay(to as string),
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean,
      sourceIDs: sourceIDs as number[],
      fieldIDs: [858866],
      positiveAnswers: ['Чудово', 'Добре'],
      groupsBy: [{
        fieldID: 858829
      }, {
        fieldID: 858830
      }, {
        fieldID: 858831
      }, {
        metaKey: 'created_by_type'
      }, {
        metaKey: 'asc_type'
      }]
    };
    setLoading(true);
    analyticsApi.getGroupedCsi(+companyID, filteredData, !data).then(res => {
      if (res.statusCode === 200) {
        setCsiData(res.data);
        setLoading(false);
      }
    });
    dispatch(setSaveLink('analytics/grouped/csi/file'));
    dispatch(setSaveFilters({
      from: from as string,
      to: addOneDay(to as string),
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      sourceIDs: sourceIDs as number[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean,
      fieldIDs: [858866],
      positiveAnswers: ['Чудово', 'Добре'],
      groupsBy: [{
        fieldID: 858829
      }, {
        fieldID: 858830
      }, {
        fieldID: 858831
      }, {
        metaKey: 'created_by_type'
      }, {
        metaKey: 'asc_type'
      }]
    }));
  }, [reQuery]);
  if (loading) {
    return <CsiDetailedDashboardStyles fullScreen={fullScreen}>
        <Loader />
      </CsiDetailedDashboardStyles>;
  }
  return <CsiDetailedDashboardStyles fullScreen={fullScreen}>
      {csiData && <div className="charts">
          <div className="charts-item charts-one">
            <h4>CSI</h4>
            <div className="bars">
              <div className="bars-item">
                <div className="bars-fill">
                  <div style={{
                background: colors[0],
                height: `${csiData.total}%`
              }} />
                  <span>{csiData.total.toFixed(1)}%</span>
                </div>
                <h6>Україна</h6>
              </div>
            </div>
          </div>
          <div className="charts-item charts-two">
            <h4>CSI стать</h4>
            <div className="bars">
              {csiData.groups[0].filter(e => e.groupName.length).map((el, index) => <div key={el.groupName} className="bars-item">
                    <div className="bars-fill">
                      <div style={{
                background: el.groupName === 'Чоловік' ? colors[2] : colors[1],
                height: `${el.csi}%`
              }} />
                      <span>{el.csi.toFixed(1)}%</span>
                    </div>
                    <h6 title={el.groupName}>{el.groupName}</h6>
                  </div>)}
            </div>
          </div>
          <div className="charts-item charts-three">
            <h4>CSI вікова категорія</h4>
            <div className="bars">
              {csiData.groups[1].filter(e => e.groupName.length).slice().sort((a, b) => ageLabel.indexOf(a.groupName) - ageLabel.indexOf(b.groupName)).map((el, index) => <div key={el.groupName} className="bars-item">
                    <div className="bars-fill">
                      <div style={{
                background: colors[3],
                height: `${el.csi}%`
              }} />
                      <span>{el.csi.toFixed(1)}%</span>
                    </div>
                    <h6 title={el.groupName}>{el.groupName}</h6>
                  </div>)}
            </div>
          </div>
          <div className="charts-item charts-four">
            <h4>CSI статус</h4>
            <div className="bars">
              {csiData.groups[2].filter(e => e.groupName.length).slice().sort((a, b) => statusLabel.indexOf(a.groupName) - statusLabel.indexOf(b.groupName)).map((el, index) => <div key={el.groupName} className="bars-item">
                    <div className="bars-fill">
                      <div style={{
                background: colors[4],
                height: `${el.csi}%`
              }} />
                      <span>{el.csi.toFixed(1)}%</span>
                    </div>
                    <h6 title={el.groupName}>{el.groupName}</h6>
                  </div>)}
            </div>
          </div>
          <div className="charts-item charts-five">
            <h4>CSI тип органу, що утворив центр</h4>
            <div className="horizontal">
              {csiData.groups[3].filter(e => e.groupName.length).sort((a, b) => b.csi - a.csi).map((el, index) => <div key={el.groupName} className="horizontal-item">
                    <h6 title={el.groupName}>{el.groupName}</h6>
                    <div className="horizontal-fill">
                      <span>{el.csi.toFixed(1)}%</span>
                      <div style={{
                background: colors[0],
                width: `${el.csi}%`
              }} />
                    </div>
                  </div>)}
            </div>
          </div>
          <div className="charts-item charts-six">
            <h4>CSI тип центру</h4>
            <div className="horizontal">
              {csiData.groups[4].filter(e => e.groupName.length).sort((a, b) => b.csi - a.csi).map((el, index) => <div key={el.groupName} className="horizontal-item">
                    <h6 title={el.groupName}>{el.groupName}</h6>
                    <div className="horizontal-fill">
                      <span>{el.csi.toFixed(1)}%</span>
                      <div style={{
                background: colors[2],
                width: `${el.csi}%`
              }} />
                    </div>
                  </div>)}
            </div>
          </div>
        </div>}
    </CsiDetailedDashboardStyles>;
};