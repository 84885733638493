import styled from 'styled-components';

export const DropdownFilterStyles = styled.div<{
  width?: number;
  filterWidth?: number;
}>`
  font-weight: 400;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  background: #fff;
  position: absolute;
  top: 100%;
  left: -${({ width, filterWidth }) => (width && filterWidth ? width / 2 - filterWidth / 2 : 0)}px;
  z-index: 99;
  width: 270px;
  padding: var(--sm-4);
  border-radius: 4px;

  @media screen and (max-width: 1500px) {
    position: fixed;
    z-index: 99;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
