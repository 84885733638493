import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { AnalyticsStartPageStyles } from './styles';
import { TGroup, TTab } from '../../../pages';
import { ArrowLink } from '../../../assets';
import { usePermissionView } from '../../../hooks';
import { useAppSelector } from '../../../state';
import { RegistrationStatus } from '../../../constants/registrationStatuses';
type TAnalyticsStartPage = {
  setSelectedTab: Dispatch<SetStateAction<string>>;
  tabs: TTab[];
  regime: string;
  group: TGroup;
};
export const AnalyticsStartPage: FC<TAnalyticsStartPage> = ({
  setSelectedTab,
  tabs,
  regime,
  group
}) => {
  const hasAnyAccess = usePermissionView();
  const {
    data
  } = useAppSelector(state => state.user);
  const handleSelect = (tab: string) => {
    setSelectedTab(tab);
  };
  return <AnalyticsStartPageStyles>
      <div className={`links ${regime}`}>
        {tabs.filter(tab => (hasAnyAccess(tab.key).view || !data) && group.dashboards.includes(tab.position)).map((el, index) => <div key={el.key} className={`links-item ${regime}`} onClick={() => handleSelect(el.key)}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0} onKeyDown={e => {
        if (e.key === 'Enter') {
          handleSelect(el.key);
        }
      }} aria-label={el.text}>
            <span>{el.text}</span>
          </div>)}
      </div>
    </AnalyticsStartPageStyles>;
};