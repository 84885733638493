import React, { useEffect, useRef, useState } from 'react';
import { TableStyles } from './styles';
import { useExpanded, usePagination, useSortBy, useTable } from 'react-table';
import { TablePagination } from './tablePagination';
import { ArrowDownIcon } from '../../../assets';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '../../atoms';
import { TableProps } from './TableTypes';
export const Table = React.memo(({
  data,
  columns,
  hideFieldsSplitters,
  pagination,
  extraFieldsSettings,
  headerColumnCounts,
  sortableColumns,
  hiddenHeaders,
  fullWidthColumns,
  onClickToRow,
  rowTitle,
  pageSize,
  setPageSize,
  currentPage,
  rowsQuantity,
  pageNumberHandler,
  totalCount,
  offset,
  loading,
  fixedHeader,
  countLabel
}: TableProps) => {
  const [tableWidth, setTableWidth] = useState<number>(0);
  const tableRef = useRef<HTMLTableElement>(null);
  const infiniteScrollRef = useRef<HTMLDivElement>(null);
  const checkIfMoreFields = () => {
    if (infiniteScrollRef.current && tableRef.current && data && pageNumberHandler && currentPage && totalCount) {
      const infiniteScrollHeight = infiniteScrollRef.current.clientHeight;
      const tableListHeight = tableRef.current.clientHeight;
      if (infiniteScrollHeight >= tableListHeight && data.length < totalCount) {
        pageNumberHandler(currentPage + 1);
      }
    }
  };
  useEffect(() => {
    if (data?.length && !loading) {
      checkIfMoreFields();
    }
  }, [data, totalCount, loading]);
  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page
  } = useTable({
    columns,
    data: data || [],
    initialState: {
      pageIndex: 0,
      pageSize: pageSize || 10
    }
  }, useSortBy, useExpanded, usePagination);
  useEffect(() => {
    if (tableRef.current) {
      setTableWidth(tableRef.current.offsetWidth);
    }
    const handleResize = () => {
      if (tableRef.current) {
        setTableWidth(tableRef.current.offsetWidth);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const renderTableHeader = () => <thead>
        {headerGroups.map((headerGroup, index) => <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => {
        const columnItem = (column.getHeaderProps().key as string).split('_')[1];
        const columnSettings = extraFieldsSettings?.[columnItem];
        return <th {...column.getHeaderProps(sortableColumns?.includes(columnItem) || sortableColumns === 'all' ? column.getSortByToggleProps() : undefined)} style={{
          width: columnSettings?.width ?? 'auto',
          minWidth: columnSettings?.minWidth !== undefined ? `${tableWidth * columnSettings.minWidth}px` : 'none'
        }} key={columnItem}>
                  <div className="fieldContent" style={{
            justifyContent: extraFieldsSettings?.[columnItem]?.justifyContent
          }}>
                    <span>
                      {hiddenHeaders?.includes(columnItem) ? '' : column.render('Header')}
                      {headerColumnCounts?.[columnItem] ? ` (${headerColumnCounts?.[columnItem]})` : ''}

                      {countLabel === columnItem && ` (${totalCount})`}
                    </span>
                    {!hiddenHeaders?.includes(columnItem) && (sortableColumns?.includes(columnItem) || sortableColumns === 'all') && <div className="arrows">
                          <div className={column.isSortedDesc ? 'arrowUp active' : 'arrowUp'}>
                            <ArrowDownIcon />
                          </div>
                          <div className={column.isSorted && !column.isSortedDesc ? 'arrowDown active' : 'arrowDown'}>
                            <ArrowDownIcon />
                          </div>
                        </div>}
                  </div>
                </th>;
      })}
          </tr>)}
      </thead>;
  const renderTableBody = () => <tbody {...getTableBodyProps()}>
        {(pagination ? page : rows).length === 0 && <tr className="emptyRow">
            <td colSpan={columns.length}>
              <p>
                Список порожній
              </p>
            </td>
          </tr>}

        {(pagination ? page : rows).map((row, index) => {
      prepareRow(row);
      return <React.Fragment key={index}>
              <tr title={rowTitle || ''} onClick={() => onClickToRow ? onClickToRow(row.original) : ''} {...row.getRowProps()} className={onClickToRow ? 'clickableRow' : ''} tabIndex={0} onKeyDown={e => {
          if (e.key === 'Enter' && onClickToRow) {
            onClickToRow(row.original);
          }
        }}>
                {row.cells.map(cell => {
            const column = (cell.getCellProps().key as string).split('_')[2];
            const columnSettings = extraFieldsSettings?.[column];
            return <td {...cell.getCellProps()} style={{
              width: columnSettings?.width ?? 'auto',
              minWidth: columnSettings?.minWidth !== undefined ? `${tableWidth * columnSettings.minWidth}px` : 'none'
            }}>
                      <div className="fieldContent" style={{
                justifyContent: extraFieldsSettings?.[column]?.justifyContent,
                alignItems: extraFieldsSettings?.[column]?.alignItems,
                width: fullWidthColumns?.includes(column) ? '100%' : 'auto'
              }}>
                        {cell.render('Cell')}
                      </div>
                    </td>;
          })}
              </tr>
            </React.Fragment>;
    })}
      </tbody>;
  return <TableStyles hideFieldsSplitters={!!hideFieldsSplitters}>
        <div className="tableContainer" id="tableContainer" ref={infiniteScrollRef}
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    tabIndex={0} aria-label="Таблиця">
          <InfiniteScroll dataLength={data ? data.length : 0} style={{
        height: 'auto',
        overflow: 'visible'
      }} next={() => {
        if (pageNumberHandler && currentPage) {
          pageNumberHandler(currentPage + 1);
        }
      }} hasMore={currentPage && offset && totalCount ? currentPage * offset < totalCount : false} scrollableTarget="tableContainer" loader={loading ? <Loader margin={10} /> : null}>
            <table ref={tableRef} style={{
          minWidth: columns.length > 6 ? '1400px' : 'none'
        }} className={fixedHeader ? 'fixed' : ''}>
              {renderTableHeader()}
              {renderTableBody()}
            </table>
          </InfiniteScroll>
        </div>

        {pagination && pageSize !== undefined && currentPage !== undefined && rowsQuantity !== undefined && pageNumberHandler !== undefined && <TablePagination pageSize={pageSize} setPageSize={setPageSize} currentPage={currentPage} pageNumberHandler={pageNumberHandler} rowsQuantity={rowsQuantity} />}
      </TableStyles>;
});