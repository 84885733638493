import styled from 'styled-components';

export const QRPublishingPieDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;

  .charts {
    height: 100%;
    width: 100%;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    gap: 24px;
    grid-template-areas:
      'one two four four four'
      'three three four four four'
      'three three four four four'
      '. . four four four'
      '. . four four four';

    @media screen and (max-width: 1500px) {
      gap: 8px;
    }

    &-item {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 24px;
      gap: 8px;
      border: 1px solid #dee6eb;
      border-radius: 10px;

      @media screen and (max-width: 1500px) {
        padding: 12px;
      }

      h4 {
        font-weight: 400;
        font-size: var(--fs-4);
        line-height: 115%;
        text-align: center;
        color: #000000;
        padding-bottom: 10px;
        border-bottom: 1px solid #dee6eb;

        @media screen and (max-width: 1500px) {
          padding-bottom: 5px;
        }
      }
    }

    &-one {
      grid-area: one;
    }

    &-two {
      grid-area: two;
    }

    &-three {
      grid-area: three;
      min-height: 280px;
    }

    &-four {
      grid-area: four;
    }

    &-five {
      grid-area: five;
    }
  }

  .pies {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    height: calc(100% - 50px);
  }

  .counts {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    h2 {
      font-weight: 400;
      font-size: var(--fs-2);
      line-height: 115%;
      text-align: center;
      color: #000000;

      @media screen and (max-width: 1500px) {
        font-size: var(--fs-3);
      }
    }
  }

  .legend {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex-wrap: wrap;

    &-item {
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-weight: 400;
        font-size: var(--fs-5);
        line-height: 115%;
        color: #000000;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        overflow: hidden;
      }

      div {
        border-radius: 50%;
        width: 10px;
        height: 10px;
        flex-shrink: 0;
      }
    }
  }

  .bars {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 5px;
    position: relative;
    overflow: auto;

    &-item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      h5 {
        font-weight: 400;
        font-size: var(--fs-7);
        line-height: 115%;
        text-align: center;
        color: #000000;
        width: 20%;
        text-overflow: ellipsis;

        @media screen and (max-width: 1500px) {
          padding-bottom: 5px;
        }
      }
    }

    &-fill {
      width: calc(40% - 5px);
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;

      span {
        font-weight: 700;
        font-size: var(--fs-7);
        line-height: 115%;
        text-align: center;
        color: #000000;
        opacity: 0.8;
        padding: 2px 6px;
        position: relative;
        z-index: 3;
      }
    }

    &-left {
      justify-content: flex-end;

      &::before {
        position: absolute;
        content: '';
        width: 1px;
        height: calc(100% + 10px);
        top: 50%;
        transform: translate(0, -50%);
        background: #dee6eb;
        right: 0;
      }

      div {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        border-radius: 100px 0px 0px 100px;
      }
    }

    &-right {
      justify-content: flex-start;
      
      &::before {
        position: absolute;
        content: '';
        width: 1px;
        height: calc(100% + 10px);
        top: 50%;
        transform: translate(0, -50%);
        background: #dee6eb;
        left: 0;
      }

      div {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 0px 100px 100px 0px;
      }
    }
  }
`;
