import React, { useEffect, useRef, useState } from 'react';
import { MassmailingStyles } from './styles';
import { useAppSelector } from '../../state';
import { channels, statsContent, TabMailKeys, tabsEdit, TStats } from './utils';
import { Helmet } from 'react-helmet-async';
import { ClientCampaign, CompanyFilter, CustomSelect, CustomTabsMenu, DatePicker, Modal, SmsCampaigns, SubHeader, Templaters, TransparentButton, ViberCampaigns } from '../../components';
import { companyApi, massMailingApi } from '../../api';
import { CompaniesMainInfo } from '../../entities';
import _, { isNumber } from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Sms } from './sms';
import { ConversionMassMailings, TotalMassMailings } from '../../assets';
import { toast } from 'react-toastify';
export const Massmailing = () => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(tabsEdit(interfaceLanguage)[0].key);
  const [selectedChanel, setSelectedChanel] = useState<{
    channel: 'sms' | 'viber' | 'email';
  }>({
    channel: 'sms'
  });
  const [companies, setCompanies] = useState<{
    [key: string]: CompaniesMainInfo;
  }>();
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [stats, setStats] = useState<TStats>({
    delivered: 0,
    failed: 0,
    formOpened: 0,
    formSubmitted: 0,
    processed: 0,
    sent: 0,
    total: 0
  });
  const [addCampaign, setAddCampaign] = useState<boolean>(false);
  const [templater, setTemplater] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const filterParamsRef = useRef<{
    [key: string]: number | string | string[] | number[];
  }>({});
  const filterCampaigns = useRef<{
    [key: string]: number | string | string[] | number[];
  }>({});
  const getCompanies = (data: CompaniesMainInfo[]) => {
    const apiCompanies: {
      [key: string]: CompaniesMainInfo;
    } = data.reduce((acc, item) => {
      acc[`${item.id}`] = item;
      return acc;
    }, {} as {
      [key: string]: CompaniesMainInfo;
    });
    if (Object.keys(apiCompanies).length > 1) {
      setCompanies(apiCompanies);
    } else {
      const id = Object.values(apiCompanies)[0]?.id;
      if (!isNumber(id)) {
        toast.error('Помилка');
      }
      filterParamsRef.current = {
        companyID: id
      };
      setReQuery(!reQuery);
    }
  };
  useEffect(() => {
    companyApi.getCompanies().then(res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        getCompanies(res.data);
      }
    });
  }, []);
  useEffect(() => {
    if (TabMailKeys.campaign === selectedTab) {
      if (!location.pathname.includes(selectedChanel.channel)) {
        navigate(`/massmailer/${selectedChanel.channel}/1`);
      }
    } else {
      navigate('/massmailer');
    }
    setReQuery(!reQuery);
  }, [selectedTab]);
  useEffect(() => {
    if (TabMailKeys.campaign === selectedTab) {
      if (selectedChanel.channel === 'sms') {
        massMailingApi.massMailingSmsCampaignList(+filterParamsRef.current.companyID, 0, 0, filterCampaigns.current.from as string, filterCampaigns.current.to as string).then(res => {
          if (res.statusCode === 200 && res.stats) {
            setStats(res.stats);
            setCount(res.count ?? 0);
          }
        });
      }
      if (selectedChanel.channel === 'viber') {
        massMailingApi.massMailingViberCampaignList(+filterParamsRef.current.companyID, 0, 0, filterCampaigns.current.from as string, filterCampaigns.current.to as string).then(res => {
          if (res.statusCode === 200 && res.stats) {
            setStats(res.stats);
            setCount(res.count ?? 0);
          }
        });
      }
    }
  }, [selectedTab, reQuery]);
  return <MassmailingStyles className={TabMailKeys.campaign === selectedTab ? 'full' : ''}>
      <Helmet>
        <title>Масові розсилки</title>
      </Helmet>
      <SubHeader strechChild title="Масові розсилки">
        <div className="massmailing-subheader">
          {companies && <CompanyFilter filterParams={filterParamsRef.current} companies={companies} setFilterParams={params => {
          filterParamsRef.current = params;
        }} setReQuery={() => setReQuery(!reQuery)} />}
          {selectedTab === TabMailKeys.createCampaign && <>
              <TransparentButton filled text="Створити розсилку" handleClick={() => setAddCampaign(true)} />
              <TransparentButton filled text="Редактор шаблонів" handleClick={() => setTemplater(true)} />
            </>}
        </div>
      </SubHeader>
      <CustomTabsMenu tabs={tabsEdit(interfaceLanguage)} selectedTab={selectedTab} setSelectedTab={setSelectedTab} fullWidth />
      {TabMailKeys.campaign === selectedTab && stats && <div className="massmailing-counts">
          <div className="massmailing-stat">
            <div className="massmailing-count">
              <h4>{count}</h4>
              <TotalMassMailings />
            </div>
            <p>Створено розсилок</p>
          </div>
          {Object.keys(statsContent).map(stat => {
        const key = stat as keyof TStats;
        const Icon = statsContent[stat].icon;
        return <div className="massmailing-stat" key={stat}>
                <div className="massmailing-count">
                  <h4>{stats[key]}</h4>
                  <Icon />
                </div>
                <p>{statsContent[stat].text}</p>
              </div>;
      })}
          <div className="massmailing-stat">
            <div className="massmailing-count">
              <h4>
                {stats.total === 0 ? 0 : Math.round(stats.formSubmitted / stats.total * 100)}
                %
              </h4>
              <ConversionMassMailings />
            </div>
            <p>Конверсія</p>
          </div>
        </div>}
      {TabMailKeys.campaign === selectedTab && <div className="massmailing-filters">
          <CustomSelect containerStyle={{
        marginTop: 0
      }} extraStyles={{
        maxWidth: '300px'
      }} name="channels" options={channels} placeholder="Канал комунікації" labelField="channel" selected={selectedChanel} withoutForm onChange={value => {
        setSelectedChanel(value);
        setReQuery(!reQuery);
      }} />
          <DatePicker setReQuery={() => setReQuery(!reQuery)} setFilterParams={params => {
        filterCampaigns.current = {
          ...filterCampaigns.current,
          ...params
        };
      }} filterParams={filterCampaigns.current} />
        </div>}
      <div className="massmailing-content">
        {filterParamsRef.current.companyID && selectedTab === TabMailKeys.createCampaign && <ClientCampaign filterParams={filterParamsRef} mainReQuery={reQuery} companyID={+filterParamsRef.current.companyID} />}
        {filterParamsRef.current.companyID && selectedTab === TabMailKeys.campaign && selectedChanel.channel === 'sms' && <SmsCampaigns companyID={+filterParamsRef.current.companyID} reQuery={reQuery} filters={filterCampaigns.current} />}
        {filterParamsRef.current.companyID && selectedTab === TabMailKeys.campaign && selectedChanel.channel === 'viber' && <ViberCampaigns companyID={+filterParamsRef.current.companyID} reQuery={reQuery} filters={filterCampaigns.current} />}
      </div>

      {addCampaign && filterParamsRef.current.companyID && <Modal title="Створення розсилки" onClose={() => setAddCampaign(false)}>
          <Sms filters={filterParamsRef.current} setAddCampaign={setAddCampaign} companyID={+filterParamsRef.current.companyID} />
        </Modal>}
      {templater && filterParamsRef.current.companyID && <Modal title="Редактор шаблонів" onClose={() => setTemplater(false)}>
          <Templaters setTemplater={setTemplater} companyID={+filterParamsRef.current.companyID} />
        </Modal>}
    </MassmailingStyles>;
};