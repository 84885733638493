import React, { FC } from 'react';
import { TIcon } from './TIcon';
export const SentMassMailings: FC<TIcon> = React.memo(({
  color = '#0C0005',
  width = 36,
  height = 36
}) => <svg width={width} height={height} viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.8879 18.4283C18.5521 18.6523 18.1145 18.6523 17.7786 18.4283L6.38411 10.8321C5.56087 10.2832 5.9494 9 6.93881 9L29.7277 9C30.7171 9 31.1056 10.2832 30.2824 10.8321L18.8879 18.4283Z" stroke={color} strokeWidth="2" />
    <path d="M30.9583 22L33.9583 19L30.9583 16" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path d="M25.7083 19H33.9583" stroke="black" strokeWidth="2" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.33325 10H30.3333V13H32.3333V10C32.3333 8.89543 31.4378 8 30.3333 8H6.33325C5.22868 8 4.33325 8.89543 4.33325 10V26C4.33325 27.1046 5.22868 28 6.33325 28H30.3333C31.4378 28 32.3333 27.1046 32.3333 26V25H30.3333V26H6.33325V10Z" fill={color} />
  </svg>);