import styled from 'styled-components';

export const CampaignStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: var(--sm-4); */
  background: #ffffff;
  border-radius: 5px;

  .campaign {
    &-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding: var(--sm-4);

      &:hover {
        background: #fff4d7;
      }
    }

    &-info {
      display: flex;
      align-items: center;
      gap: var(--sm-2);

      h3 {
        font-weight: 400;
        font-size: var(--fs-3);
        line-height: 120%;
        color: #0c0005;
      }
    }

    &-utils {
      display: flex;
      align-items: center;

      p {
        font-weight: 400;
        font-size: var(--fs-5);
        line-height: 120%;
        color: rgba(12, 0, 5, 0.5);
      }
    }

    &-arrow {
      position: relative;
      transition: all 0.3s ease-in-out;
      transform: rotate(180deg);

      &.active {
        transform: rotate(0deg);
      }
    }

    &-stat {
      padding: 2px var(--sm-7);
      border-radius: 1000px;
    }

    &-division {
      height: 1px;
      width: 100%;
      background: #dee6eb;
      display: none;

      &.active {
        display: block;
      }
    }

    &-content {
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: var(--sm-4);
      padding: var(--sm-4);
      display: none;

      &.active {
        display: flex;
      }

      h3 {
        height: var(--fs-3);
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        text-align: center;
        color: #0c0005;
      }
    }

    &-charts {
      width: 100%;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      gap: var(--sm-5);
    }

    &-chart {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--sm-4);
      width: calc(20% - 8px);
      height: auto;

      p {
        font-size: var(--fs-3);
        line-height: 115%;
        text-align: center;
        color: #0c0005;
      }
    }

    &-bar {
      width: 100%;
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      border-bottom: 1px solid #dee6eb;
      padding-top: var(--sm-6);
      height: 200px;

      h4 {
        position: absolute;
        z-index: 2;
        font-weight: 700;
        font-size: var(--fs-3);
        line-height: 115%;
        text-align: center;
        color: #0c0005;
        opacity: 0.8;
        left: 50%;
        transform: translate(-50%);
        bottom: 8px;
      }

      div {
        min-width: 100px;
        max-width: 150px;
        width: 100%;
        border-radius: 10px 10px 0px 0px;
      }
    }
  }
`;
