import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { ChartSelectorStyles } from './styles';
import { TGroup, TTab } from '../../../pages';
import { usePermissionView } from '../../../hooks';
import { ArrowLeft } from '../../../assets';
import { useAppSelector } from '../../../state';
type TChartSelector = {
  charts: TTab[];
  setSelectedTab: Dispatch<SetStateAction<string>>;
  group: TGroup;
  selectedTab: string;
};
export const ChartSelector: FC<TChartSelector> = ({
  charts,
  setSelectedTab,
  group,
  selectedTab
}) => {
  const hasAnyAccess = usePermissionView();
  const {
    data
  } = useAppSelector(state => state.user);
  const [dashboards, setDashboards] = useState<TTab[]>([]);
  const handleNext = () => {
    const currentIndex = dashboards.findIndex(dashboard => dashboard.key === selectedTab);
    if (currentIndex < dashboards.length - 1) {
      setSelectedTab(dashboards[currentIndex + 1].key);
    }
  };
  const handlePrev = () => {
    const currentIndex = dashboards.findIndex(dashboard => dashboard.key === selectedTab);
    if (currentIndex > 0) {
      setSelectedTab(dashboards[currentIndex - 1].key);
    }
  };
  useEffect(() => {
    setDashboards(charts.filter(tab => (hasAnyAccess(tab.key).view || !data) && group.dashboards.includes(tab.position)));
  }, [selectedTab, group]);
  return <ChartSelectorStyles>
      <button type="button" className="chart-prev" onClick={handlePrev} disabled={dashboards.findIndex(d => d.key === selectedTab) === 0} aria-label="Обрати попередній дашборд">
        <ArrowLeft height={30} width={30} />
      </button>
      <div className="chart-selector">
        <div className="chart-position">
          {dashboards.findIndex(el => el.key === selectedTab) + 1}
        </div>
      </div>
      <button type="button" className="chart-next" onClick={handleNext} disabled={dashboards.findIndex(d => d.key === selectedTab) === dashboards.length - 1} aria-label="Обрати наступний дашборд">
        <ArrowLeft height={30} width={30} />
      </button>
    </ChartSelectorStyles>;
};