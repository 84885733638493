import styled from 'styled-components';

export const InternalStyles = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  max-height: calc(-62px + 100vh);
  position: relative;
  overflow: hidden;

  .searchWrapper {
    display: flex;
    align-items: center;
    padding: 6px 16px;

    input {
      width: 100%;
      height: 2rem;
      margin-left: 11px;
      border: none;
      outline: none;
    }

    button {
      min-width: 130px;
      width: fit-content;
    }

    input::placeholder {
      font-size: var(--fs-2);
      color: #666666;
    }
  }
`;
