import styled from 'styled-components';

export const NodeStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(240, 240, 240);
  height: 50px;
  padding: 0 16px;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: rgb(240, 245, 245);
  }

  .nodeInfo {
    display: flex;
    align-items: center;
    position: relative;
    gap: 25px;
  }

  .nodeUtils {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .nodeMark {
    position: relative;
    width: 18px;
    height: 18px;

    .arrowWrapper {
      width: 18px;
      height: 18px;
      background-color: #e2e2e2;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
      z-index: 2;

      &.active {
        transform: translate(-50%, -50%) rotate(0);
      }
    }

    .horizontalLineLeaf {
      position: absolute;
      height: 100%;
      display: flex;
      align-items: center;
      top: 50%;
      transform: translate(0, -50%);
      right: 0px;
    }

    .horizontalLineNone {
      position: absolute;
      height: 100%;
      display: flex;
      align-items: center;
      top: 50%;
      transform: translate(0, -50%);
      left: -22px;
    }

    .verticalLineNone {
      height: 50px;
      position: absolute;
      overflow: hidden;
      top: -18px;
      left: -24px;

      &.last {
        height: 25px;
      }
    }

    .arrowVerticalLine {
      height: 50px;
      position: absolute;
      top: -18px;
      left: 9px;
    }

    .verticalLineLeaf {
      height: 50px;
      position: absolute;
      overflow: hidden;
      top: -18px;
      left: -24px;

      &.last {
        height: 25px;
      }
    }
  }

  .nodeUtils {
    height: 100%;
    display: flex;
    justify-content: center;

    a {
      display: flex;
      align-items: center;
    }

    button {
      display: flex;
      align-items: center;
    }

    .openButton {
      position: relative;

      svg {
        height: 20px;
      }
    }

    .editButtons {
      width: 180px;
      display: grid;
      grid-template-columns: 1fr 1fr 20px 1fr 1fr;
      align-items: center;

      .buttonContainer {
        display: flex;
        justify-content: center;
      }

      button {
        display: flex;
        justify-content: center;
      }

      .splitter {
        height: 24px;
        display: flex;
        justify-content: center;

        > div {
          width: 1px;
          height: 100%;
          background-color: #eee;
        }
      }
    }
  }

  .modalBody {
    .modalButtonsWrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .modalButtonsCaption {
        margin-bottom: 16px;
        font-size: var(--fs-3);
        color: #000000;
      }

      div {
        display: flex;
        justify-content: space-between;
        gap: 20px;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 244px;
        height: 40px;
        font-weight: 500;
        font-size: var(--fs-3);
        border-radius: 4px;
        transition: 0.3s all;

        :nth-child(1) {
          background: #ffffff;
          border: 1px solid #c4c4c4;
          color: #000000;

          :hover {
            color: #fff;
            background: #c4c4c4;
          }
        }

        :nth-child(2) {
          border: 1px solid #ff0101;
          background: #ff0101;
          color: #ffffff;

          :hover {
            background: #ffffff;
            color: #ff0101;
          }
        }
      }
    }

    .modalError {
      font-size: var(--fs-5);
      line-height: 120%;
      color: #c32900;
      margin-bottom: 0;
    }
  }

  .responsibleWrapper {
    position: relative;
    margin-left: 30px;

    .userIcon {
      cursor: pointer;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
`;
