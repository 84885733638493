import styled from 'styled-components';

export const FormsStyles = styled.div`
  width: 100%;
  flex: 1;
  position: relative;
  overflow: hidden;

  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding-left: 16px;
    padding-right: 32px;
    border-bottom: 1px solid #f0f0f0;
    box-sizing: border-box;

    h1 {
      font-weight: 500;
      font-size: var(--lg-fs-1);
      line-height: 28px;
      color: #000000;
    }

    .buttonsContainer {
      display: flex;
      align-items: center;

      button {
        width: fit-content;
        margin-left: 16px;

        &:first-child {
          margin-left: 0;

          div {
            display: flex;

            span {
              margin-left: 12px;
            }
          }
        }

        &:hover {
          path {
            transition: 0.3s all;
            fill: #000;
          }
        }
      }
    }
  }

  .nameWrapper {
    background-color: lightgrey;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 4px 8px;
    border-radius: 67px;
    text-decoration: none;
    p {
      font-size: var(--fs-2);
      color: #000000;
    }
    div {
      margin-left: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .customUrlField {
    font-weight: 400;
    font-size: var(--fs-3);
    line-height: 130%;
    color: #000;
    text-decoration: none;
  }

  .editButtonsContainer {
    height: 100%;
    display: flex;
    justify-content: center;

    a {
      display: flex;
      align-items: center;
    }

    button {
      display: flex;
      align-items: center;
    }
  }

  .searchWrapper {
    display: flex;
    align-items: center;
    padding: 0 19px;
    border-bottom: 1px solid #f0f0f0;

    .searchField {
      width: 100%;
      height: 3rem;
      margin-left: 11px;
      border: none;
      outline: none;
    }

    .searchField::placeholder {
      font-size: var(--fs-2);
      color: #666666;
    }
  }

  .modalBody {
    .modalButtonsWrap {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .modalButtonsCaption {
        margin-bottom: 16px;
        font-size: var(--fs-3);
        color: #000000;
      }

      .deleteButton {
        border: 1px solid #ff0101;
        background: #ff0101;
        color: #ffffff;

        :hover {
          background: #ffffff;
          color: #ff0101;
        }
      }

      .confirmButton {
        border: 1px solid #000;
        background: #000;
        color: #ffffff;

        :hover {
          background: #ffffff;
          color: #000;
        }
      }

      .declineButton {
        background: #ffffff;
        border: 1px solid #c4c4c4;
        color: #000000;

        :hover {
          color: #fff;
          background: #c4c4c4;
        }
      }

      & > button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        font-weight: 500;
        font-size: var(--fs-3);
        border-radius: 4px;
        transition: 0.3s all;
        margin-bottom: 8px;
      }
    }

    .modalError {
      font-size: var(--fs-5);
      line-height: 120%;
      color: #c32900;
      margin-bottom: 0;
    }
  }

  .updateAtWrapper {
    display: flex;
    flex-direction: column;

    span:first-child {
      margin-bottom: 4px;
    }
  }

  .statusWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    svg {
      margin-right: 7px;
    }
  }

  .customConstructorField {
    font-weight: 500;
    font-size: var(--fs-3);
    color: #ffffff;
    text-decoration: none;

    div {
      transition: 0.3s all;
      border: 1px solid #000;
      padding: 8px;
      background: #000;
      border-radius: 4px;
      text-align: center;

      :hover {
        background: #ffffff;
        color: #000;
      }
    }
  }

  .editLinks {
    display: flex;
  }

  .customEditLink {
    margin: 0 16px;
    font-weight: 500;
    font-size: var(--fs-3);
    color: #000;
    text-decoration: none;

    div {
      min-width: 120px;
      transition: 0.3s all;
      border: 1px solid #000;
      padding: 8px;
      background: #ffffff;
      border-radius: 4px;
      text-align: center;

      :hover {
        background: #000;
        color: #ffffff;
      }
    }
  }

  button {
    padding: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }

  .menu {
    position: relative;

    .menuList {
      z-index: 99;
      position: absolute;
      top: calc(50% + 15px);
      right: 16px;
      width: 260px;
      border-radius: 4px;
      border: 1px solid #e5e5e5;
      overflow: hidden;
      filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.14));

      list-style: none;
      font-size: var(--fs-3);
      color: #000000;

      li {
        height: 41px;
        background: #ffffff;
        border-bottom: 1px solid #e5e5e5;
        box-sizing: border-box;

        &:last-child {
          border-bottom: none;
        }
      }

      button {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 16px;
        box-sizing: border-box;
        transition: background-color 0.3s;

        &:hover {
          background-color: #f0f5f5;
          transition: background-color 0.3s;
        }
      }

      span {
        margin-left: 12px;
      }
    }
  }
`;
