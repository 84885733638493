import React, { FC } from 'react';
import { TIcon } from './TIcon';
export const DeliveredMassMailings: FC<TIcon> = React.memo(({
  color = '#0C0005',
  width = 36,
  height = 36
}) => <svg width={width} height={height} viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.2212 18.4283C17.8853 18.6523 17.4477 18.6523 17.1118 18.4283L5.71736 10.8321C4.89412 10.2832 5.28265 9 6.27206 9L29.061 9C30.0504 9 30.4389 10.2832 29.6157 10.8321L18.2212 18.4283Z" stroke={color} strokeWidth="2" />
    <path d="M28.6665 18.9167L30.0719 20L32.6665 18" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.6665 10H29.6665V14H31.6665V10C31.6665 8.89543 30.7711 8 29.6665 8H5.6665C4.56193 8 3.6665 8.89543 3.6665 10V26C3.6665 27.1046 4.56194 28 5.6665 28H29.6665C30.7711 28 31.6665 27.1046 31.6665 26V24H29.6665V26H5.6665V10Z" fill={color} />
    <circle cx="30.6665" cy="19" r="5" stroke={color} strokeWidth="2" />
  </svg>);