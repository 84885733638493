export const periods: string[] = ['quarter', 'months', 'days7'];

export const setMidnightTime = (date: Date) => new Date(date.setHours(0, 0, 0, 0));

export const getStartDate = () => {
  const date = new Date();
  const result = setMidnightTime(new Date(date.setDate(date.getDate() - 4)));
  if (new Date(result).getMonth() !== new Date().getMonth()) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }
  return new Date(result);
};

export const addDay = (date: Date, quantity?: number) => new Date(date.getTime() + (quantity ? 86400000 * quantity : 86400000));
