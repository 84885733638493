export const statsContent: {[key: string]: { [key: string]: any }} = {
  itemsTotal: {
    text: 'Відправлено',
    color: '#CFC4FF',
  },
  itemsDelivered: {
    text: 'Доставлено',
    color: '#A7C4E9',
  },
  itemsFailed: {
    text: 'Не вдалося',
    color: '#F2C3DB',
  },
  itemsFormOpened: {
    text: 'Відкрили форму',
    color: '#F4EF67',
  },
  itemsFormSubmitted: {
    text: 'Відправили форму',
    color: '#6EAE93',
  },
};
