/* eslint-disable class-methods-use-this */
import baseApi from '../baseApi';
import { ApiResponse } from '../entities';

class UsersFiltersApi {
  public async getOrgNames(
    offset?: number,
    limit?: number,
    query?: string,
  ): Promise<ApiResponse<any>> {
    const res = await baseApi.getData(
      `/users/filters/org-names?limit=${limit}&offset=${offset}&query=${query}`,
    );
    return res;
  }

  public async getOrgTypes(
    offset?: number,
    limit?: number,
    query?: string,
  ): Promise<ApiResponse<any>> {
    const res = await baseApi.getData(
      `/users/filters/org-types?limit=${limit}&offset=${offset}&query=${query}`,
    );
    return res;
  }

  public async getPositions(
    offset?: number,
    limit?: number,
    query?: string,
  ): Promise<ApiResponse<any>> {
    const res = await baseApi.getData(
      `/users/filters/positions?limit=${limit}&offset=${offset}&query=${query}`,
    );
    return res;
  }

  public async getProcessedNames(
    offset?: number,
    limit?: number,
    query?: string,
  ): Promise<ApiResponse<any>> {
    const res = await baseApi.getData(
      `/users/filters/processed-names?limit=${limit}&offset=${offset}&query=${query}`,
    );
    return res;
  }

  public async getProcessedOrgNames(
    offset?: number,
    limit?: number,
    query?: string,
  ): Promise<ApiResponse<any>> {
    const res = await baseApi.getData(
      `/users/filters/processed-org-names?limit=${limit}&offset=${offset}&query=${query}`,
    );
    return res;
  }

  public async getProcessedOrgTypes(
    offset?: number,
    limit?: number,
    query?: string,
  ): Promise<ApiResponse<any>> {
    const res = await baseApi.getData(
      `/users/filters/processed-org-types?limit=${limit}&offset=${offset}&query=${query}`,
    );
    return res;
  }

  public async getProcessedRoles(
    offset?: number,
    limit?: number,
    query?: string,
  ): Promise<ApiResponse<any>> {
    const res = await baseApi.getData(
      `/users/filters/processed-roles?limit=${limit}&offset=${offset}&query=${query}`,
    );
    return res;
  }

  public async getRegions(
    offset?: number,
    limit?: number,
    query?: string,
  ): Promise<ApiResponse<any>> {
    const res = await baseApi.getData(
      `/users/filters/regions?limit=${limit}&offset=${offset}&query=${query}`,
    );
    return res;
  }

  public async getRoles(
    offset?: number,
    limit?: number,
    query?: string,
  ): Promise<ApiResponse<any>> {
    const res = await baseApi.getData(
      `/users/filters/roles?limit=${limit}&offset=${offset}&query=${query}`,
    );
    return res;
  }
}

export const usersFilterApi = new UsersFiltersApi();
