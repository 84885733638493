import styled from 'styled-components';

export const CustomTabsMenuStyles = styled.div`
  .tabsContainer {
    width: 100%;
    padding-left: 16px;
    padding-right: 32px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f0f0;

    button {
      height: 24px;
      display: flex;
      align-items: flex-start;
      border-bottom: 3px solid #fff;
      margin-right: 24px;

      font-size: var(--fs-3);
      line-height: 120%;
      color: #7c7878;
      transition: all 0.3s;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        border-bottom: 3px solid #000;
        color: #000;
        transition: all 0.3s;
      }
    }
  }
`;

export const NewCustomTabsMenuStyles = styled.div<{ length: number }>`
  .tabsContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    button {
      box-sizing: border-box;
      padding: var(--sm-6);
      opacity: 0.3;
      border-bottom: 2px solid #0c0005;
      font-size: var(--fs-3);
      line-height: 120%;
      transition: all 0.3s;
      font-weight: 400;
      color: #0c0005;
      width: calc(100% / ${(props) => props.length});
      cursor: pointer;

      &:hover {
        opacity: 1;
        background: #FFF4D7;
      }

      &.active {
        opacity: 1;
        background: #FFF4D7;
      }
    }
  }
`;
