import React, { useEffect, useState } from 'react';
import { ExpandableProps } from './types';
import { ExpandableStyles } from './styles';
export const Expandable: React.FC<ExpandableProps> = ({
  children,
  isOpen,
  extraStyles
}) => {
  useEffect(() => {}, []);
  return <ExpandableStyles>
      <div style={{
      ...extraStyles,
      ...{}
    }} className="expandable">
        <div className={`expandable-content ${isOpen ? 'open' : ''}`}>
          {children}
        </div>
      </div>
    </ExpandableStyles>;
};