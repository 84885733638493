import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { TFieldConstruct } from '../../companyFields';
import { ApiCompanyField, ApiCreatedField, ApiUpdatedField, FieldType, TAdditionalParams, fieldsApi } from '../../../../api';
import { TabKeys, addAdditionalParam, scaleColors, scaleTypes, tabs } from '../utils';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Checkbox, ColorScale, CustomTabsMenu, InputField, NumericColorScale, NumericScale, RadioButton, StarScale } from '../../../molecules';
import { InfoAlert, SubmitButton } from '../../../atoms';
import { getErrorMessage, handleKeyUp } from '../../../../utils';
import { FieldScaleStyles } from '../styles';
import { ColorSettings, NumericColorSettings, NumericSettings, StarSettings } from '../../scaleSettings';
type TScale = {
  field: TFieldConstruct;
  setFields: Dispatch<SetStateAction<ApiCompanyField[]>>;
  setField: Dispatch<SetStateAction<TFieldConstruct | null>>;
};
export interface IScaleField {
  question: string;
  signature_min: string;
  signature_max: string;
  scale_type: string;
  required: boolean;
  isSentiment: boolean;
  analytics_title: string;
  isMain: boolean;
  key_question: boolean;
  change_background: boolean;
  change_mascot: boolean;
  min: number;
  max: number;
}
const initialValue: IScaleField = {
  question: '',
  signature_min: 'Малоймовірно',
  signature_max: 'Надзвичайно неймовірно',
  scale_type: 'color',
  required: false,
  analytics_title: '',
  isSentiment: false,
  isMain: false,
  key_question: false,
  change_background: false,
  change_mascot: false,
  min: 0,
  max: 10
};
export const validationSchema = Yup.object({
  question: Yup.string().required('* Required')
});
export const Scale: FC<TScale> = ({
  field,
  setFields,
  setField
}) => {
  const {
    id
  } = useParams();
  const [initialValues, setInitialValues] = useState<IScaleField>();
  const [apiField, setApiField] = useState<ApiCompanyField | null>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [selectedTab, setSelectedTab] = useState<string>(TabKeys.field);
  const onSubmit = async (values: IScaleField, {
    setSubmitting
  }: FormikHelpers<IScaleField>) => {
    setSubmitting(false);
    let options: TAdditionalParams[] = [];
    let styles: TAdditionalParams[] = [];
    if (id) {
      if (apiField) {
        options = [...apiField.options];
        styles = [...apiField.styles];
        addAdditionalParam(values.required, 'required', options);
        addAdditionalParam(values.isSentiment, 'isSentiment', options);
        addAdditionalParam(values.isMain, 'isMain', options);
        addAdditionalParam(values.key_question, 'key_question', options);
        addAdditionalParam(values.change_background, 'change_background', options);
        addAdditionalParam(values.change_mascot, 'change_mascot', options);
        addAdditionalParam(values.signature_min, 'signature_min', options);
        addAdditionalParam(values.signature_max, 'signature_max', options);
        addAdditionalParam(values.scale_type, 'scale_type', options);
        addAdditionalParam(values.analytics_title, 'analytics_title', options);
        const data: ApiUpdatedField<FieldType.Scale> = {
          question: values.question,
          options,
          styles,
          min: values.min,
          max: values.max
        };
        fieldsApi.updateFormField(apiField.id!, data).then(res => {
          if (res.statusCode === 200) {
            setInitialValues(values);
            setFields(prev => prev.map(el => {
              if (el.id === apiField.id) {
                return {
                  ...el,
                  ...data
                };
              }
              return el;
            }));
            toast.success('Поле успішно оновлене');
          }
        });
      } else {
        addAdditionalParam(values.required, 'required', options);
        addAdditionalParam(values.isSentiment, 'isSentiment', options);
        addAdditionalParam(values.isMain, 'isMain', options);
        addAdditionalParam(values.change_background, 'change_background', options);
        addAdditionalParam(values.change_mascot, 'change_mascot', options);
        addAdditionalParam(values.key_question, 'key_question', options);
        addAdditionalParam(values.signature_min, 'signature_min', options);
        addAdditionalParam(values.signature_max, 'signature_max', options);
        addAdditionalParam(values.scale_type, 'scale_type', options);
        addAdditionalParam(values.analytics_title, 'analytics_title', options);
        const data: ApiCreatedField = {
          field: {
            [FieldType.Scale]: {
              question: values.question,
              min: values.min,
              max: values.max,
              options,
              styles
            }
          }
        };
        fieldsApi.createCompanyFields(+id, data).then(res => {
          if (res.statusCode === 201) {
            setInitialValues(values);
            setFields(prev => [res.data, ...prev]);
            setField({
              id: res.data.id,
              type: field.type
            });
            toast.success('Поле успішно додано');
          }
        });
      }
    }
  };
  const renderForm = ({
    values,
    setFieldValue,
    handleChange
  }: FormikProps<IScaleField>) => <Form>
      <InputField extraBlockStyles={{
      marginBottom: '25px'
    }} name="question" onChange={setFieldValue} onKeyUp={() => handleKeyUp('question', setErrorMessage, errorMessage)} placeholder="Введіть запитання для поля" value={values.question} error={typeof errorMessage === 'object' ? getErrorMessage('question', errorMessage) : undefined} />
      <div className="field-info">
        <InputField extraBlockStyles={{
        marginBottom: '25px'
      }} name="analytics_title" onChange={setFieldValue} onKeyUp={() => handleKeyUp('analytics_title', setErrorMessage, errorMessage)} placeholder="Заголовок для дашбордів аналітики" value={values.analytics_title} error={typeof errorMessage === 'object' ? getErrorMessage('analytics_title', errorMessage) : undefined} />
        <InfoAlert signature="У цьому полі введіть загаловок який буде відображатись на дашборді аналітики." />
      </div>
      <div className="field-info">
        <InputField extraBlockStyles={{
        marginBottom: '25px'
      }} name="signature_min" onChange={setFieldValue} onKeyUp={() => handleKeyUp('signature_min', setErrorMessage, errorMessage)} placeholder="Мінімальне значення" value={values.signature_min} error={typeof errorMessage === 'object' ? getErrorMessage('signature_min', errorMessage) : undefined} />
        <InfoAlert signature="У цьому полі введіть короткий опис для мінімального значення шкали." />
      </div>
      <div className="field-info">
        <InputField extraBlockStyles={{
        marginBottom: '25px'
      }} name="signature_max" onChange={setFieldValue} onKeyUp={() => handleKeyUp('signature_max', setErrorMessage, errorMessage)} placeholder="Максимальне значення" value={values.signature_max} error={typeof errorMessage === 'object' ? getErrorMessage('signature_max', errorMessage) : undefined} />
        <InfoAlert signature="У цьому полі введіть короткий опис для максимального значення шкали." />
      </div>

      <div className="field-scales">
        {scaleTypes.map((el, index) => <RadioButton name="scale_type" value={el} onChange={event => {
        setFieldValue('scale_type', event.target.value);
      }} radioButtonWrapperStyles={{
        alignItems: 'center'
      }} customizedRadioContainerStyles={{
        marginBottom: 24
      }} checked={values.scale_type === el}>
            {el === 'color' && <ColorScale colors={scaleColors.colorScale.colors} pointerColor={scaleColors.colorScale.pointerColor} maxValue={values.max} defaultValue={0} signatures={[values.signature_min, values.signature_max]} signatureColor="#ccc" />}
            {el === 'numeric' && <NumericScale numbersColor={scaleColors.numericScale.numbersColor} selectedButtonColor={scaleColors.numericScale.selectedButtonColor} maxValue={values.max} defaultValue={0} showDefaultValue signatures={[values.signature_min, values.signature_max]} signatureColor="#ccc" />}
            {el === 'star' && <StarScale selectedStarColor={scaleColors.starScale.selectedStarColor} notSelectedStarBorder={scaleColors.starScale.notSelectedStarBorder} notSelectedStarBackground={scaleColors.starScale.notSelectedStarBackground} maxValue={values.max} defaultValue={0} showDefaultValue signatures={[values.signature_min, values.signature_max]} signatureColor="#ccc" />}
            {el === 'numericColor' && <NumericColorScale defaultValue={0} showDefaultValue signatures={[values.signature_min, values.signature_max]} signatureColor="#ccc" />}
          </RadioButton>)}
      </div>

      <Checkbox name="required" value={values.required!} onChange={handleChange}>
        <span className="checkboxValue">
          Обовʼязкове поле
        </span>
      </Checkbox>

      <Checkbox name="isMain" value={values.isMain!} onChange={handleChange}>
        <span className="checkboxValue">
          Головна шкала
        </span>
      </Checkbox>

      <Checkbox name="key_question" value={values.key_question!} onChange={handleChange}>
        <span className="checkboxValue">
          Ключове питання
        </span>
      </Checkbox>

      <Checkbox name="isSentiment" value={values.isSentiment!} onChange={handleChange}>
        <span className="checkboxValue">
          Коментар для сповіщень
        </span>
      </Checkbox>

      <SubmitButton extraBlockStyles={{
      marginTop: '20px'
    }} extraButtonStyles={{
      padding: '15px 45px'
    }}>
        Зберегти
      </SubmitButton>
    </Form>;
  useEffect(() => {
    if (field.id) {
      fieldsApi.getTargetField(field.id).then(res => {
        const required = res.data.options.find(option => option.key === 'required')?.value === 'true';
        const isSentiment = res.data.options.find(option => option.key === 'isSentiment')?.value === 'true';
        const isMain = res.data.options.find(option => option.key === 'isMain')?.value === 'true';
        const key_question = res.data.options.find(option => option.key === 'key_question')?.value === 'true';
        const change_background = res.data.options.find(option => option.key === 'change_background')?.value === 'true';
        const change_mascot = res.data.options.find(option => option.key === 'change_background')?.value === 'true';
        const signature_min = res.data.options.find(option => option.key === 'signature_min')?.value || 'Малоймовірно';
        const signature_max = res.data.options.find(option => option.key === 'signature_max')?.value || 'Надзвичайно неймовірно';
        const scale_type = res.data.options.find(option => option.key === 'scale_type')?.value || '';
        const analytics_title = res.data.options.find(option => option.key === 'analytics_title')?.value || '';
        setApiField(res.data);
        setInitialValues({
          question: res.data.question,
          min: res.data.min || 0,
          max: res.data.max || 10,
          isSentiment,
          signature_max,
          scale_type,
          analytics_title,
          change_background,
          signature_min,
          change_mascot,
          required,
          key_question,
          isMain
        });
      });
    } else {
      setInitialValues(initialValue);
    }
  }, [field]);
  return <FieldScaleStyles>
      <CustomTabsMenu tabs={tabs} selectedTab={selectedTab} setSelectedTab={(key: any) => {
      if (key === TabKeys.settings && !field.id) {
        alert('Для внесення додаткових налаштувань збережіть основні');
        return;
      }
      setSelectedTab(key as TabKeys);
    }} />
      <div className="field-body">
        {initialValues && selectedTab === TabKeys.field && <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
            {renderForm}
          </Formik>}
        {initialValues && selectedTab === TabKeys.settings && <>
            {initialValues.scale_type === 'color' && <ColorSettings changeMinMax setField={setField} setFields={setFields} field={field} />}
            {initialValues.scale_type === 'star' && <StarSettings changeMinMax setField={setField} setFields={setFields} field={field} />}
            {initialValues.scale_type === 'numeric' && <NumericSettings changeMinMax setField={setField} setFields={setFields} field={field} />}
            {initialValues.scale_type === 'numericColor' && <NumericColorSettings setField={setField} setFields={setFields} field={field} />}
          </>}
      </div>
    </FieldScaleStyles>;
};