import styled from 'styled-components';
export const SearchContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 16px;
  gap: 12px;
  background: #eef3f7;
  border: 2px solid #ffffff;
  border-radius: 999px;
  width: 50%;
  position: relative;
  z-index: 5;
  margin-left: 10px;
  margin-bottom: 10px;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    width: 100%;
    height: 100%;
    min-height: 20px;
    flex: 1;
    padding: 0px;
    border: none;
    outline: none;
    background-color: #eef3f7;
    border-radius: 5px;
    padding-left: 10px;

    @media screen and (max-width: 768px) {
      margin-left: 0px;
    }
  }

  input::placeholder {
    font-size: var(--fs-2);
    color: #666666;
  }
`;
export const AcessRequestStyles = styled.div`
  width: 100%;
  padding: 15px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .row {
    display: flex;
    justify-content: space-between;
  }

  .access-wrapper {
    flex: 1 1 0%;
    height: 100%;
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  .optionsExtraStyles {
    border: none !important;
    border-radius: 0 !important;
    overflow-y: auto !important;
    max-height: none !important;
  }

  .extraFilterFormStyles {
    height: 100%;
  }

  .extraFilterStyles {
    height: 100%;
    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-gap: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .togglerContainer {
    padding-top: 20px;
    padding-left: 16px;
    display: flex;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      margin-left: 24px;
      min-width: 100px;

      & > button {
        width: 100%;
      }
    }
  }

  .sortable {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    &.asc {
      svg {
        transform: rotate(180deg);
      }
    }

    &.desc {
      svg {
      }
    }
  }

  .searchWrapper {
    display: flex;
    align-items: center;
    padding: 0 19px;
    border-bottom: 1px solid #f0f0f0;

    input {
      width: 100%;
      height: 3rem;
      margin-left: 11px;
      font-size: var(--fs-5);
      border: none;
      outline: none;
    }

    input::placeholder {
      font-size: var(--fs-5);
      color: #666666;
    }
  }

  .editButtonsContainer {
    .menuOpenerContainer {
      display: flex;
      justify-content: flex-end;
      position: relative;

      .menuOpenerButton {
        padding: 0 20px;
        cursor: pointer;
      }

      .menu {
        position: relative;

        .menuList {
          z-index: 99;
          position: absolute;
          top: calc(50% + 15px);
          right: 16px;
          width: 260px;
          border-radius: 4px;
          border: 1px solid #e5e5e5;
          overflow: hidden;
          filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.14));

          list-style: none;
          font-size: var(--fs-3);
          color: #000000;

          li {
            height: 41px;
            background: #ffffff;
            border-bottom: 1px solid #e5e5e5;
            box-sizing: border-box;

            &:last-child {
              border-bottom: none;
            }
          }

          button {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0 16px;
            box-sizing: border-box;
            transition: background-color 0.3s;

            &:hover {
              background-color: #f0f5f5;
              transition: background-color 0.3s;
            }
          }

          span {
            margin-left: 12px;
          }
        }
      }
    }

    .modalBody {
      .modalButtonsWrap {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .modalButtonsCaption {
          margin-bottom: 16px;
          font-size: var(--fs-3);
          color: #000000;
        }

        div {
          display: flex;
          justify-content: space-between;
        }
      }

      .modalError {
        font-size: var(--fs-5);
        line-height: 120%;
        color: #c32900;
        margin-bottom: 0;
      }
    }
  }

  .filterRow {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-left: 10px;
    }
  }

  .entityTitle {
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-right: 8px;
    }

    svg {
      transition: all 0.3s ease-in-out;
      width: 16px;
      height: 16px;
    }
  }

  .filterTitle {
    font-weight: 400;
    margin-right: 10px;
  }

  .filtersOpener {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: fit-content;
    min-width: 150px;
    align-items: center;
    padding: 4px 10px;
    border: 1px solid #e5e5e5;
    &-active {
      svg {
        transition: all 0.3s ease-in-out;
        transform: rotate(180deg);
      }
    }
  }
`;
export const DropDownContainer = styled.div`
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 150px;
  background-color: #eef3f7;
  border-radius: 5px;
  /* margin-bottom: 10px; */
`;
export const StyledSelect = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;