import React, { useRef, useMemo } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { ScoreFilterStyles } from './SubmittedStyles';
import { translations } from '../../../../i18n';
import { useAppSelector } from '../../../../state';
import CustomSelectWithMultipleCheckboxes from '../../../form/customSelect/CustomSelectWithMultipleCheckboxes';
import SubmitButton from '../../../form/submitButton/SubmitButton';
import { extraOneOptionStyles, resetFilterButtonStyles } from '../reviewsTableFiltersUtils';
import { Loader, TransparentButton } from '../../../atoms';
import { TSubmittedFilter } from './TScoreFilter';
export const SubmittedFilter = React.memo(({
  filterParams,
  setReQuery,
  setApply,
  setFilterParams,
  onClose,
  options,
  columnName
}: TSubmittedFilter) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const ref = useRef<any>();
  const formRef = useRef<FormikProps<{
    [key: string]: any[];
  }>>(null);
  const initialValues: {
    [key: string]: any[];
  } = {
    [columnName]: options
  };
  const persistedValues = useMemo(() => ({
    ...initialValues,
    [columnName]: initialValues[columnName]?.map(item => ({
      ...item,
      value: !!filterParams[columnName]?.includes(+item.key)
    }))
  }), []);
  async function onSubmit(values: {
    [key: string]: any[];
  }, {
    setSubmitting
  }: FormikHelpers<{
    [key: string]: any[];
  }>) {
    const params: {
      [key: string]: any;
    } = {};
    // @ts-ignore
    const checkedFilters: Array<number> = values[columnName]?.filter(item => item.value).map(e => +e.key) ?? [];
    params[columnName] = checkedFilters;
    const resultParams: {
      [key: string]: any;
    } = {};
    Object.entries({
      ...filterParams,
      ...params
    }).forEach(item => {
      if (!!item[0] && item[1] !== undefined && item[1] !== null && item[1] !== '') {
        resultParams[item[0]] = item[1];
      }
    });
    setFilterParams(resultParams);
    setApply(resultParams);
    setReQuery();
    setSubmitting(false);
  }
  const additionalResetHandlers = () => {
    if (filterParams[columnName]) {
      delete filterParams[columnName];
    }
    setFilterParams({
      ...filterParams
    });
    setApply({
      ...filterParams
    });
    setReQuery();
    onClose();
  };
  const renderForm = ({
    values,
    errors,
    setFieldValue,
    handleChange,
    resetForm
  }: FormikProps<{
    [key: string]: any[];
  }>) => <Form>
        <div>
          <SubmitButton extraButtonStyles={{
        width: '100%',
        maxWidth: '500px',
        height: 32
      }}>
            {translations[interfaceLanguage].apply_filters_button}
          </SubmitButton>
          <TransparentButton handleClick={() => {
        resetForm();
        additionalResetHandlers();
      }} text={translations[interfaceLanguage].reset_reviews_filter} extraStyles={resetFilterButtonStyles} extraButtonTextStyles={{
        color: '#ffffff'
      }} />
          <CustomSelectWithMultipleCheckboxes name={columnName} handleChange={handleChange} options={values[columnName] || []} extraOneOptionStyles={extraOneOptionStyles} optionsContainerStyles={{
        width: '100%'
      }} placeholder={translations[interfaceLanguage].select_multiple_Filters} placeholderStyles={{
        color: '#666666'
      }} oneOptionValueStyles={{
        marginLeft: '4px'
      }} isLoading={false} alwaysOpened />
        </div>
      </Form>;
  return <ScoreFilterStyles ref={ref}>
        {initialValues ? <Formik innerRef={formRef} initialValues={persistedValues} onSubmit={onSubmit}>
            {renderForm}
          </Formik> : <Loader margin={0} />}
      </ScoreFilterStyles>;
});