/* eslint-disable class-methods-use-this */
import {
  ICompanyUser, ICreateUser, IEdiUser, IUser,
} from '../../entities';
import { mapApiUserToIUser, mapApiUsersToIUsers } from '../Mappers';
import baseApi from '../baseApi';
import { ApiResponse } from '../entities';
import IUsersApi from './IUsersApi';

export type TUserItem = {
  id: number;
  company: string;
  name: string;
  email: string;
  orgType: string;
  orgName: string;
  role: string;
  position: string;
  region: string;
  frontOffice: string;
  processedOrgType: string;
  processedOrgName: string;
  processedRole: string;
  processedName: string;
};
class UsersApi implements IUsersApi {
  public async getFilteredUsers(
    data: { email?: string; name?: string; q?: string; companyID?: string },
    offset?: number,
    limit?: number,
  ): Promise<ApiResponse<IUser[]>> {
    const query = Object.entries(data)
      .map((item) => item.join('='))
      .join('&');
    const res = await baseApi.getData(
      `/users?offset=${offset || 0}&limit=${limit || 10}${
        query ? `&${query}` : ''
      }`,
    );
    return Promise.resolve({
      data: mapApiUsersToIUsers(res.data.data),
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async exportUsers(
    data: { email?: string; name?: string; q?: string; companyID?: string },
    offset?: number,
    limit?: number,
  ) {
    const response = await baseApi.postData('/users/export', data, true);
    console.log({ response });
    // Get filename from Content-Disposition header
    const contentDisposition = response.headers['content-disposition'];

    let filename = 'download.xlsx'; // default filename

    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(
        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
      );
      if (filenameMatch && filenameMatch[1]) {
        filename = filenameMatch[1].replace(/['"]/g, '');
      }
    }
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(response.data);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  public async getFilteredUsersList(
    data: any,
    offset?: number,
    limit?: number,
  ): Promise<ApiResponse<TUserItem[]>> {
    const res = await baseApi.postData(
      `/users/list?offset=${offset || 0}&limit=${limit || 10}`,
      data,
    );
    return Promise.resolve({
      data: res.data.items,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async getUser(id: number): Promise<ApiResponse<IUser>> {
    const { data, statusCode } = await baseApi.getData(`/auth/users/${id}`);
    return Promise.resolve({
      data: mapApiUserToIUser(data),
      statusCode,
    });
  }

  public async getCrmUser(id: number): Promise<ApiResponse<IUser>> {
    const { data, statusCode } = await baseApi.getData(`/users?id=${id}`);
    return Promise.resolve({
      data: mapApiUserToIUser(data.data[0]),
      statusCode,
    });
  }

  // also edites one on AuthService
  public async editCrmUser(
    id: number,
    data: IEdiUser,
  ): Promise<ApiResponse<null>> {
    const { statusCode } = await baseApi.putData(`/users/${id}`, data);

    return {
      data: null,
      statusCode,
    };
  }

  // also creates one on AuthService
  public async createCrmUser(data: ICreateUser): Promise<ApiResponse<IUser>> {
    const { data: response, statusCode } = await baseApi.postData(
      '/users',
      data,
    );

    return {
      data: mapApiUserToIUser(response),
      statusCode,
    };
  }

  public async deleteUser(id: number): Promise<ApiResponse<null>> {
    return baseApi.deleteData(`/users/${id}`);
  }

  public async getNodeUsers(id: number): Promise<ApiResponse<IUser[]>> {
    return baseApi.getData(`/nodes/${id}/view`);
  }

  public async getUsersByCompany(
    id: number,
    offset?: number,
    limit?: number,
    q?: string,
  ): Promise<ApiResponse<ICompanyUser[]>> {
    const res = await baseApi.getData(
      `/companies/${id}/users?offset=${offset || 0}&limit=${limit || 10}&q=${
        q || ''
      }`,
    );

    return Promise.resolve({
      data: res.data.data,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }
}

export const usersApi = new UsersApi();
