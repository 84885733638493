import styled from 'styled-components';

export const MassmailingStyles = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 1fr;
  position: relative;
  overflow: hidden;
  max-height: calc(100vh - 62px);
  gap: var(--sm-4);
  padding: var(--sm-2);

  &.full {
    grid-template-rows: auto auto  auto auto 1fr;
  }

  .titleWrapper {
    padding: 0;
  }

  .massmailing {
    &-subheader {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
      align-items: center;
      padding-left: 20px;
    }

    &-counts {
      width: 100%;
      display: flex;
      gap: var(--sm-7);
      position: relative;
      overflow: auto;
      flex-shrink: 0;
    }

    &-stat {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: var(--md-3);
      gap: var(--sm-7);
      background: #ffffff;
      border-radius: 5px;
      min-width: 180px;
      flex: 1 0;
      flex-shrink: 0;

      p {
        font-weight: 400;
        font-size: var(--fs-3);
        line-height: 100%;
        color: #0c0005;
      }

      h4 {
        font-weight: 400;
        font-size: var(--lg-fs-1);
        line-height: 100%;
        color: #0c0005;
      }
    }

    &-count {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
    }

    &-content {
      flex: 1;
      width: 100%;
      position: relative;
      overflow: hidden;
    }

    &-campaigns {
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: auto;
      max-height: 100%;
      gap: var(--sm-4);
    }

    &-filters {
      display: flex;
      align-items: center;
      gap: var(--sm-4);
    }
  }
`;
