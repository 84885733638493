import { PermissionsStyles } from './styles';
import { getTranslationByLangOrEng } from '../../i18n';
import { SearchIcon } from '../../assets';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiResponse, permissionApi } from '../../api';
import { IPermission, TPermissionValue } from '../../entities';
import { useAppSelector } from '../../state';
import { Helmet } from 'react-helmet-async';
import { EditButtons, extraFieldsSettings, NameCustomField, StaticCheckBox } from './utils';
import { SubHeader, Table, TransparentButton } from '../../components';
import { useDebounce } from '../../hooks';
export const Permissions = () => {
  const navigate = useNavigate();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [searchParams, setSearchParams] = useState<string>('');
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [uploading, setUploading] = useState<boolean>(true);
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const permissionsCount = useRef<number>(0);
  const debouncedSearch = useDebounce(searchParams, 1000);
  useEffect(() => {
    if (!uploading) {
      setPermissions([]);
      setPageNumber(1);
      setReload(!reload);
    }
  }, [debouncedSearch]);
  useEffect(() => {
    setUploading(true);
    permissionApi.getPermissions((pageNumber ? +pageNumber - 1 : 0) * 10, 10, [], debouncedSearch).then(res => {
      if (res.statusCode === 200) {
        setPermissions(prev => [...prev, ...res.data]);
        permissionsCount.current = res.count ? res.count : 0;
        setUploading(false);
      }
    }).finally(() => {
      setUploading(false);
    });
  }, [pageNumber, reload]);
  const filterItemsAfterDelete = useCallback((id: number) => {
    setPermissions(prev => prev.filter(user => user.id !== id));
  }, [reQuery]);
  const handleUpdate = (crud: number, permission: IPermission) => {
    if (permission.id) {
      const {
        permissions
      } = permission;
      let updatedPermissions;
      if (permissions.includes(crud as TPermissionValue)) {
        updatedPermissions = permissions.filter(item => item !== crud);
      } else {
        updatedPermissions = [...permissions, crud];
      }
      const data = {
        ...permission,
        permissions: updatedPermissions as TPermissionValue[]
      };
      permissionApi.updatePermission(+permission.id, data).then(res => {
        if (res.statusCode === 200) {
          setPermissions(prev => prev.map(el => {
            if (el.id === permission.id) {
              return res.data;
            }
            return el;
          }));
        }
      });
    }
  };
  useEffect(() => {
    setTableHeaders([{
      Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_permission_header'),
      accessor: 'permission',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={data.row.original.name} textColor="#666666" />
    }, {
      Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_resource_header'),
      accessor: 'resource',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={data.row.original.resource} textColor="#666666" />
    }, {
      Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_create_header'),
      accessor: 'create',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <StaticCheckBox value={1} permissions={data.row.original} handleUpdate={handleUpdate} />
    }, {
      Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_read_header'),
      accessor: 'read',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <StaticCheckBox value={2} permissions={data.row.original} handleUpdate={handleUpdate} />
    }, {
      Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_update_header'),
      accessor: 'update',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <StaticCheckBox value={4} permissions={data.row.original} handleUpdate={handleUpdate} />
    }, {
      Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_delete_header'),
      accessor: 'delete',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <StaticCheckBox value={8} permissions={data.row.original} handleUpdate={handleUpdate} />
    }, {
      Header: 'Edit buttons',
      accessor: 'editButtons',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <EditButtons reQuery={reQuery} setReQuery={setReQuery} id={data.row.original.id} filterItemsAfterDelete={filterItemsAfterDelete} />
    }]);
  }, [permissions]);
  const onClickToRow = async (data: any) => {
    navigate(`/users/permissions/${data.id}`);
  };
  return <PermissionsStyles>
      <Helmet>
        <title>Доступи</title>
      </Helmet>

      <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_title')}>
        <div className="buttonsContainer">
          <TransparentButton handleClick={() => navigate('/users/permissions/create')} text={getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_add_permission_button')} filled />
          <TransparentButton handleClick={() => navigate('/users/roles')} text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')} />
        </div>
      </SubHeader>
      <div className="searchWrapper">
        <SearchIcon />
        <input placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search')} type="text" value={searchParams} onChange={e => setSearchParams(e.target.value)} />
      </div>

      {tableHeaders && <Table columns={tableHeaders} data={permissions} hideFieldsSplitters extraFieldsSettings={extraFieldsSettings} headerColumnCounts={{
      permission: permissionsCount.current
    }} hiddenHeaders={['editButtons']} fullWidthColumns={['editButtons']} sortableColumns={['name']} onClickToRow={onClickToRow} totalCount={permissionsCount.current} currentPage={pageNumber} offset={10} loading={uploading} pageNumberHandler={value => setPageNumber(value)} fixedHeader />}
    </PermissionsStyles>;
};